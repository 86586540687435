import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PublicAuth, ProtectedAuth } from "./Auth";

import FirstModal from "../Components/Register/FirstModal";

// 홈
import Main from "../Routes/Main/Index";

// 상품
import ProductList from "../Routes/Product/List/index";
import ProductDetail from "../Routes/Product/Detail/index";
import ProductOrder from "../Routes/Product/Order/index";

// 묶음
import SetList from "../Routes/Set/List/index";
import SetDetail from "../Routes/Set/Detail/index";
import SetOrder from "../Routes/Set/Order/index";

// 구독박스
import Box from "../Routes/Box/Index";

// 배송관리
import OrderList from "../Routes/Order/List/Index";
import OrderDetail from "../Routes/Order/Detail/Index";

// 배송정보
import PlaceList from "../Routes/Account/Place/List/index";
import AddressList from "../Routes/Account/Address/List/index";
import AddressRegister from "../Routes/Account/Address/Register/index";
import AddressWrite from "../Routes/Account/Address/Write/index";
import AddressEdit from "../Routes/Account/Address/Edit/index";

// 검색
import SearchInit from "../Routes/Search/Init/index";
import SearchResultProduct from "../Routes/Search/Result/Product/index";
import SearchResultSet from "../Routes/Search/Result/Set/index";

// 이용약관
import Provision from "../Routes/Policy/Provision";
import Privacy from "../Routes/Policy/Privacy";
import ThirdParty from "../Routes/Policy/ThirdParty";

//  로그인
import Login from "../Routes/Auth/Login/index";

//  에러
import Error from "../Routes/Error/index";

const AppRouter = ({ isLoggedIn }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/main" />} />
      {/* 메인 */}
      <Route
        path="/main"
        element={
          <Fragment>
            <Main />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />

      {/* 상품 */}
      <Route
        path="/products"
        element={
          <Fragment>
            <ProductList />
          </Fragment>
        }
      />
      <Route
        path="/products/:id"
        element={
          <Fragment>
            <ProductDetail />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route element={<ProtectedAuth />}>
        {/* 상품 담기 */}
        <Route
          path="/products/:id/order"
          element={
            <Fragment>
              <ProductDetail />
              <ProductOrder />
              {isLoggedIn && <FirstModal />}
            </Fragment>
          }
        />
      </Route>

      {/* 묶음 */}
      <Route
        path="/sets"
        element={
          <Fragment>
            <SetList />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route
        path="/sets/:id"
        element={
          <Fragment>
            <SetDetail />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route element={<ProtectedAuth />}>
        {/* 묶음상품 담기 */}
        <Route
          path="/sets/:id/order"
          element={
            <Fragment>
              <SetDetail />
              <SetOrder />
              {isLoggedIn && <FirstModal />}
            </Fragment>
          }
        />
      </Route>

      {/* 검색 */}
      <Route
        path="/search/init"
        element={
          <Fragment>
            <SearchInit />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route
        path="/search/result/products"
        element={
          <Fragment>
            <SearchResultProduct />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route
        path="/search/result/sets"
        element={
          <Fragment>
            <SearchResultSet />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />

      {/* 약관 */}
      <Route
        path="/policy/provision"
        element={
          <Fragment>
            <Provision />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route
        path="/policy/privacy"
        element={
          <Fragment>
            <Privacy />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />
      <Route
        path="/policy/thirdparty"
        element={
          <Fragment>
            <ThirdParty />
            {isLoggedIn && <FirstModal />}
          </Fragment>
        }
      />

      <Route element={<PublicAuth />}>
        {/* 로그인 */}
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<ProtectedAuth />}>
        {/* 배송정보 등록 */}
        <Route path="/account/places" element={<PlaceList />} />
        <Route path="/account/addresses" element={<AddressList />} />
        <Route
          path="/account/addresses/register"
          element={<AddressRegister />}
        />
        <Route path="/account/addresses/write" element={<AddressWrite />} />
        <Route path="/account/addresses/:id/edit" element={<AddressEdit />} />

        {/* 구독박스 */}
        <Route
          path="/box"
          element={
            <Fragment>
              <Box />
              {isLoggedIn && <FirstModal />}
            </Fragment>
          }
        />

        {/* 배송 */}
        <Route
          path="/orders"
          element={
            <Fragment>
              <OrderList />
              {isLoggedIn && <FirstModal />}
            </Fragment>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <Fragment>
              <OrderDetail />
              {isLoggedIn && <FirstModal />}
            </Fragment>
          }
        />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};
export default AppRouter;
