import { gql } from "@apollo/client";

/** 메인 **/
export const DELIVERY_MAIN_QUERY = gql`
  query DeliveryMain {
    DeliveryBannerList(skip: 0, take: 100) {
      deliveryBanners {
        id
        createdAt
        updatedAt

        commonStatus
        isPeriod
        startDate
        endDate
        isLink
        link
        coverImage
        title
      }
      count
    }

    DeliveryProductCategoryList(skip: 0, take: 100) {
      deliveryProductCategories {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order

        deliveryProductsCount
      }
      count
    }

    DeliverySetList(skip: 0, take: 100) {
      deliverySets {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        name
        description

        totalPrice
      }
      count
    }

    DeliveryProductList(orderBy: "orderCount_DESC", skip: 0, take: 10) {
      deliveryProducts {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        price
        name
        description

        deliveryProductCategory {
          id
          createdAt
          updatedAt

          commonStatus
          iconImage
          name
          order
        }
        deliveryProductOptions {
          id
          createdAt
          updatedAt

          commonStatus
          name
          deliveryProductOptionDetails {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
  }
`;
