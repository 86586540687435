import styled, { css } from "styled-components"

const RadioButton = styled.label`
  ${({ theme }) => {
    return css`
      width: 100%;
      margin-right: ${theme.margins.sm};
      &:last-child {
        margin-right: 0;
      }
      .radio-input {
        width: 0;
        height: 0;
        display: none;
      }
      .custom-radio {
        display: flex;
        border-radius: 8px;
        height: 40px;
        align-items: center;
        justify-content: center;
        background-color: ${theme.colors.lightprimary};
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.primary};
        cursor: pointer;
      }
      .radio-input:checked + .custom-radio {
        background-color: ${theme.colors.primary};
        color: white;
      }
    `
  }}
`
function RadioBtn({ id, text, name, onChange, checked, value }) {
  return (
    <RadioButton htmlFor={id}>
      <input
        className="radio-input"
        type="radio"
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio">{text}</span>
    </RadioButton>
  )
}

export default RadioBtn
