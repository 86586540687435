import styled, { css } from "styled-components"
import { Link, useNavigate } from "react-router-dom"
import theme from "../Style/Theme"
import { ArrowLeft2, SearchNormal1 } from "iconsax-react"

const Headers = styled.header`
  width: 100%;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const HButton = styled.button`
  display: flex;
  background-color: white;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  ${({ theme }) => {
    return css`
      font-size: ${theme.fonts.size.lg};
      color: #777;
      &:hover {
        color: ${theme.colors.black01};
      }
    `
  }}
`
const Block = styled.div`
  background-color: white;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
`
const Title = styled.div`
  display: flex;
  ${({ theme }) => {
    return css`
      a {
        color: #000;
        font-size: ${theme.fonts.size.base};
        font-weight: ${theme.fonts.weight.bold};
        text-decoration: none;
      }
    `
  }}
`

function Back() {
  const navigate = useNavigate()

  return (
    <HButton onClick={() => navigate(-1)}>
      <ArrowLeft2 color={theme.colors.black02} variant="Linear" size={24} />
    </HButton>
  )
}
function Search() {
  let navigate = useNavigate()
  return (
    <HButton onClick={() => navigate("/search/init")}>
      <SearchNormal1 color={theme.colors.black02} variant="Linear" size={24} />
    </HButton>
  )
}
function Header({ title, onSearch }) {
  return (
    <Headers>
      <Wrap>
        <Back />
        <Title>
          <Link to="/main">{title}</Link>
        </Title>
        {onSearch ? <Search /> : <Block />}
      </Wrap>
    </Headers>
  )
}
export default Header
