export const stateOptions = [
  {
    label: "1회 구독",
    value: "Onetime",
  },
  {
    label: "정기구독",
    value: "Subscription",
  },
];
