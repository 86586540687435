import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_BOX_PRODUCT_WRITE_MUTATION = gql`
  mutation DeliveryAccountBoxProductWrite(
    $state: String!
    $count: String!
    $deliveryProduct_id: String!
    $deliveryProductOptionDetail1_id: String
    $deliveryProductOptionDetail2_id: String
    $deliveryProductOptionDetail3_id: String
  ) {
    DeliveryAccountBoxProductWrite(
      state: $state
      count: $count
      deliveryProduct_id: $deliveryProduct_id
      deliveryProductOptionDetail1_id: $deliveryProductOptionDetail1_id
      deliveryProductOptionDetail2_id: $deliveryProductOptionDetail2_id
      deliveryProductOptionDetail3_id: $deliveryProductOptionDetail3_id
    )
  }
`;

export const DELIVERY_ACCOUNT_BOX_PRODUCT_SET_WRITE_MUTATION = gql`
  mutation DeliveryAccountBoxProductSetWrite(
    $state: String!
    $count: String!
    $deliverySet_id: String!
  ) {
    DeliveryAccountBoxProductSetWrite(
      state: $state
      count: $count
      deliverySet_id: $deliverySet_id
    )
  }
`;

export const DELIVERY_ACCOUNT_BOX_PRODUCT_EDIT_COUNT_MUTATION = gql`
  mutation DeliveryAccountBoxProductEditCount($id: String!, $count: String!) {
    DeliveryAccountBoxProductEditCount(id: $id, count: $count)
  }
`;

export const DELIVERY_ACCOUNT_BOX_PRODUCT_EDIT_STATE_MUTATION = gql`
  mutation DeliveryAccountBoxProductEditState($id: String!, $state: String!) {
    DeliveryAccountBoxProductEditState(id: $id, state: $state)
  }
`;

export const DELIVERY_ACCOUNT_BOX_PRODUCT_DELETE_MUTATION = gql`
  mutation DeliveryAccountBoxProductDelete($id: String!) {
    DeliveryAccountBoxProductDelete(id: $id)
  }
`;

export const DELIVERY_ACCOUNT_BOX_PRODUCT_SELECT_DELETE_MUTATION = gql`
  mutation DeliveryAccountBoxProductSelectDelete($ids: [String!]!) {
    DeliveryAccountBoxProductSelectDelete(ids: $ids)
  }
`;
