import { Helmet } from "react-helmet-async";

function PageTitle({ title }) {
  return (
    <Helmet>
      <title>{title} : 로컬푸드 구독 서비스</title>
    </Helmet>
  );
}

export default PageTitle;
