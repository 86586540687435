import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Layout from "../../../Components/Layout/Layout";
import styled from "styled-components";
import theme from "../../../Components/Style/Theme";
import FormError from "../../../Components/Common/FormError";
import Button from "../../../Components/Common/Buttons";
import { SwalCustom } from "../../../Components/Style/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

import { useMutation } from "@apollo/client";
import { DELIVERY_AUTH_LOGIN_MUTATION } from "../../../Config/Queries/Auth";
import { logUserIn } from "../../../Config/Apollo";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const LoginBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.paddings.xl};
  margin-bottom: 10px;
  font-family: ${theme.fonts.family.base};
  .logo_container {
    padding: ${theme.paddings.xl} 0;
    margin-bottom: ${theme.margins.xl};
    .logo {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      img {
        width: 36px;
        height: auto;
        margin-right: 8px;
      }
      p {
        color: ${theme.colors.warning};
        font-weight: ${theme.fonts.weight.medium};
        font-size: ${theme.fonts.size.base};
        letter-spacing: -1px;
      }
    }
    .logo_title {
      h2 {
        color: ${theme.colors.primary};
        font-weight: ${theme.fonts.weight.normal};
        font-size: ${theme.fonts.size.xl};
        line-height: 1.4;
        letter-spacing: -0.4px;
        b {
        }
      }
    }
  }
  form {
    width: 100%;
    .form_group {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: ${theme.margins.base};
      label {
        color: ${theme.colors.black04};
        font-size: 13px;
        margin-bottom: ${theme.margins.sm};
      }
      input {
        height: 46px;
        padding: 0 ${theme.paddings.base};
        border: solid 1px #eee;
        border-radius: 4px;
        font-size: ${theme.fonts.size.base};
        &::placeholder {
          font-size: 1.3rem;
          color: #bbb;
        }
        &:focus-visible {
          outline: none !important;
          border: 2px solid ${theme.colors.primary};
        }
      }
    }
  }
`;
const SFooter = styled.footer`
  width: 100%;
  flex: 0.3;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
  ul {
    margin-bottom: ${theme.margins.base};
    li {
      color: ${theme.colors.black01};
      position: relative;
      float: left;
      padding: 0 ${theme.paddings.sm};
      font-size: 1.3rem;
      ::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        background-color: #ddd;
        width: 1px;
        height: 10px;
        transform: translateY(-50%);
      }
      &:first-child {
        padding-left: 0;
        ::before {
          display: none;
        }
      }
      a {
        color: #555;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .copytext {
    font-size: ${theme.fonts.size.xs};
    color: #bbb;
  }
`;

function Login() {
  const navigate = useNavigate();

  const [DeliveryAuthLoginMutation, { DeliveryAuthLoginLoading }] = useMutation(
    DELIVERY_AUTH_LOGIN_MUTATION
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (DeliveryAuthLoginLoading) {
        return;
      }

      const {
        data: { DeliveryAuthLogin: token },
      } = await DeliveryAuthLoginMutation({
        variables: {
          userId: data.userId.toLowerCase(),
          password: data.password,
        },
      });

      if (token) {
        logUserIn(token);
        navigate("/main");
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom className="ss">
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  return (
    <Layout isNav={true} isFooter={false} isSearch={true}>
      <LoginBox>
        <div className="logo_container">
          <div className="logo">
            <img src="/image/login_logo.svg" />
            <p>로컬푸드 구독 서비스</p>
          </div>
          <div className="logo_title">
            <h2>
              편리하게
              <br />
              <b>집 또는 가까운 장소</b>에서 픽업
            </h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="userId"
            control={control}
            rules={{
              required: "아이디를 입력해주세요.",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="form_group">
                <label>아이디</label>
                <input
                  type="text"
                  placeholder="아이디"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                {errors.userId && <FormError message={errors.userId.message} />}
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: "비밀번호를 입력해주세요.",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="form_group">
                <label>비밀번호</label>
                <input
                  type="password"
                  placeholder="비밀번호"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                {errors.password && (
                  <FormError message={errors.password.message} />
                )}
              </div>
            )}
          />
          <Button
            type="submit"
            style={{ marginTop: 15 }}
            color="primary"
            width="full"
            size="lg"
          >
            {DeliveryAuthLoginLoading ? "처리중..." : "로그인"}
          </Button>
        </form>
        <SFooter>
          <ul>
            <li>
              <Link to="/policy/provision">이용약관</Link>
            </li>
            <li>
              <Link to="/policy/privacy">개인정보처리방침</Link>
            </li>
            <li>
              <Link to="/policy/thirdParty">제3자 정보제공</Link>
            </li>
          </ul>
        </SFooter>
      </LoginBox>
    </Layout>
  );
}

export default Login;
