import React from "react";
import { useParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_PRODUCT_DETAIL_QUERY } from "../../../Config/Queries/Product";

const Container = () => {
  const params = useParams();
  const id = params.id;
  const baseUrl = "/products";

  const { loading, error, data } = useQuery(DELIVERY_PRODUCT_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return <Presenter loading={loading} data={data} baseUrl={baseUrl} />;
};

export default Container;
