import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"

const CheckButton = styled.label`
  ${({ theme }) => {
    return css`
      display: inline-flex;
      align-items: center;
      margin-right: ${theme.margins.sm};
      &:last-child {
        margin-right: 0;
      }
      .check-input {
        width: 0;
        height: 0;
        display: none;
      }
      .custom-check {
        background-color: white;
        display: flex;
        border-radius: 15px;
        margin-right: ${theme.margins.sm};
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        border: solid 2px ${theme.colors.gray};
        font-size: ${theme.fonts.size.base};
        color: white;
        cursor: pointer;
      }
      .check-input:checked + .custom-check {
        background-color: ${theme.colors.primary};
        border: solid 2px ${theme.colors.primary};
        color: white;
      }
      .custom-text {
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black03};
      }
    `
  }}
`
const CheckBtn = ({ id, text, onChange, checked }) => {
  return (
    <CheckButton htmlFor={id}>
      <input
        className="check-input"
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-check">
        <FontAwesomeIcon icon={faCheck} />
      </span>
      <span className="custom-text">{text}</span>
    </CheckButton>
  )
}
export default CheckBtn
