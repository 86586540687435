const Menu = [
  {
    path: ["/main"],
    title: "홈",
  },
  {
    path: ["/products", "/products/detail"],
    title: "상품",
  },
  {
    path: ["/sets", "/sets/detail"],
    title: "묶음",
  },
  {
    path: ["/box"],
    title: "구독박스",
  },
  {
    path: ["/orders"],
    title: "배송",
  },
];

export default Menu;
