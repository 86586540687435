import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_ACCOUNT_BOX_DETAIL_QUERY } from "../../Config/Queries/Box";

export default () => {
  const navigate = useNavigate();
  const baseUrl = "/box";

  const { data, loading, error } = useQuery(DELIVERY_ACCOUNT_BOX_DETAIL_QUERY, {
    fetchPolicy: "network-only",
  });

  // if (loading) {
  //   return <></>;
  // }
  if (!loading && data.DeliveryAccountBoxDetail === null) {
    navigate(`/main`);
    return;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return <Presenter loading={loading} data={data} baseUrl={baseUrl} />;
};
