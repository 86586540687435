import React, { Fragment, useState } from "react"
import styled from "styled-components"
import theme from "../../../Components/Style/Theme"
import Layout from "../../../Components/Layout/Layout"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import "../../../Components/Style/ckediter.css"
import StickyBox from "react-sticky-box"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../../../Components/Common/Buttons"
import Links from "../../../Components/Common/Links"
import {
  DetailContainer,
  DetailInfo,
  FooterButton,
} from "../../../Components/Style/Styles"
import PageTitle from "../../../Components/Layout/PageTitle"
import { uri } from "../../../Config/Apollo"
import { NumericFormat } from "react-number-format"

import { OrderPolicy } from "../../../Config/Constant"

const TabsNav = styled(Tabs)`
  .react-tabs__tab-list {
    display: flex;
    border: 0;
    margin: 0;
    background-color: white;
    border-bottom: solid 1px ${theme.colors.gray};
    li {
      background-color: transparent;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 0;
      span {
        height: 45px;
        line-height: 45px;
        padding: 0 ${theme.paddings.sm};
        font-size: 15px;
        color: ${theme.colors.black03};
      }
      &.react-tabs__tab--selected {
        background-color: transparent !important;
        span {
          border-bottom: solid 2px ${theme.colors.black01};
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.bold};
        }
      }
    }
  }
  .react-tabs__tab-panel {
    padding: 0;
  }
`

function Detail({ data, baseUrl }) {
  const {
    DeliveryProductDetail: {
      id,
      createdAt,
      updatedAt,

      commonStatus,
      coverImage,
      timestamp,
      price,
      name,
      description,

      deliveryProductCategory,
      deliveryProductOptions,
    },
  } = data

  const cover =
    coverImage === "" || coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${uri}${coverImage}`

  const [tabIndex, setTabIndex] = useState(0)

  const [isFull, setIsFull] = useState(false)
  const isFullToggle = () => {
    setIsFull(!isFull)
  }
  return (
    <Fragment>
      <DetailContainer>
        <div className="detail_img">
          <img src={cover} alt={name} />
        </div>
        <DetailInfo>
          <span className="category">{deliveryProductCategory.name}</span>
          <h3>{name}</h3>
          <span className="price">
            <b>
              <NumericFormat
                displayType={"text"}
                value={price}
                thousandSeparator={true}
              />
            </b>{" "}
            드림
          </span>
        </DetailInfo>

        <TabsNav
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => setTabIndex(tabIndex)}
        >
          <StickyBox
            offsetTop={0}
            offsetBottom={20}
            style={{ zIndex: 91, backgroundColor: "white" }}
          >
            <TabList>
              <Tab>
                <span>상세정보</span>
              </Tab>
              <Tab>
                <span>교환/환불</span>
              </Tab>
              <Tab>
                <span>배송/결제</span>
              </Tab>
            </TabList>
          </StickyBox>
          <TabPanel>
            <div className={"detail_content"}>
              <div
                className={`ck-content ${isFull ? "open" : "hide"}`}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
              <Button color="white" width="full" onClick={isFullToggle}>
                {isFull ? (
                  <>
                    상품정보 접기 &nbsp;
                    <FontAwesomeIcon icon={faAngleUp} />
                  </>
                ) : (
                  <>
                    상품정보 펼치기 &nbsp;
                    <FontAwesomeIcon icon={faAngleDown} />
                  </>
                )}
              </Button>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={"detail_content"}>{OrderPolicy.Refund.content}</div>
          </TabPanel>
          <TabPanel>
            <div className={"detail_content"}>
              {OrderPolicy.Payment.content}
            </div>
          </TabPanel>
        </TabsNav>
      </DetailContainer>

      <FooterButton>
        <div className="shadow">
          <Links
            to={`${baseUrl}/${id}/order`}
            replace={true}
            color="primary"
            width="full"
            border="radius"
          >
            구독 담기
          </Links>
        </div>
      </FooterButton>
    </Fragment>
  )
}

function Presenter({ loading, data, baseUrl }) {
  return (
    <Layout isNav={false} isFooter={true} isFooterButton={true}>
      <PageTitle title={`상품`} />

      {!loading ? (
        <Detail data={data} baseUrl={baseUrl} />
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  )
}

export default Presenter
