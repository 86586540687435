import React from "react";

import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "./Config/Apollo";

import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";

import Theme from "./Components/Style/Theme";
import { GlobalStyles } from "./Components/Style/Styles";
import { FontStyles } from "./Components/Style/Fonts";
import Routers from "./Config/Routes";

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <HelmetProvider>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <FontStyles />
        <Routers isLoggedIn={isLoggedIn} />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
