import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import styled from "styled-components"
import theme from "../../../Components/Style/Theme"
import Layout from "../../../Components/Layout/Layout"
import PageTitle from "../../../Components/Layout/PageTitle"
import FormError from "../../../Components/Common/FormError"
import {
  SearchContainer,
  SearchGroup,
  Ready,
} from "../../../Components/Style/Styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose } from "@fortawesome/pro-regular-svg-icons"
import Category from "../../../Components/Search/Category"

function Init() {
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [displayKeywords, setDisplayKeywords] = useState([])

  const getItem = async () => {
    const keywords = await localStorage.getItem("keywords")
    setDisplayKeywords(keywords ? JSON.parse(keywords) : [])
    // setLoaded(true);
  }

  const setItem = async (text) => {
    const date = new Date()
    const newKeyword = { keyword: text, date: date.toString() }

    let keywords = await localStorage.getItem("keywords")

    if (keywords) {
      keywords = JSON.parse(keywords)

      let tempKeywords = keywords.filter((item) => text !== item.keyword)

      tempKeywords = tempKeywords ? [newKeyword, ...tempKeywords] : [newKeyword]
      await localStorage.removeItem("keywords")
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
      setDisplayKeywords(tempKeywords)
    } else {
      const tempKeywords = [newKeyword]
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
      setDisplayKeywords(tempKeywords)
    }
  }

  const removeItem = async ({ keyword }) => {
    let keywords = await localStorage.getItem("keywords")

    if (keywords) {
      keywords = JSON.parse(keywords)

      let tempKeywords = keywords.filter((item) => keyword !== item.keyword)

      await localStorage.removeItem("keywords")
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
      setDisplayKeywords(tempKeywords)
    } else {
      await localStorage.removeItem("keywords")
      setDisplayKeywords([])
    }
  }
  const allRemoveItem = async () => {
    await localStorage.removeItem("keywords")
    setDisplayKeywords([])
  }

  const onSubmit = (data) => {
    setItem(data.keyword)
    navigate(
      `/search/result/products?keyword=${encodeURIComponent(data.keyword)}`
    )
  }

  useEffect(() => {
    getItem()
  }, [])

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title={`검색`} />
      <SearchGroup>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form_group">
            <div className="row">
              <div>
                <Controller
                  name="keyword"
                  control={control}
                  rules={{
                    required: "키워드를 입력해주세요.",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <input
                        className="form_control"
                        placeholder="검색어를 입력해주세요."
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    </>
                  )}
                />
              </div>
              <button type="submit" className="btn">
                검색
              </button>
            </div>
            {errors.keyword && <FormError message={errors.keyword.message} />}
          </div>
        </form>
      </SearchGroup>
      <SearchContainer>
        <div className="search_recent">
          <div className="search_title">
            <h3>최근 검색어</h3>
            <button className="more" onClick={allRemoveItem}>
              전체삭제
            </button>
          </div>
          <div className="recent_list">
            {displayKeywords.length > 0 ? (
              <ul>
                {displayKeywords.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to={`/search/result/products?keyword=${encodeURIComponent(
                          item.keyword
                        )}`}
                      >
                        <span>{item.keyword}</span>
                      </Link>
                      <button>
                        <FontAwesomeIcon
                          icon={faClose}
                          color={theme.colors.black03}
                          size={"lg"}
                          onClick={() => removeItem(item)}
                        />
                      </button>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <Ready>최근 검색 내역이 없습니다.</Ready>
            )}
          </div>
        </div>
        <div className="search_category">
          <div className="search_title">
            <h3>상품 분류</h3>
          </div>
          <div className="category_list">
            <Category />
          </div>
        </div>
      </SearchContainer>
    </Layout>
  )
}

export default Init
