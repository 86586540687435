import React from "react";
import { useSearchParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY } from "../../../../Config/Queries/Address";

const Container = () => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get("page") ? +searchParams.get("page") : 1;
  const take = 100;
  const baseUrl = "/account/addresses";

  const { data, loading, error } = useQuery(
    DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        take,
        skip: (page - 1) * take,
      },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
};

export default Container;
