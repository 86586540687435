import { gql } from "@apollo/client";

export const DELIVERY_SEARCH_LIST_QUERY = gql`
  query DeliverySearchList($keyword: String, $skip: Int!, $take: Int) {
    DeliveryProductList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      take: $take
    ) {
      deliveryProducts {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        price
        name
        description

        deliveryProductCategory {
          id
          createdAt
          updatedAt

          commonStatus
          iconImage
          name
          order
        }
        deliveryProductOptions {
          id
          createdAt
          updatedAt

          commonStatus
          name
          deliveryProductOptionDetails {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
    DeliverySetList(keyword: $keyword, skip: $skip, take: $take) {
      deliverySets {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        name
        description

        totalPrice
      }
      count
    }
  }
`;
