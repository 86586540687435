import React from "react"

import Layout from "../../Components/Layout/Layout"
import PageTitle from "../../Components/Layout/PageTitle"
import { ProvisionContainer } from "../../Components/Style/Styles"
import { Policy } from "../../Config/Constant"

function Privacy() {
  return (
    <Layout isNav={false} isFooter={true} isSearch={true}>
      <PageTitle title={`${Policy.Privacy.title}`} />
      <ProvisionContainer>
        <h1>{Policy.Privacy.title}</h1>
        <div className="inner">{Policy.Privacy.content}</div>
      </ProvisionContainer>
    </Layout>
  )
}

export default Privacy
