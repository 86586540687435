export const shortDayOfWeekOptions = [
  {
    label: "월",
    value: "1",
  },
  {
    label: "화",
    value: "2",
  },
  {
    label: "수",
    value: "3",
  },
  {
    label: "목",
    value: "4",
  },
  {
    label: "금",
    value: "5",
  },
];

export const dayOfWeekOptions = [
  {
    label: "월요일",
    value: "1",
  },
  {
    label: "화요일",
    value: "2",
  },
  {
    label: "수요일",
    value: "3",
  },
  {
    label: "목요일",
    value: "4",
  },
  {
    label: "금요일",
    value: "5",
  },
];
export const dayOfWeekName = (dayOfWeek) => {
  return dayOfWeekOptions.find((item) => item.value === dayOfWeek).label;
};
