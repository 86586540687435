export const sortOptions = [
  {
    label: "최신순",
    value: "id_DESC",
  },
  {
    label: "낮은 가격순",
    value: "price_ASC",
  },
  {
    label: "높은 가격순",
    value: "price_DESC",
  },
];
