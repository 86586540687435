import { useNavigate } from "react-router-dom";
import { SortOption } from "../Style/Styles";
import Dropdown from "react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import theme from "..//Style/Theme";
import { sortOptions } from "../../Config/Codes/Product";

const Sort = ({ baseUrl, deliveryProductCategory_id, keyword, orderBy }) => {
  const navigate = useNavigate();

  const defaultOption = orderBy
    ? sortOptions.find((item) => item.value === orderBy)
    : sortOptions.find((item) => item.value === "id_DESC");

  const onChange = (option) => {
    const url =
      baseUrl +
      "?" +
      (deliveryProductCategory_id
        ? `deliveryProductCategory_id=${deliveryProductCategory_id}&`
        : ``) +
      (keyword ? `keyword=${keyword}&` : ``) +
      (option.value ? `orderBy=${option.value}&` : ``) +
      `page=1`;

    navigate(url);
  };

  return (
    <div className="sort">
      <SortOption>
        <Dropdown
          options={sortOptions}
          value={defaultOption}
          onChange={onChange}
          arrowClosed={
            <FontAwesomeIcon icon={faAngleDown} color={theme.colors.black04} />
          }
          arrowOpen={
            <FontAwesomeIcon icon={faAngleUp} color={theme.colors.black04} />
          }
        />
      </SortOption>
    </div>
  );
};

export default Sort;
