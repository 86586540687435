export const dayOfWeekOptions = [
  {
    label: "일요일",
    value: "0",
  },
  {
    label: "월요일",
    value: "1",
  },
  {
    label: "화요일",
    value: "2",
  },
  {
    label: "수요일",
    value: "3",
  },
  {
    label: "목요일",
    value: "4",
  },
  {
    label: "금요일",
    value: "5",
  },
  {
    label: "토요일",
    value: "6",
  },
];
export const dayOfWeekName = (dayOfWeek) => {
  return dayOfWeekOptions.find((item) => item.value === dayOfWeek).label;
};

export const stateOptions = [
  {
    label: "배송준비중",
    value: "Ready",
  },
  {
    label: "배송중",
    value: "Delivery",
  },
  {
    label: "배송완료",
    value: "Completed",
  },
];
export const stateName = (state) => {
  return stateOptions.find((item) => item.value === state).label;
};

export const typeOptions = [
  {
    label: "픽업",
    value: "Pickup",
  },
  {
    label: "배달",
    value: "Delivery",
  },
];
export const typeName = (type) => {
  return typeOptions.find((item) => item.value === type).label;
};
