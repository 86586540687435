import React, { Fragment, useState } from "react";
import styled from "styled-components";
import theme from "../../Components/Style/Theme";
import Layout from "../../Components/Layout/Layout";
import PageTitle from "../../Components/Layout/PageTitle";
import Item from "../../Components/Box/Item";
import CheckBtn from "../../Components/Common/CheckBtn";
import RowInfo from "../../Components/Common/RowInfo";
import Button from "../../Components/Common/Buttons";
import Links from "../../Components/Common/Links";
import {
  FooterButton,
  InfoBox,
  SwalCustom,
  Ready,
  Span,
} from "../../Components/Style/Styles";
import { NumericFormat } from "react-number-format";
import { dayOfWeekName, typeName } from "../../Config/Codes/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import ReactMoment from "react-moment";

import { useMutation } from "@apollo/client";
import {
  DELIVERY_ACCOUNT_BOX_PRODUCT_EDIT_COUNT_MUTATION,
  DELIVERY_ACCOUNT_BOX_PRODUCT_EDIT_STATE_MUTATION,
  DELIVERY_ACCOUNT_BOX_PRODUCT_DELETE_MUTATION,
  DELIVERY_ACCOUNT_BOX_PRODUCT_SELECT_DELETE_MUTATION,
} from "../../Config/Queries/BoxProduct";
import {
  DELIVERY_ACCOUNT_BOX_DETAIL_QUERY,
  DELIVERY_ACCOUNT_BOX_EDIT_STATE_TOGGLE_MUTATION,
} from "../../Config/Queries/Box";
import { DELIVERY_ACCOUNT_USER_DETAIL_QUERY } from "../../Config/Queries/Account";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CalendarTick } from "iconsax-react";

const MySwal = withReactContent(Swal);

const SubscribContainer = styled.div`
  .subscrib_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.lightgray};
    font-size: ${theme.fonts.size.base};
    color: ${theme.colors.black05};
    padding: ${theme.paddings.base} ${theme.paddings.lg};
    .delet_btn {
      background-color: ${theme.colors.lightgray};
      font-size: ${theme.fonts.size.base};
      color: ${theme.colors.black01};
      font-weight: ${theme.fonts.weight.bold};
      padding: 0;
    }
  }
  .subscrib_list {
    border-bottom: solid 5px ${theme.colors.gray};
    padding: ${theme.paddings.sm} 0;
  }
  .subscrib_box {
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
    border-bottom: solid 5px ${theme.colors.gray};
  }
`;

function Box({ data, baseUrl }) {
  const {
    DeliveryAccountBoxDetail: {
      id,
      createdAt,
      updatedAt,
      state,
      type,
      deliveryPlace,
      deliveryAddress,
      deliveryBoxProducts,

      condition,
      totalPrice,
      deliveryDate,
    },
    DeliveryAccountUserDetail: { trustlines, isCms, cmsValue, cmsDay },
  } = data;

  const boxDeliveryDate = new Date(parseInt(deliveryDate));
  const boxDeliveryDayOfWeek = boxDeliveryDate.getDay().toString();

  const boxPayDate = new Date(moment(boxDeliveryDate).add(-1, "d"));
  const boxPayDayOfWeek = boxPayDate.getDay().toString();

  const amount = trustlines.find(
    (trustline) => trustline.currency === "DRM"
  ).amount;
  const ableAmount = isCms
    ? amount - totalPrice + cmsValue
    : amount - totalPrice;
  const totalAmount = amount - totalPrice;
  // const totalAmount = -50001;

  // 구독상품 갯수 수정
  const [
    DeliveryAccountBoxProductEditCountMutation,
    { loading: DeliveryAccountBoxProductEditCountLoading },
  ] = useMutation(DELIVERY_ACCOUNT_BOX_PRODUCT_EDIT_COUNT_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_BOX_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
    ],
  });
  const onEditCount = async (item, count) => {
    try {
      if (DeliveryAccountBoxProductEditCountLoading) {
        return;
      }

      const {
        data: { DeliveryAccountBoxProductEditCount },
      } = await DeliveryAccountBoxProductEditCountMutation({
        variables: { id: item.id, count: `${count}` },
      });

      if (DeliveryAccountBoxProductEditCount) {
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  // 구독상품 구독상태 수정
  const [
    DeliveryAccountBoxProductEditStateMutation,
    { loading: DeliveryAccountBoxProductEditStateLoading },
  ] = useMutation(DELIVERY_ACCOUNT_BOX_PRODUCT_EDIT_STATE_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_BOX_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
    ],
  });
  const onEditState = async (item, state) => {
    try {
      if (DeliveryAccountBoxProductEditStateLoading) {
        return;
      }

      const deliveryBoxProduct = deliveryBoxProducts.find(
        (product) =>
          product.state === state &&
          product.deliveryProduct.id === item.deliveryProduct.id &&
          product.deliveryProductOptionDetail1?.id ===
            item.deliveryProductOptionDetail1?.id &&
          product.deliveryProductOptionDetail2?.id ===
            item.deliveryProductOptionDetail2?.id &&
          product.deliveryProductOptionDetail3?.id ===
            item.deliveryProductOptionDetail3?.id
      );

      if (deliveryBoxProduct) {
        MySwal.fire({
          html: (
            <SwalCustom>
              <div className="icon danger">
                <FontAwesomeIcon icon={faWarning} />
              </div>
              <div className="alert_title">
                선택한 옵션의 상품이 존재합니다. 합치시겠습니까?
              </div>
            </SwalCustom>
          ),
          confirmButtonText: `확인`,
          cancelButtonText: "취소",
          showCancelButton: true,
          customClass: {
            popup: "swal_popup",
            actions: "swal",
            confirmButton: "order-2 swal_btn primary",
            cancelButton: "order-1 right-gap swal_btn lightprimary",
          },
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const {
              data: { DeliveryAccountBoxProductEditState },
            } = await DeliveryAccountBoxProductEditStateMutation({
              variables: { id: item.id, state },
            });

            if (DeliveryAccountBoxProductEditState) {
            }
          }
          if (result.isDismissed) {
            return;
          }
        });
      } else {
        const {
          data: { DeliveryAccountBoxProductEditState },
        } = await DeliveryAccountBoxProductEditStateMutation({
          variables: { id: item.id, state },
        });

        if (DeliveryAccountBoxProductEditState) {
        }
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  // 구독상품 삭제
  const [
    DeliveryAccountBoxProductDeleteMutation,
    { loading: DeliveryAccountBoxProductDeleteLoading },
  ] = useMutation(DELIVERY_ACCOUNT_BOX_PRODUCT_DELETE_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_BOX_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
      {
        query: DELIVERY_ACCOUNT_USER_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
    ],
  });
  const onDelete = async (item) => {
    try {
      if (DeliveryAccountBoxProductDeleteLoading) {
        return;
      }

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">삭제하시겠습니까?</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        cancelButtonText: "취소",
        showCancelButton: true,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {
            data: { DeliveryAccountBoxProductDelete },
          } = await DeliveryAccountBoxProductDeleteMutation({
            variables: { id: item.id },
          });

          if (DeliveryAccountBoxProductDelete) {
          }
        }
      });
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  // 구독상품 선택삭제
  const [
    DeliveryAccountBoxProductSelectDeleteMutation,
    { loading: DeliveryAccountBoxProductSelectDeleteLoading },
  ] = useMutation(DELIVERY_ACCOUNT_BOX_PRODUCT_SELECT_DELETE_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_BOX_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
      {
        query: DELIVERY_ACCOUNT_USER_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
    ],
  });
  const onSelectDelete = async () => {
    try {
      if (DeliveryAccountBoxProductSelectDeleteLoading) {
        return;
      }

      if (checkItems.length === 0) {
        MySwal.fire({
          html: (
            <SwalCustom>
              <div className="icon warning">
                <FontAwesomeIcon icon={faWarning} />
              </div>
              <div className="alert_title">
                선택된 상품이 없습니다.
                <br />
                상품을 선택해주세요.
              </div>
            </SwalCustom>
          ),
          confirmButtonText: `확인`,
          confirmButtonColor: `#44a16a`,
          customClass: {
            popup: "swal_popup",
            actions: "swal",
            confirmButton: "swal_btn lightprimary",
            cancelButton: "swal_btn primary",
          },
          allowOutsideClick: false,
        });
        return;
      }

      const ids = checkItems.map((item) => item.id);
      const {
        data: { DeliveryAccountBoxProductSelectDelete },
      } = await DeliveryAccountBoxProductSelectDeleteMutation({
        variables: { ids },
      });
      if (DeliveryAccountBoxProductSelectDelete) {
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };
  const [checkItems, setCheckItems] = useState([]);
  const doChecker = (event, item) => {
    const checked = event.target.checked;

    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const doAllChecker = (event) => {
    const checked = event.target.checked;

    if (checked) {
      let itemArray = [];
      deliveryBoxProducts.forEach((item) => {
        itemArray.push(item);
      });
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };

  // 구독 배송 미루기/당기기
  const [
    DeliveryAccountBoxEditStateToggleMutation,
    { loading: DeliveryAccountBoxEditStateToggleLoading },
  ] = useMutation(DELIVERY_ACCOUNT_BOX_EDIT_STATE_TOGGLE_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_BOX_DETAIL_QUERY,
        fetchPolicy: "network-only",
      },
    ],
  });
  const onEditStateToggle = async () => {
    try {
      if (DeliveryAccountBoxEditStateToggleLoading) {
        return;
      }

      const date = new Date(parseInt(deliveryDate));

      const stateDeliveryDate =
        (state === "Pay" && new Date(moment(date).add(7, "d"))) ||
        (state === "Next" && new Date(moment(date).add(-7, "d")));
      const stateDeliveryDayOfWeek = stateDeliveryDate.getDay().toString();

      const statePayDate = new Date(moment(stateDeliveryDate).add(-1, "d"));
      const statePayDayOfWeek = statePayDate.getDay().toString();

      MySwal.fire({
        html: (
          <>
            <SwalCustom>
              <div className="icon primary">
                <CalendarTick variant="Bulk" size={32} />
              </div>
              <div className="alert_title">
                <b>
                  {state === "Pay" && "배송 미루기"}
                  {state === "Next" && "배송 당기기"}
                </b>
              </div>
              <ul className="swal_row">
                <li>
                  <div className="left">{state === "Pay" && "다음 "}결제일</div>
                  <div className="right">
                    <b>
                      <ReactMoment format="YYYY.MM.DD">
                        {statePayDate}
                      </ReactMoment>
                    </b>
                    ({dayOfWeekName(statePayDayOfWeek)})
                  </div>
                </li>
                <li>
                  <div className="left">{state === "Pay" && "다음 "}배송일</div>
                  <div className="right">
                    <b>
                      <ReactMoment format="YYYY.MM.DD">
                        {stateDeliveryDate}
                      </ReactMoment>
                    </b>
                    ({dayOfWeekName(stateDeliveryDayOfWeek)})
                  </div>
                </li>
              </ul>
            </SwalCustom>
          </>
        ),
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        showCancelButton: true,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "order-2 swal_btn primary",
          cancelButton: "order-1 right-gap swal_btn lightprimary",
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {
            data: { DeliveryAccountBoxEditStateToggle },
          } = await DeliveryAccountBoxEditStateToggleMutation();

          if (DeliveryAccountBoxEditStateToggle) {
          }
        }
      });
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  return (
    <Fragment>
      <SubscribContainer>
        <div className="subscrib_top">
          <CheckBtn
            text={"전체선택"}
            onChange={doAllChecker}
            checked={
              deliveryBoxProducts.length > 0 &&
              checkItems.length === deliveryBoxProducts.length
            }
          />
          <button type="button" className="delet_btn" onClick={onSelectDelete}>
            선택삭제
          </button>
        </div>
        <div className="subscrib_list">
          {deliveryBoxProducts.length > 0 ? (
            <ul>
              {deliveryBoxProducts.map((item, index) => (
                <li key={index}>
                  <Item
                    item={item}
                    onDelete={onDelete}
                    onEditCount={onEditCount}
                    onEditState={onEditState}
                    doChecker={doChecker}
                    checkItems={checkItems}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <Ready>등록된 상품이 없습니다.</Ready>
          )}
        </div>
        <div className="subscrib_box">
          {condition === "None" && ableAmount < 0 && (
            <InfoBox className="danger">
              <b>보유금액</b>이 부족해요!
            </InfoBox>
          )}
          {condition === "None" && totalPrice < 30000 && (
            <InfoBox className="danger">
              <b>30,000</b>드림 미만시 배송하지 않습니다.
              <br />
              <b>상품</b>을 더 담아주세요!
            </InfoBox>
          )}
          <RowInfo
            lefttext={"결제금액"}
            righttext={
              <>
                <b>
                  <NumericFormat
                    displayType={"text"}
                    value={totalPrice}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </>
            }
          />
          <RowInfo
            border={"bottom"}
            lefttext={"보유금액"}
            righttext={
              <>
                <b>
                  <NumericFormat
                    displayType={"text"}
                    value={amount}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </>
            }
          />
          <RowInfo
            lefttext={"드림잔액"}
            righttext={
              <>
                <b>
                  {isCms && totalAmount < 0 && (
                    <>(땡겨쓴돈{totalAmount * -1 > cmsValue && <> 초과</>})</>
                  )}

                  <NumericFormat
                    displayType={"text"}
                    value={totalAmount}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </>
            }
          />
        </div>
        <div className="subscrib_box">
          <RowInfo
            lefttext={"결제일"}
            border={"bottom"}
            righttext={
              <>
                <ReactMoment format="YYYY.MM.DD">{boxPayDate}</ReactMoment>(
                {dayOfWeekName(boxPayDayOfWeek)})
              </>
            }
          />
          <RowInfo
            lefttext={"배송일"}
            righttext={
              <>
                <ReactMoment format="YYYY.MM.DD">{boxDeliveryDate}</ReactMoment>
                ({dayOfWeekName(boxDeliveryDayOfWeek)})
              </>
            }
          />
          <Button
            color="lightprimary"
            width="full"
            border="radius"
            onClick={onEditStateToggle}
          >
            {state === "Pay" && "배송 미루기"}
            {state === "Next" && "배송 당기기"}
          </Button>
        </div>
        <div className="subscrib_box">
          <RowInfo
            border={"bottom"}
            lefttext={"타입"}
            righttext={typeName(type)}
          />
          {type === "Pickup" && (
            <>
              <RowInfo
                border={"bottom"}
                lefttext={"배송매장"}
                righttext={<>{deliveryPlace?.deliveryStore?.store.name}</>}
              />
              <RowInfo
                border={"bottom"}
                lefttext={"연락처"}
                righttext={`${deliveryPlace?.deliveryStore?.user.phone}`}
              />
              <RowInfo
                lefttext={"주소"}
                righttext={`${deliveryPlace.roadAddress}<br />${deliveryPlace.addressDetail}`}
              />
            </>
          )}
          {type === "Delivery" && (
            <>
              <RowInfo
                border={"bottom"}
                lefttext={"배송매장"}
                righttext={<>{deliveryAddress?.deliveryStore?.store.name}</>}
              />
              <RowInfo
                border={"bottom"}
                lefttext={"연락처"}
                righttext={`${deliveryAddress?.deliveryStore?.user.phone}`}
              />
              <RowInfo
                lefttext={"주소"}
                righttext={`${deliveryAddress.roadAddress}<br />${deliveryAddress.addressDetail}`}
              />
            </>
          )}
          <Links
            to={
              (type === "Pickup" && `/account/places`) ||
              (type === "Delivery" && `/account/addresses`)
            }
            color="lightprimary"
            width="full"
            border="radius"
          >
            배송정보 변경
          </Links>
        </div>
      </SubscribContainer>
      {/* <FooterButton>
        <div>
          <Button
            color="primary"
            size="lg"
            width="full"
            onClick={onEditStateToggle}
          >
            {state === "Pay" && "배송 미루기"}
            {state === "Next" && "배송 당기기"}
          </Button>
        </div>
      </FooterButton> */}
    </Fragment>
  );
}

function Presenter({ loading, data, baseUrl }) {
  return (
    <Layout isNav={true} isFooter={true} isSearch={true}>
      <PageTitle title={`구독박스`} />

      {!loading ? <Box data={data} baseUrl={baseUrl} /> : <Fragment></Fragment>}
    </Layout>
  );
}
export default Presenter;
