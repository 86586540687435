import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Layout from "../../../../Components/Layout/Layout";
import {
  Container,
  FooterButton,
  InfoBox,
  InputGroup,
  SwalCustom,
} from "../../../../Components/Style/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import RadioBtn from "../../../../Components/Common/RadioBtn";
import FormError from "../../../../Components/Common/FormError";
import Button from "../../../../Components/Common/Buttons";
import PageTitle from "../../../../Components/Layout/PageTitle";
import { shortDayOfWeekOptions } from "../../../../Config/Codes/Address";
import SearchModal from "../../../../Components/Register/SearchModal";

import { useMutation } from "@apollo/client";
import {
  DELIVERY_ACCOUNT_ADDRESS_EDIT_MUTATION,
  DELIVERY_ACCOUNT_ADDRESS_DELETE_MUTATION,
  DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
} from "../../../../Config/Queries/Address";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Edit = ({ data }) => {
  const navigate = useNavigate();

  const {
    DeliveryAccountAddressDetail: {
      id,
      // createdAt,
      // updatedAt,

      // commonStatus,
      name,
      zipcode,
      jibunAddress,
      roadAddress,
      addressDetail,
      longitude,
      latitude,
      dayOfWeek,
      sido,
      sigungu,
      upmyundong,
      li,
    },
    DeliveryAccountStoreDongList,
    DeliveryAccountBoxDetail,
  } = data;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name,
      zipcode,
      jibunAddress,
      roadAddress,
      addressDetail,
      longitude,
      latitude,
      dayOfWeek,
      sido,
      sigungu,
      upmyundong,
      li,
    },
  });

  const [
    DeliveryAccountAddressEditMutation,
    { DeliveryAccountAddressEditLoading },
  ] = useMutation(DELIVERY_ACCOUNT_ADDRESS_EDIT_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
        variables: {
          skip: 0,
          take: 100,
        },
      },
    ],
  });

  const onSubmit = async (data) => {
    // console.log({ data });
    // return;
    try {
      if (DeliveryAccountAddressEditLoading) {
        return;
      }

      const {
        data: { DeliveryAccountAddressEdit },
      } = await DeliveryAccountAddressEditMutation({
        variables: {
          id,
          name: data.name,
          zipcode: data.zipcode,
          jibunAddress: data.jibunAddress,
          roadAddress: data.roadAddress,
          addressDetail: data.addressDetail,
          longitude: data.longitude,
          latitude: data.latitude,
          dayOfWeek: data.dayOfWeek,
          sido: data.sido,
          sigungu: data.sigungu,
          upmyundong: data.upmyundong,
          li: data.li,
        },
      });

      if (DeliveryAccountAddressEdit) {
        navigate(`/account/addresses`);
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");
      MySwal.fire({
        html: (
          <SwalCustom className="ss">
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  const [
    DeliveryAccountAddressDeleteMutation,
    { DeliveryAccountAddressDeleteLoading },
  ] = useMutation(DELIVERY_ACCOUNT_ADDRESS_DELETE_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
        variables: {
          skip: 0,
          take: 100,
        },
      },
    ],
  });

  const onDelete = async () => {
    try {
      if (DeliveryAccountAddressDeleteLoading) {
        return;
      }

      if (id === DeliveryAccountBoxDetail?.deliveryAddress?.id) {
        MySwal.fire({
          html: (
            <SwalCustom>
              <div className="icon danger">
                <FontAwesomeIcon icon={faWarning} />
              </div>
              <div className="alert_title">
                기본 배달장소는 삭제할 수 없습니다.
              </div>
            </SwalCustom>
          ),
          confirmButtonText: `확인`,
          customClass: {
            popup: "swal_popup",
            actions: "swal",
            confirmButton: "swal_btn lightprimary",
            cancelButton: "swal_btn primary",
          },
          allowOutsideClick: false,
        });
        return;
      }

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">삭제하시겠습니까?</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        cancelButtonText: "취소",
        showCancelButton: true,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {
            data: { DeliveryAccountAddressDelete },
          } = await DeliveryAccountAddressDeleteMutation({
            variables: { id },
          });

          if (DeliveryAccountAddressDelete) {
            navigate(`/account/addresses`);
          }
        }
      });
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  const [search, setSearch] = useState(false);
  const toggleSearch = () => {
    setSearch(!search);
  };

  const dongs = DeliveryAccountStoreDongList?.deliveryStoreDongs.map(
    (item) => item.upmyundong
  );
  const dongsString = dongs.join(", ");

  const deliveryStoreDong =
    DeliveryAccountStoreDongList?.deliveryStoreDongs.find(
      (item) => item.upmyundong === watch("upmyundong")
    );

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <InfoBox>
            배송이 가능한 동은 <b>{dongsString}</b>
          </InfoBox>
          <InputGroup>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "배송지 이름을 입력해주세요.",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <label className="label_txt">배송지 이름</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="배송지 이름을 입력해주세요."
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  {errors.name && <FormError message={errors.name.message} />}
                </div>
              )}
            />
            <Controller
              name="dayOfWeek"
              control={control}
              rules={{
                required: "배송요일를 입력해주세요.",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <label className="label_txt">배송요일</label>
                  <div className="row">
                    {shortDayOfWeekOptions.map((item, index) => {
                      return (
                        <RadioBtn
                          key={index}
                          id={`week_${index}`}
                          text={item.label}
                          value={item.value}
                          onChange={onChange}
                          onBlur={onBlur}
                          checked={value === item.value}
                        />
                      );
                    })}
                  </div>
                  {errors.dayOfWeek && (
                    <FormError message={errors.dayOfWeek.message} />
                  )}
                </div>
              )}
            />
            <Controller
              name="zipcode"
              control={control}
              rules={{
                required: "주소를 검색해주세요.",
                validate: (value) => {
                  return watch("upmyundong") && !deliveryStoreDong
                    ? "주소를 다시 검색해주세요."
                    : undefined;
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <label className="label_txt">우편번호</label>
                  <div className="row">
                    <div>
                      <input
                        type="text"
                        className="form_control"
                        placeholder="주소를 검색해주세요."
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        readOnly
                        onClick={toggleSearch}
                      />
                    </div>
                    <Button
                      type="button"
                      className="btn"
                      color="lightprimary"
                      onClick={toggleSearch}
                    >
                      주소검색
                    </Button>
                  </div>
                  {errors.zipcode && (
                    <FormError message={errors.zipcode.message} />
                  )}
                </div>
              )}
            />
            <Controller
              name="roadAddress"
              control={control}
              rules={{
                required: "주소를 검색해주세요.",
                validate: (value) => {
                  return watch("upmyundong") && !deliveryStoreDong
                    ? "주소를 다시 검색해주세요."
                    : undefined;
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <label className="label_txt">기본주소</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="주소를 검색해주세요."
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    readOnly
                    onClick={toggleSearch}
                  />
                  {errors.roadAddress && (
                    <FormError message={errors.roadAddress.message} />
                  )}
                </div>
              )}
            />
            <div style={{ display: "none" }}>
              <Controller
                name="jibunAddress"
                control={control}
                rules={{ required: "주소를 검색해주세요." }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="주소를 검색해주세요."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      readOnly
                    />
                    {errors.jibunAddress && (
                      <FormError message={errors.jibunAddress.message} />
                    )}
                  </div>
                )}
              />
              <Controller
                name="longitude"
                control={control}
                rules={{ required: "주소를 검색해주세요." }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="주소를 검색해주세요."
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      readOnly
                    />
                    {errors.longitude && (
                      <FormError message={errors.longitude.message} />
                    )}
                  </div>
                )}
              />
              <Controller
                name="latitude"
                control={control}
                rules={{ required: "주소를 검색해주세요." }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="주소를 검색해주세요."
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      readOnly
                    />
                    {errors.latitude && (
                      <FormError message={errors.latitude.message} />
                    )}
                  </div>
                )}
              />

              <Controller
                name="sido"
                control={control}
                rules={{ required: "주소를 검색해주세요." }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="주소를 검색해주세요."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      readOnly
                    />
                    {errors.sido && <FormError message={errors.sido.message} />}
                  </div>
                )}
              />
              <Controller
                name="sigungu"
                control={control}
                rules={{ required: "주소를 검색해주세요." }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="주소를 검색해주세요."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      readOnly
                    />
                    {errors.sigungu && (
                      <FormError message={errors.sigungu.message} />
                    )}
                  </div>
                )}
              />
              <Controller
                name="upmyundong"
                control={control}
                rules={{ required: "주소를 검색해주세요." }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="주소를 검색해주세요."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      readOnly
                    />
                    {errors.upmyundong && (
                      <FormError message={errors.upmyundong.message} />
                    )}
                  </div>
                )}
              />
            </div>
            <Controller
              control={control}
              rules={{
                required: "상세주소를 입력해주세요.",
              }}
              name="addressDetail"
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <label className="label_txt">상세주소</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="상세주소 입력"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  {errors.addressDetail && (
                    <FormError message={errors.addressDetail.message} />
                  )}
                </div>
              )}
            />
          </InputGroup>
          {deliveryStoreDong && (
            <InfoBox className="primary">
              <b>{deliveryStoreDong.deliveryStore.store.name}</b>
              에서 <b>배송</b>합니다.
            </InfoBox>
          )}
          {watch("upmyundong") && !deliveryStoreDong && (
            <InfoBox className="danger">
              <b>해당주소</b>는 <b>배송지역</b>이 아닙니다.
            </InfoBox>
          )}
        </Container>
        <FooterButton>
          <div className="inner">
            <Button
              type="submit"
              color="primary"
              size="lg"
              width="full"
              border="radius"
            >
              확인
            </Button>
            <Button
              type="button"
              onClick={() => onDelete()}
              color="danger"
              size="lg"
              width="full"
              border="radius"
            >
              삭제
            </Button>
          </div>
        </FooterButton>
      </form>
      <SearchModal
        isOpen={search}
        toggleModal={toggleSearch}
        deliveryStoreDongs={DeliveryAccountStoreDongList?.deliveryStoreDongs}
        setValue={setValue}
      />
    </Fragment>
  );
};

function Presenter({ loading, data }) {
  return (
    <Layout isNav={false} isFooter={false}>
      <PageTitle title={`주소추가`} />
      {!loading && <Edit data={data} />}
    </Layout>
  );
}

export default Presenter;
