import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../Style/Theme";

import { useQuery } from "@apollo/client";
import { DELIVERY_PRODUCT_CATEGORY_LIST_QUERY } from "../../Config/Queries/Product";

const CategoryList = styled.div`
  background-color: ${theme.colors.lightgray};
  padding: ${theme.paddings.lg} ${theme.paddings.xl} ${theme.paddings.base};
  ul {
    flex-wrap: wrap;
    display: flex;
    li {
      padding: 0 ${theme.paddings.sm} 0 0;
      margin-bottom: ${theme.paddings.base};
      a {
        display: block;
        background-color: white;
        padding: ${theme.paddings.sm} ${theme.paddings.base};
        border: solid 1px #eee;
        border-radius: 15px;
        text-align: center;
        font-size: 15px;
        color: ${theme.colors.black02};
        &.active {
          background-color: ${theme.colors.primary};
          border: solid 1px ${theme.colors.primary};
          font-weight: ${theme.fonts.weight.bold};
          color: white;
        }
      }
    }
  }
`;

function Category({ baseUrl, deliveryProductCategory_id, keyword, orderBy }) {
  const { data, error, loading } = useQuery(
    DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { skip: 0, take: 100 },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  const urlConfig = (item) => {
    const url =
      baseUrl +
      "?" +
      (item?.id ? `deliveryProductCategory_id=${item?.id}&` : ``) +
      (keyword ? `keyword=${keyword}&` : ``) +
      (orderBy ? `orderBy=${orderBy}&` : ``) +
      `page=1`;

    return url;
  };

  return (
    <CategoryList>
      <ul>
        <li>
          <Link
            to={urlConfig()}
            className={deliveryProductCategory_id === "" ? "active" : ""}
          >
            전체
          </Link>
        </li>
        {!loading &&
          data.DeliveryProductCategoryList.deliveryProductCategories.map(
            (item, index) => {
              return (
                <li key={index}>
                  <Link
                    to={urlConfig(item)}
                    className={
                      deliveryProductCategory_id === item.id ? "active" : ""
                    }
                  >
                    {item.name} <span>{item.deliveryProductsCount}</span>
                  </Link>
                </li>
              );
            }
          )}
      </ul>
    </CategoryList>
  );
}

export default Category;
