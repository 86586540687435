import React from "react";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_ACCOUNT_STORE_DONG_LIST_QUERY } from "../../../../Config/Queries/StoreDong";

const Container = () => {
  const { data, loading, error } = useQuery(
    DELIVERY_ACCOUNT_STORE_DONG_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        take: 100,
        skip: 0,
      },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return <Presenter loading={loading} data={data} />;
};

export default Container;
