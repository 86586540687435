import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../Components/Layout/Layout";
import Category from "../../../Components/Product/Category";
import Sort from "../../../Components/Product/Sort";
import {
  ProductList,
  ProductTitle,
  Ready,
} from "../../../Components/Style/Styles";
import ProductItem from "../../../Components/Product/ProductItem";
import PageTitle from "../../../Components/Layout/PageTitle";
import { NumericFormat } from "react-number-format";
import Pagination from "../../../Components/Common/Pagination";

function Presenter({
  deliveryProductCategory_id,
  keyword,
  orderBy,
  page,
  take,
  loading,
  data,
  baseUrl,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultUrl =
    baseUrl +
    "?" +
    (deliveryProductCategory_id
      ? `deliveryProductCategory_id=${deliveryProductCategory_id}&`
      : ``) +
    (keyword ? `keyword=${keyword}&` : ``) +
    (orderBy ? `orderBy=${orderBy}&` : ``);

  return (
    <Layout isNav={true} isFooter={true} isSearch={true}>
      <PageTitle title={`상품`} />
      <Category
        baseUrl={baseUrl}
        deliveryProductCategory_id={deliveryProductCategory_id}
        keyword={keyword}
        orderBy={orderBy}
      />
      <ProductTitle>
        <h3>
          상품{" "}
          <b>
            <NumericFormat
              displayType={"text"}
              value={!loading ? data.DeliveryProductList.count : 0}
              thousandSeparator={true}
            />
          </b>
          건
        </h3>
        <Sort
          baseUrl={baseUrl}
          deliveryProductCategory_id={deliveryProductCategory_id}
          keyword={keyword}
          orderBy={orderBy}
        />
      </ProductTitle>
      {!loading ? (
        <Fragment>
          {data.DeliveryProductList.count > 0 ? (
            <>
              <ProductList>
                <ul>
                  {data.DeliveryProductList.deliveryProducts.map(
                    (item, index) => {
                      return (
                        <li key={index}>
                          <Link to={`${baseUrl}/${item.id}${location.search}`}>
                            <ProductItem item={item} />
                          </Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              </ProductList>
              <Pagination
                totalRecord={data.DeliveryProductList.count}
                blockSize={5}
                pageSize={take}
                currentPage={page}
                baseUrl={`${defaultUrl}`}
              />
            </>
          ) : (
            <Ready>등록된 상품이 없습니다.</Ready>
          )}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Presenter;
