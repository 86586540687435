import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../../Components/Style/Theme";
import Layout from "../../../Components/Layout/Layout";
import Item from "../../../Components/Delivery/Item";
import ProductList from "../../../Components/Delivery/ProductList";
import { delivery, product } from "../../../Components/Datas";
import { Dot, Ready, Row } from "../../../Components/Style/Styles";
import PageTitle from "../../../Components/Layout/PageTitle";
import { Box, Check, TickCircle, TruckFast } from "iconsax-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import RowInfo from "../../../Components/Common/RowInfo";
import ReactMoment from "react-moment";
import {
  stateName,
  typeName,
  dayOfWeekName,
} from "../../../Config/Codes/Order";
import { NumericFormat } from "react-number-format";
import { uri } from "../../../Config/Apollo";
import KakaoMap from "../../../Components/Common/KakaoMap";

const OrderContainer = styled.div`
  .order_status {
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
    border-bottom: solid 5px #f5f5f5;
    ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      li {
        background-color: white;
        width: 72px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        z-index: 12;
        .icon {
          background-color: #f5f5f5;
          display: flex;
          width: 42px;
          height: 42px;
          border-radius: 24px;
          color: ${theme.colors.black05};
          align-items: center;
          justify-content: center;
        }
        p {
          margin-top: ${theme.margins.base};
          text-align: center;
          font-size: 15px;
          color: ${theme.colors.black05};
        }
        &.active {
          .icon {
            background-color: ${theme.colors.primary};
            color: white;
          }
          &.bar {
            background-color: ${theme.colors.primary};
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: -17px;
        height: 4px;
        background-color: #f5f5f5;
        border-radius: 2px;
        z-index: 11;
      }
    }
  }
  .inner {
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
    border-bottom: solid 5px #f5f5f5;
  }
  .title {
    font-size: ${theme.fonts.size.base};
    color: ${theme.colors.black01};
  }
  .label {
    color: ${theme.colors.black04};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    &.primary {
      color: ${theme.colors.primary};
    }
    &.danger {
      color: ${theme.colors.primary};
    }
  }
  .delivery_box {
    margin-bottom: ${theme.margins.base};
    background-color: ${theme.colors.lightgray};
    border-radius: 8px;
    padding: ${theme.paddings.base};
  }
  &.open {
    opacity: 1;
    height: auto;
  }
  &.hide {
    opacity: 0;
    height: 0 !important;
    transition: all 0.3s ease-out;
  }
  .order_map {
    margin-top: ${theme.margins.base};
    border-radius: 8px;
    min-height: 140px;
    overflow: hidden;
    border: solid 1px #f5f5f5;
  }
  .product_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: ${theme.fonts.size.base};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.black01};
    }
  }
  .product_list {
    transition: all 0.3s linear;
    overflow: hidden;
    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin-bottom: ${theme.margins.base};
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        margin-top: ${theme.margins.base};
        padding-bottom: ${theme.margins.base};
        border-bottom: solid 1px #eee;
        &:last-child {
          margin-bottom: 0;
        }
        .img {
          position: relative;
          border-radius: 8px;
          width: 70px;
          height: 70px;
          overflow: hidden;
          img {
            max-width: 100%;
            display: block;
            width: 100%;
            height: auto;
          }
        }
        .cont {
          padding-left: ${theme.paddings.base};
          strong {
            display: block;
            font-size: ${theme.fonts.size.base};
            font-weight: ${theme.fonts.weight.medium};
            color: ${theme.colors.black01};
          }
          .option {
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black04};
          }
          .row {
            margin-top: ${theme.margins.sm};
            display: flex;
            align-items: center;
            font-size: ${theme.fonts.size.base};
            color: ${theme.colors.black02};
          }
        }
        &.missing {
          .cont {
            opacity: 0.5;
          }
          .img {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              top: 0;
              left: 0;
              right: 0;
            }
            span {
              position: absolute;
              left: 5px;
              right: 5px;
              top: 50%;
              background-color: rgba(0, 0, 0, 0.5);
              padding: ${theme.paddings.sm} ${theme.paddings.base};
              font-size: ${theme.fonts.size.sm};
              color: white;
              text-align: center;
              transform: translateY(-50%);
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
`;

function Detail({ data, baseUrl }) {
  const {
    DeliveryAccountOrderDetail: {
      id,
      createdAt,
      updatedAt,

      state,
      deliverAt,
      deliveryOrderProducts,
      price,
      refundPrice,
      totalPrice,

      type,
      zipcode,
      jibunAddress,
      roadAddress,
      addressDetail,
      dayOfWeek,

      deliveryStore,
      deliveryOrderSteps,
    },
  } = data;

  console.log({ deliveryOrderProducts });

  const deliverAtDayOfWeek = new Date(deliverAt).getDay().toString();

  const [productIsOpen, setProductIsOpen] = useState(true);
  const content = useRef(null);
  const [height, setHeight] = useState("");
  const productIsOpenToggle = () => {
    setProductIsOpen(!productIsOpen);
  };

  const deliveryOrderStep = deliveryOrderSteps[deliveryOrderSteps.length - 1];

  useEffect(() => {
    setHeight(productIsOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [productIsOpen]);

  return (
    <OrderContainer>
      <div className="order_status">
        <ul>
          <li className={`${state === "Ready" ? "active" : ""}`}>
            <div className="icon">
              <Box variant="Linear" size={24} />
            </div>
            <p>배송준비중</p>
          </li>
          <li className={`${state === "Delivery" ? "active" : ""}`}>
            <div className="icon">
              <TruckFast variant="Linear" size={24} />
            </div>
            <p>배송중</p>
          </li>
          <li className={`${state === "Completed" ? "active" : ""}`}>
            <div className="icon">
              <TickCircle variant="Linear" size={24} />
            </div>
            <p>배송완료</p>
          </li>
        </ul>
      </div>
      <div className="inner">
        <RowInfo
          border={"bottom"}
          lefttext={<span className="label primary">{stateName(state)}</span>}
        />
        <RowInfo
          border={"bottom"}
          lefttext={"배송일"}
          righttext={
            <>
              <ReactMoment format="YYYY.MM.DD">{deliverAt}</ReactMoment>(
              {dayOfWeekName(deliverAtDayOfWeek)})
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          lefttext={"타입"}
          righttext={typeName(type)}
        />
        <RowInfo
          border={"bottom"}
          lefttext={"주소"}
          righttext={`${roadAddress}<br />${addressDetail}`}
        />
        <RowInfo
          border={"bottom"}
          lefttext={"배송매장"}
          righttext={`${deliveryStore.store.name}`}
        />
        <RowInfo
          border={"bottom"}
          lefttext={"연락처"}
          righttext={deliveryStore.user.phone}
        />
      </div>

      {deliveryOrderStep && (
        <div className="inner">
          <strong className="title">위치정보</strong>
          <div className="order_map">
            <KakaoMap
              longitude={deliveryOrderStep.longitude}
              latitude={deliveryOrderStep.latitude}
            />
          </div>
        </div>
      )}

      <div className="inner">
        <div className="product_top" onClick={productIsOpenToggle}>
          <span>상품정보</span>
          <FontAwesomeIcon
            icon={productIsOpen ? faAngleUp : faAngleDown}
            size="2x"
            color={theme.colors.black04}
          />
        </div>
        <div
          ref={content}
          className={`product_list ${productIsOpen ? `open` : `hide`}`}
          style={{ height: `${height}` }}
        >
          <ul>
            {deliveryOrderProducts.map((deliveryOrderProduct, index) => {
              const cover =
                deliveryOrderProduct.deliveryProduct.coverImage === "" ||
                deliveryOrderProduct.deliveryProduct.coverImage === null
                  ? "/assets/img/product/product-default.jpg"
                  : `${uri}${deliveryOrderProduct.deliveryProduct.coverImage}`;
              return (
                <li
                  className={
                    deliveryOrderProduct.state === "Refund" ? "missing" : ""
                  }
                  key={index}
                >
                  <Link
                    to={`/products/${deliveryOrderProduct.deliveryProduct.id}`}
                    className="img"
                  >
                    {deliveryOrderProduct.state === "Refund" && (
                      <span>결품</span>
                    )}
                    <img src={cover} />
                  </Link>
                  <Link
                    to={`/products/${deliveryOrderProduct.deliveryProduct.id}`}
                    className="cont"
                  >
                    <strong>
                      {deliveryOrderProduct.state === "Refund" && <>[환불]</>}
                      {deliveryOrderProduct.deliveryProduct.name}
                    </strong>
                    {(deliveryOrderProduct.deliveryProductOptionDetail1 ||
                      deliveryOrderProduct.deliveryProductOptionDetail2 ||
                      deliveryOrderProduct.deliveryProductOptionDetail3) && (
                      <span className="option">
                        [옵션]
                        {deliveryOrderProduct.deliveryProductOptionDetail1 &&
                          ` ${deliveryOrderProduct.deliveryProductOptionDetail1.name}`}
                        {deliveryOrderProduct.deliveryProductOptionDetail2 &&
                          ` / ${deliveryOrderProduct.deliveryProductOptionDetail2.name}`}
                        {deliveryOrderProduct.deliveryProductOptionDetail3 &&
                          ` / ${deliveryOrderProduct.deliveryProductOptionDetail3.name}`}
                      </span>
                    )}
                    <Row className="row">
                      <b>{deliveryOrderProduct.count}</b>개
                      <Dot />
                      <b>
                        <NumericFormat
                          displayType={"text"}
                          value={deliveryOrderProduct.totalPrice}
                          thousandSeparator={true}
                        />
                      </b>{" "}
                      드림
                    </Row>
                  </Link>
                </li>
              );
            })}
          </ul>
          <RowInfo
            total={"true"}
            lefttext={"총 결제금액"}
            righttext={
              <>
                <b>
                  <NumericFormat
                    displayType={"text"}
                    value={totalPrice}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </>
            }
          />
        </div>
      </div>
    </OrderContainer>
  );
}

function Presenter({ loading, data, baseUrl }) {
  return (
    <Layout isNav={true} isFooter={true} isSearch={true}>
      <PageTitle title={`배송관리`} />

      {!loading ? (
        <Detail data={data} baseUrl={baseUrl} />
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Presenter;
