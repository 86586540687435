import styled, { createGlobalStyle } from "styled-components"
import reset from "styled-reset"
import theme from "../../Components/Style/Theme"
import "./style.css"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

export const GlobalStyles = createGlobalStyle`
  ${reset}; 
        * {
          box-sizing: border-box;
          margin: 0;
        }
        html {
          font-size: 62.5%; //1rem = 10px;
          height: 100%;
        }
        body {
          height: 100%;
          font-family: ${theme.fonts.family.base};
          line-height: 1.4;
          background-color: #eee;
          ${theme.device.mobile} {
            background-color: white;
          } 
          &.swal2-height-auto{
            height: 100%!important;
          }
        }
        #root {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        textarea,
        input {
          font-family: ${theme.fonts.family.base};
          font-size: 14px;
          ::placeholder {
            color: ${theme.colors.black04};
          }
        }
        input {
        }
        textarea {
          border: none;
          background-color: transparent;
          resize: none;
          outline: none;
        }
        button,
        a {
          cursor: pointer;
        }
        button {
          border: 0;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        .m-t {
          margin-top: 1rem;
        }
        img {
          max-width: 100%;
        }
        b,
        strong {
          font-weight: ${theme.fonts.weight.bold};
        }
`

export const Wrapper = styled.div`
  position: relative;
  background-color: white;
  margin: 0 auto;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 750px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Between = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Span = styled.div`
  margin: 0 5px;
`
export const Dot = styled.div`
  background-color: ${theme.colors.black03};
  width: 4px;
  height: 4px;
  margin: 0 5px;
  border-radius: 3px;
`
export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: ${theme.paddings.lg} ${theme.paddings.xl};
`
export const MainTitle = styled.div`
  padding: 0 ${theme.paddings.xl};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.margins.base};
  align-items: center;
  h3 {
    font-size: ${theme.fonts.size.lg};
    color: ${theme.colors.black01};
    font-weight: ${theme.fonts.weight.bold};
    b {
      color: ${theme.colors.primary};
    }
  }
  .more {
    display: flex;
    align-items: center;
    font-size: ${theme.fonts.size.sm};
    line-height: 20px;
    color: ${theme.colors.black04};
  }
`

export const TabsNav = styled.div`
  border-bottom: solid 1px #eee;
  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    li {
      flex: 1 0 auto;
      text-align: center;
      justify-content: center;
      a {
        display: inline-flex;
        height: 40px;
        align-items: center;
        color: ${theme.colors.black04};
        font-size: ${theme.fonts.size.base};
        padding: 0 ${theme.paddings.sm};
      }
      &.active {
        a {
          border-bottom: solid 2px ${theme.colors.black01};
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.bold};
        }
      }
    }
  }
`

export const Select = styled.div`
  position: relative;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    right: 10px;
    top: 19px;
    z-index: 999;
    width: 0px;
    height: 0px;
    border-radius: 3px;
    border-bottom: 6px solid transparent;
    border-top: 6px solid ${theme.colors.black01};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  select {
    position: relative;
    background-color: white;
    height: 44px;
    width: 100%;
    padding: 0 ${theme.paddings.base};
    border: solid 1px #e4e7ed;
    border-radius: 8px;
    color: ${theme.colors.black04};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #444;
    font-size: ${theme.fonts.size.base};
  }
`
export const InputGroup = styled.div`
  margin-top: ${theme.margins.base};
  .form_group {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${theme.margins.lg};
  }
  .label_txt {
    color: ${theme.colors.black04};
    font-size: 13px;
    margin-bottom: ${theme.margins.sm};
  }
  .desc {
    margin-top: ${theme.margins.sm};
    color: ${theme.colors.black04};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: -0.3px;
  }
  .form_control {
    width: 100%;
    position: relative;
    height: 46px;
    padding: 0 ${theme.paddings.base};
    border: solid 1px #eee;
    border-radius: 4px;
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.normal};
    &::placeholder {
      font-size: ${theme.fonts.size.base};
      color: #bbb;
    }
    &:hover {
      border: 1px solid ${theme.colors.primary};
    }
    &:focus-visible {
      outline: none !important;
      border: 1px solid ${theme.colors.primary};
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    div {
      flex: 1;
    }
    button.btn {
      margin-left: ${theme.paddings.base};
      display: flex;
      height: 46px;
      align-items: center;
      border-radius: 4px;
      background-color: ${theme.colors.lightprimary};
      color: ${theme.colors.primary};
      padding: 0 ${theme.paddings.lg};
      font-size: ${theme.fonts.size.base};
      font-weight: ${theme.fonts.weight.normal};
    }
  }
`
export const InfoBox = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: ${theme.paddings.base};
  font-size: ${theme.fonts.size.base};
  font-weight: ${theme.fonts.weight.normal};
  color: ${theme.colors.black02};
  text-align: center;
  &.primary {
    background-color: ${theme.colors.lightprimary};
    color: ${theme.colors.primary};
  }
  &.danger {
    background-color: ${theme.colors.lightdanger};
    color: ${theme.colors.danger};
  }
`
export const DetailInfo = styled.div`
  padding: ${theme.paddings.lg} ${theme.paddings.xl};
  border-bottom: solid 5px #f5f5f5;
  .category {
    color: ${theme.colors.black04};
    font-size: ${theme.fonts.size.sm};
    font-weight: 400;
  }
  h3 {
    margin-top: ${theme.margins.sm};
    font-size: 20px;
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.black01};
  }
  .price {
    display: block;
    margin-top: ${theme.margins.base};
    font-size: 20px;
    color: ${theme.colors.black01};
    font-weight: ${theme.fonts.weight.normal};
    b {
      font-weight: ${theme.fonts.weight.bold};
    }
  }
  .accordion_box {
    margin-top: ${theme.margins.base};
  }
`

export const Ready = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 180px;
  color: ${theme.colors.black04};
  font-size: ${theme.fonts.size.sm};
  text-align: center;
  justify-content: center;
  align-items: center;
`
export const ProductList = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px;
    li {
      width: 50%;
      padding: 0 ${theme.paddings.base};
      margin-bottom: ${theme.margins.lg};
      a {
        display: block;
      }
    }
  }
`
export const ProductTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.paddings.lg} ${theme.paddings.xl};
  h3 {
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.black01};
    b {
      color: ${theme.colors.primary};
    }
  }
  .sort {
  }
`

export const SortOption = styled.div`
  position: relative;
  z-index: 999;
  .Dropdown-root {
    .Dropdown-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: ${theme.paddings.sm} ${theme.paddings.base};
      border: solid 1px #eee;
      border-radius: 24px;
      font-weight: ${theme.fonts.weight.medium};
      :hover {
        box-shadow: none;
      }
      .Dropdown-placeholder {
        font-size: 15px;
        color: #777;
        letter-spacing: -0.5px;
        :hover {
          box-shadow: none;
          color: ${theme.colors.primary};
        }
      }
    }
    .Dropdown-menu {
      position: absolute;
      background-color: white;
      width: 100px;
      right: 0;
      border-radius: 5px;
      overflow: hidden;
      border: solid 1px #eee;
      .Dropdown-option {
        display: flex;
        justify-content: center;
        font-size: ${theme.fonts.size.sm};
        padding: 1rem 0.6rem;
        border-bottom: solid 1px #eee;
        color: #777;
        font-weight: ${theme.fonts.weight.medium};
        :last-child {
          border-bottom: 0;
        }
        &:hover {
          background-color: ${theme.colors.lightgray};
        }
      }
      .Dropdown-option.is-selected {
        background-color: ${theme.colors.gray};
        color: ${theme.colors.black01};
      }
    }
  }
  .Dropdown-root.is-open {
    .Dropdown-placeholder {
      color: ${theme.colors.primary};
    }
    .Dropdown-control {
      border: solid 1px ${theme.colors.primary};
      color: ${theme.colors.primary};
    }
  }
  .Dropdown-arrow-wrapper {
    font-size: 15px;
    margin-left: 5px;
  }
`
export const Updown = styled.div`
  height: 46px;
  display: flex;
  flex-direction: row;
  button {
    background-color: ${theme.colors.lightgray};
    border: solid 1px ${theme.colors.gray};
    width: 46px;
    height: 46px;
    color: ${theme.colors.black01};
    font-size: ${theme.fonts.size.base};
    &:first-child {
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  div {
    flex: 1;
    height: 46px;
    input {
      width: 100%;
      height: 46px;
      background-color: #fff;
      text-align: center;
      border: solid 1px ${theme.colors.gray};
      font-size: ${theme.fonts.size.base};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.black01};
      outline: 0;
    }
  }
`
export const SwalCustom = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    color: #999;
    border-radius: 35px;
    width: 64px;
    height: 64px;
    font-size: 32px;
    &.primary {
      background-color: #f4faf0;
      color: #8ec96d;
    }
    &.danger {
      background-color: #ffe5ea;
      color: #ff5473;
    }
    &.warning {
      background-color: #ffeee5;
      color: #ff8f50;
    }
    &.success {
      background-color: #eaf0ff;
      color: #2d68ff;
    }
  }
  .alert_title {
    font-size: 20px;
    line-height: 1.4;
    color: #222;
  }
  .swal_row {
    background-color: #f6f6f6;
    margin-top: 18px;
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #e5e5e5;
      padding-bottom: 10px;
      &:last-child {
        padding-top: 10px;
        padding-bottom: 0;
        border-bottom: 0;
      }
      .left {
        width: 100px;
        text-align: left;
        color: #444;
        font-size: 16px;
      }
      .right {
        flex: 1;
        text-align: right;
        color: #222;
        font-size: 16px;
      }
    }
  }
`

export const DetailContainer = styled.div`
  .detail_img {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    img {
      max-width: 100%;
      display: block;
      width: 100%;
      height: auto;
      &.sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }
  }
  .detail_content {
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.normal};
    line-height: 1.4;
    color: ${theme.colors.black02};
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
    h4 {
      font-weight: ${theme.fonts.weight.medium};
      margin-bottom: 5px;
      line-height: 1.4;
    }
    div {
      transition: all 0.25s linear;
      &.open {
        height: auto;
        overflow-y: hidden;
      }
      &.hide {
        height: 100px;
        overflow: hidden;
      }
    }
    .policy {
      .cont {
        p {
          margin-bottom: ${theme.margins.base};
          font-size: 15px;
        }
      }
      ul {
        li {
          margin: 0 0 ${theme.margins.sm} ${theme.margins.base};
          font-size: 15px;
          &.first {
            margin: ${theme.margins.base} 0 ${theme.margins.sm} 0;
          }
        }
      }
    }
  }
`
export const AddressList = styled.div`
  display: flex;
  height: 450px;
  overflow-y: scroll;
  padding: ${theme.paddings.lg} ${theme.paddings.xl};
  ul {
    li {
      border-bottom: solid 1px #e4e7ed;
      padding-bottom: ${theme.paddings.base};
      margin-bottom: ${theme.paddings.base};
      a {
        display: block;
        strong {
          font-size: ${theme.fonts.size.lg};
        }
        .info {
          margin-top: ${theme.paddings.sm};
          .text {
            flex: 1;
            font-size: 15px;
            font-weight: ${theme.fonts.weight.normal};
            color: ${theme.colors.black02};
          }
          .row {
            display: flex;
            flex-direction: row;
            margin-top: ${theme.paddings.xs};
            span {
              width: 60px;
              font-size: 15px;
              font-weight: ${theme.fonts.weight.normal};
              color: ${theme.colors.black04};
            }
          }
        }
      }
    }
  }
`
export const SearchInput = styled.div`
  border-bottom: 5px solid ${theme.colors.gray};
  padding: ${theme.paddings.lg} ${theme.paddings.xl};
  .form_group {
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    div {
      flex: 1;
    }
  }
  .label_txt {
    color: ${theme.colors.black04};
    font-size: 13px;
    margin-bottom: ${theme.margins.sm};
  }
  .form_control {
    width: 100%;
    position: relative;
    height: 46px;
    border-radius: 8px;
    background-color: white;
    padding: 0 ${theme.paddings.base};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.normal};
    border: solid 1px #eee;
    &::placeholder {
      font-size: ${theme.fonts.size.base};
      color: #bbb;
    }
    &:focus-visible {
      outline: none !important;
    }
  }
  .btn {
    background-color: ${theme.colors.lightprimary};
    margin-left: 5px;
    border-radius: 8px;
    height: 46px;
    color: ${theme.colors.primary};
    padding: 0 ${theme.paddings.lg};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.normal};
  }
`
export const ProvisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.colors.lightgray};
  padding: ${theme.paddings.base};
  line-height: 1.4;
  h1 {
    font-size: ${theme.fonts.size.lg};
    text-align: center;
    padding: ${theme.paddings.base};
    color: ${theme.colors.black01};
  }
  .inner {
    flex: 1 0 auto;
    margin-top: ${theme.paddings.base};
    padding: ${theme.paddings.lg};
    background-color: white;
    font-family: ${theme.fonts.family.base};
    font-size: 1.4rem;
    line-height: 1.4;
    color: #666;
  }
`
export const SearchGroup = styled(InputGroup)`
  border-bottom: 5px solid ${theme.colors.gray};
  padding: ${theme.paddings.lg} ${theme.paddings.xl};
  margin-top: 0;
  .form_group {
    margin-bottom: 0;
  }
`
export const Bundle = styled.div`
  ul {
    li {
      display: flex;
      flex-direction: column;
      padding: ${theme.paddings.base} 0;
      border-bottom: solid 1px #eee;
      &:last-child {
        border: 0;
      }
      .cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        strong {
          flex: 1;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: ${theme.fonts.size.base};
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
          span {
            font-weight: ${theme.fonts.weight.normal};
            font-size: 15px;
            color: ${theme.colors.black03};
          }
        }
      }
      .count {
        width: 50px;
        text-align: center;
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.black01};
        font-weight: ${theme.fonts.weight.normal};
        b {
          font-weight: ${theme.fonts.weight.bold};
        }
      }
      .price {
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black01};
        font-weight: ${theme.fonts.weight.normal};
        b {
          font-weight: ${theme.fonts.weight.bold};
        }
      }
      .option {
        margin-top: 5px;
        color: ${theme.colors.black04};
        font-size: ${theme.fonts.size.sm};
        font-weight: 400;
      }
    }
  }
  .total {
    background-color: ${theme.colors.lightgray};
    margin: 0 -10px -5px;
    padding: ${theme.paddings.sm} ${theme.paddings.base};
  }
`
export const SearchContainer = styled.div`
  .search_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.margins.base};
    align-items: center;
    h3 {
      font-size: ${theme.fonts.size.base};
      color: ${theme.colors.black01};
      font-weight: ${theme.fonts.weight.medium};
    }
    .more {
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: ${theme.fonts.size.sm};
      line-height: 20px;
      color: ${theme.colors.black04};
    }
  }
  .search_recent {
    border-bottom: 5px solid ${theme.colors.gray};
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
  }
  .search_category {
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
  }
  .recent_list {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 0 ${theme.margins.sm} ${theme.margins.base} 0;
        margin-right: ${theme.margins.sm};
        padding: ${theme.paddings.sm} ${theme.paddings.base};
        border: 1px solid ${theme.colors.gray};
        border-radius: 16px;
        span {
          font-size: 15px;
          color: ${theme.colors.black01};
          letter-spacing: -0.5px;
        }
        button {
          margin-left: ${theme.margins.sm};
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }
  .category_list {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 1 0 25%;
        padding: 0 ${theme.paddings.base};
        margin-bottom: ${theme.margins.base};
        a {
          display: block;
          text-align: center;
          .img {
            display: inline-block;
            background-color: ${theme.colors.lightprimary};
            width: 58px;
            height: 58px;
            border-radius: 32px;
            overflow: hidden;
            img {
              max-width: 100%;
            }
          }
          p {
            height: 38px;
            overflow: hidden;
            margin-top: ${theme.margins.sm};
            text-align: center;
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.medium};
            color: ${theme.colors.black02};
            b {
              color: ${theme.colors.primary};
            }
          }
        }
      }
    }
  }
`
export const FooterButton = styled.div`
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  div {
    padding: ${theme.paddings.lg} ${theme.paddings.xl};
    background-color: white;
    flex-direction: row;
    display: flex;
    &.shadow {
      box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
    }
    a,
    button {
      height: 60px;
      line-height: 60px;
      :last-child {
        margin-left: 10px;
      }
    }
  }
`

export const ModalContainer = styled.div`
  padding: ${theme.paddings.base} ${theme.paddings.xl} ${theme.paddings.xl};
  .selects {
    margin-bottom: ${theme.margins.base};
    &:last-child {
      margin-bottom: 0;
    }
  }
  .price_content {
    margin-bottom: ${theme.margins.lg};
    background-color: ${theme.colors.lightgray};
    border: 1px solid ${theme.colors.gray};
    border-radius: 8px;
    overflow: hidden;
    h3 {
      font-size: ${theme.fonts.size.lg};
      font-weight: ${theme.fonts.weight.medium};
      color: ${theme.colors.black01};
    }
    .price_info {
      padding: ${theme.paddings.lg} ${theme.paddings.lg} ${theme.paddings.sm};
    }
    .price_total {
      background-color: white;
      border-top: 1px solid ${theme.colors.gray};
      padding: ${theme.paddings.sm} ${theme.paddings.lg};
    }
  }
  .order_box {
    background-color: ${theme.colors.lightgray};
    margin-top: 15px;
    padding: 14px;
    border-radius: 8px;
    border: solid 1px #f5f5f5;
    .order_text {
      strong {
        font-size: ${theme.fonts.size.sm};
      }
      p {
        font-size: ${theme.fonts.size.sm};
      }
    }
  }
`
