import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../../../Components/Layout/Layout";
import theme from "../../../../Components/Style/Theme";
import {
  Container,
  Select,
  TabsNav,
  Ready,
  Span,
  Row,
} from "../../../../Components/Style/Styles";
import RowInfo from "../../../../Components/Common/RowInfo";
import PageTitle from "../../../../Components/Layout/PageTitle";
import {
  dayOfWeekOptions,
  dayOfWeekName,
} from "../../../../Config/Codes/Place";
import { SwalCustom } from "../../../../Components/Style/Styles";
import Buttons from "../../../../Components/Common/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import { useMutation } from "@apollo/client";
import {
  DELIVERY_ACCOUNT_BOX_WRITE_MUTATION,
  DELIVERY_ACCOUNT_BOX_EDIT_MUTATION,
} from "../../../../Config/Queries/Box";
import { DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY } from "../../../../Config/Queries/Address";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const PickupList = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  ul {
    margin-top: ${theme.margins.base};
    li {
      margin-bottom: ${theme.margins.base};
      width: 100%;
      background-color: ${theme.colors.lightgray};
      border: solid 1px ${theme.colors.lightgray};
      padding: ${theme.paddings.lg};
      border-radius: 12px;
      .title {
        display: block;
        margin-bottom: ${theme.margins.sm};
        color: ${theme.colors.black01};
        font-size: ${theme.fonts.size.base};
      }
      &.active {
        background-color: white;
        border: solid 1px ${theme.colors.primary};
      }
    }
  }
`;

function Pickup({ dayOfWeek, page, take, loading, data, baseUrl }) {
  const navigate = useNavigate();

  const [DeliveryAccountBoxWriteMutation, { DeliveryAccountBoxWriteLoading }] =
    useMutation(DELIVERY_ACCOUNT_BOX_WRITE_MUTATION, {
      refetchQueries: [
        {
          query: DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
          variables: { skip: 0, take: 100 },
        },
      ],
    });

  const [DeliveryAccountBoxEditMutation, { DeliveryAccountBoxEditLoading }] =
    useMutation(DELIVERY_ACCOUNT_BOX_EDIT_MUTATION, {
      refetchQueries: [
        {
          query: DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
          variables: { skip: 0, take: 100 },
        },
      ],
    });

  const onSelect = async (item) => {
    try {
      if (!data.DeliveryAccountBoxDetail) {
        if (DeliveryAccountBoxWriteLoading) {
          return;
        }

        const {
          data: { DeliveryAccountBoxWrite },
        } = await DeliveryAccountBoxWriteMutation({
          variables: { type: "Pickup", deliveryPlace_id: item.id },
        });

        if (DeliveryAccountBoxWrite) {
          MySwal.fire({
            html: (
              <SwalCustom>
                <div className="icon primary">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="alert_title">
                  <b>픽업정보</b>가
                  <br />
                  등록되었습니다.
                </div>
              </SwalCustom>
            ),
            customClass: {
              popup: "swal_popup",
              actions: "swal",
              confirmButton: "swal_btn lightprimary",
            },
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/main");
            }
          });
        }
      } else {
        if (DeliveryAccountBoxEditLoading) {
          return;
        }

        const {
          data: { DeliveryAccountBoxEdit },
        } = await DeliveryAccountBoxEditMutation({
          variables: { type: "Pickup", deliveryPlace_id: item.id },
        });

        if (DeliveryAccountBoxEdit) {
          MySwal.fire({
            html: (
              <SwalCustom>
                <div className="icon primary">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="alert_title">
                  <b>픽업정보</b>가
                  <br />
                  설정되었습니다.
                </div>
              </SwalCustom>
            ),
            customClass: {
              popup: "swal_popup",
              actions: "swal",
              confirmButton: "swal_btn lightprimary",
            },
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/account/places");
            }
          });
        }
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  const onChange = (e) => {
    try {
      const url =
        baseUrl +
        "?" +
        (e.target.value ? `dayOfWeek=${e.target.value}&` : ``) +
        `page=1`;
      navigate(url);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Layout isNav={false} isFooter={false} isSearch={false}>
      <PageTitle title={`픽업`} />
      <TabsNav>
        <ul>
          <li className="active">
            <Link to="/account/places" replace={true}>
              <span>픽업</span>
            </Link>
          </li>
          <li>
            <Link to="/account/addresses" replace={true}>
              <span>배달</span>
            </Link>
          </li>
        </ul>
      </TabsNav>
      <Container>
        <PickupList>
          <Select>
            <select onChange={onChange} defaultValue={dayOfWeek}>
              <option value="">전체 요일</option>
              {dayOfWeekOptions.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </Select>
          {!loading && data.DeliveryAccountPlaceList.count > 0 ? (
            <ul>
              {data.DeliveryAccountPlaceList.deliveryPlaces.map(
                (item, index) => {
                  const isActive =
                    data.DeliveryAccountBoxDetail?.type === "Pickup" &&
                    data.DeliveryAccountBoxDetail?.deliveryPlace.id === item.id;

                  return (
                    <li key={index} className={isActive ? "active" : ""}>
                      <strong className="title">{item.name}</strong>
                      <RowInfo
                        lefttext={"주소"}
                        righttext={`${item.roadAddress}<br />${item.addressDetail}`}
                      />
                      <RowInfo
                        lefttext={"픽업요일"}
                        righttext={dayOfWeekName(item.dayOfWeek)}
                      />
                      <RowInfo
                        lefttext={"배송매장"}
                        righttext={<>{item.deliveryStore.store.name}</>}
                      />
                      <Row>
                        <Buttons
                          onClick={() => onSelect(item)}
                          color={isActive ? "primary" : "white"}
                          width={"full"}
                          size={"sm"}
                        >
                          선택
                        </Buttons>
                      </Row>
                    </li>
                  );
                }
              )}
            </ul>
          ) : (
            <Ready>등록된 픽업장소가 없습니다.</Ready>
          )}
        </PickupList>
      </Container>
    </Layout>
  );
}

export default Pickup;
