import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import { MainTitle, Ready } from "../Style/Styles"
import { ArrowRight2 } from "iconsax-react"
import theme from "../Style/Theme"
import { NumericFormat } from "react-number-format"
import { uri } from "../../Config/Apollo"

const BundleList = styled.div`
  width: auto;
  margin: 15px 0 40px;
  .swiper {
    padding-left: ${theme.paddings.xl};
    .swiper-slide {
      flex-shrink: 0;
      width: 200px !important;
    }
  }
`
const BundleItem = styled.div`
  a {
    display: flex;
    justify-content: center;
    .img {
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      padding-top: 130%;
      width: 200px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
      img {
        position: absolute;
        top: 0;
        max-width: 100%;
        min-width: 100%;
        display: block;
        min-height: 100%;
        max-height: 100%;
        z-index: 13;
      }
    }
    .cont {
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 15;
      padding: 10px 15px;
      background-color: rgba(0, 0, 0, 0.5);
      strong {
        display: block;
        font-size: ${theme.fonts.size.base};
        font-weight: ${theme.fonts.weight.normal};
        color: white;
        margin-bottom: ${theme.margins.sm};
      }
      .price {
        font-size: ${theme.fonts.size.base};
        color: white;
        font-weight: ${theme.fonts.weight.normal};
        b {
          font-weight: ${theme.fonts.weight.bold};
        }
      }
    }
  }
`

const BlurStyle = {
  backdropFilter: "blur(8px)",
}
function SetList({ DeliverySetList }) {
  const { deliverySets } = DeliverySetList
  return (
    <BundleList>
      <MainTitle>
        <h3>묶음상품</h3>
        <Link to="/sets" className="more">
          더보기
          <ArrowRight2
            size={14}
            color={theme.colors.black04}
            variant="Linear"
          />
        </Link>
      </MainTitle>
      {deliverySets.length > 0 ? (
        <Swiper spaceBetween={20}>
          {deliverySets.map((item, index) => {
            const coverImage =
              item.coverImage === "" || item.coverImage === null
                ? "/image/default.png"
                : `${uri}${item.coverImage}`
            return (
              <SwiperSlide key={index}>
                <BundleItem>
                  <Link to={`/sets/${item.id}`}>
                    <div className="img">
                      <div className="cont" style={BlurStyle}>
                        <strong>{item.name}</strong>
                        <span className="price">
                          <b>
                            <NumericFormat
                              displayType={"text"}
                              value={item.totalPrice}
                              thousandSeparator={true}
                            />
                          </b>{" "}
                          드림
                        </span>
                      </div>
                      <img src={coverImage} alt={item.name} />
                    </div>
                  </Link>
                </BundleItem>
              </SwiperSlide>
            )
          })}
        </Swiper>
      ) : (
        <Ready>등록된 묶음상품이 없습니다.</Ready>
      )}
    </BundleList>
  )
}

export default SetList
