import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_PLACE_LIST_QUERY = gql`
  query DeliveryAccountPlaceList($dayOfWeek: String, $skip: Int!, $take: Int) {
    DeliveryAccountPlaceList(dayOfWeek: $dayOfWeek, skip: $skip, take: $take) {
      deliveryPlaces {
        id
        createdAt
        updatedAt

        commonStatus
        name
        managerName
        managerPhone
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        deliveryStore {
          id
          createdAt
          updatedAt

          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      count
    }
    DeliveryAccountBoxDetail {
      id
      createdAt
      updatedAt

      state
      type
      deliveryPlace {
        id
        createdAt
        updatedAt

        commonStatus
        name
        managerName
        managerPhone
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        deliveryStore {
          id
          createdAt
          updatedAt
          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
        }
      }
      deliveryAddress {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        sido
        sigungu
        upmyundong
        li

        deliveryStore {
          id
          createdAt
          updatedAt
          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
        }
      }

      condition
      totalPrice
      deliveryDate
    }
  }
`;
