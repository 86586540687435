import styled, { css } from "styled-components"
import { NumericFormat } from "react-number-format"
import { uri } from "../../Config/Apollo"

export const Item = styled.div`
  ${({ theme }) => {
    return css`
      .img {
        position: relative;
        width: 100%;
        border-radius: 8px;
        padding-top: 100%;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          z-index: 11;
        }
        img {
          position: absolute;
          top: 0;
          max-width: 100%;
          min-width: 100%;
          display: block;
          min-height: 100%;
          max-height: 100%;
        }
      }
      .cont {
        margin-top: ${theme.margins.base};
        .category {
          color: ${theme.colors.black04};
          font-size: ${theme.fonts.size.sm};
          font-weight: 400;
        }
        h3 {
          margin-top: ${theme.margins.sm};
          height: 42px;
          overflow: hidden;
          font-size: ${theme.fonts.size.base};
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
        }
        .price {
          display: block;
          margin-top: ${theme.margins.sm};
          font-size: ${theme.fonts.size.base};
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.normal};
          b {
            font-weight: ${theme.fonts.weight.bold};
          }
        }
      }
    `
  }}
`

const ProductItem = ({ item }) => {
  const coverImage =
    item.coverImage === "" || item.coverImage === null
      ? "/image/default.png"
      : `${uri}${item.coverImage}`
  return (
    <Item>
      <div className="img">
        <img src={coverImage} alt={item.name} />
      </div>
      <div className="cont">
        {item.deliveryProductCategory?.name && (
          <span className="category">{item.deliveryProductCategory.name}</span>
        )}
        <h3>{item.name}</h3>
        <span className="price">
          <b>
            <NumericFormat
              displayType={"text"}
              value={item.price}
              thousandSeparator={true}
            />
          </b>{" "}
          드림
        </span>
      </div>
    </Item>
  )
}

export default ProductItem
