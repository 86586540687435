import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const TOKEN = "token";

export const isLoggedInVar = makeVar(Boolean(localStorage.getItem(TOKEN)));

export const logUserIn = (token, me) => {
  localStorage.setItem(TOKEN, token);
  isLoggedInVar(true);
};
export const logUserOut = () => {
  localStorage.removeItem(TOKEN);
  isLoggedInVar(false);
};

// export const uri = "http://3.39.203.58:4000";
export const uri = "https://api.hbpay.kr";

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const cache = new InMemoryCache({
  // typePolicies: {
  //   Query: {
  //     fields: {
  //       localSearchKeyword: {
  //         keyArgs: ["page", "query"],
  //         merge(existing, incoming, { args }) {
  //           const page = args.input.page * 15;
  //           const merged =
  //             existing && incoming.totalResults > 0
  //               ? existing.results.slice(0)
  //               : [];
  //           for (let i = 0; i < incoming.results.length; ++i) {
  //             merged[page + i] = incoming.results[i];
  //           }
  //           return {
  //             ok: incoming.ok,
  //             results: merged,
  //             totalPages: incoming.totalPages,
  //             totalResults: incoming.totalResults,
  //             error: incoming.error,
  //           };
  //         },
  //       },
  //     },
  //   },
  // },
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
