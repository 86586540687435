import styled from "styled-components";
import Header from "./Header";
import Nav from "./Nav";
import { Wrapper } from "../Style/Styles";
import Footer from "./Footer";

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FooterButton = styled.div`
  flex: 1 0 auto;
  height: 100px;
`;

function Layout({ isNav, isFooter, isFooterButton, isSearch, children }) {
  const isApp = localStorage.getItem("isApp");
  return (
    <Wrapper onContextMenu={(e) => e.preventDefault()}>
      {!isApp && (
        <Header title={"로컬푸드 구독"} onSearch={isSearch ? true : false} />
      )}
      {isNav && <Nav />}
      <Main>{children}</Main>
      {isFooter && <Footer />}
      {isFooterButton && <FooterButton />}
    </Wrapper>
  );
}

export default Layout;
