import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Menu from "./Menu";
import "../Style/style.css";
import theme from "../Style/Theme";
import StickyBox from "react-sticky-box";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../Config/Apollo";

import { useQuery } from "@apollo/client";
import { DELIVERY_ACCOUNT_USER_DETAIL_QUERY } from "../../Config/Queries/Account";

const Navbar = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  border-bottom: solid 1px #eee;
  white-space: nowrap;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      flex: 1;
      text-align: center;
      flex-shrink: 0;
      a {
        position: relative;
        display: inline-flex;
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black03};
        height: 40px;
        line-height: 40px;
        span {
          padding: 0 ${theme.paddings.sm};
        }
        &:hover,
        &.active {
          span {
            padding: 0 ${theme.paddings.sm};
            color: ${theme.colors.black01};
            border-bottom: solid 2px ${theme.colors.black01};
            font-weight: ${theme.fonts.weight.bold};
          }
        }
      }
    }
  }
`;
const RoundDot = styled.div`
  position: absolute;
  right: -7px;
  top: 2px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: ${theme.colors.danger};
  border-radius: 15px;
  text-align: center;
  font-size: ${theme.fonts.size.sm};
  color: white;
`;

const Item = ({ item }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <NavLink
      to={item.path[0]}
      className={({ isActive }) => (isActive ? "active" : "")}
    >
      <span>{item.title}</span>
      {item.title === "구독박스" && isLoggedIn && (
        <RoundDot>
          <BoxItemCounter item={item} />
        </RoundDot>
      )}
    </NavLink>
  );
};

const BoxItemCounter = ({ item }) => {
  const { data, loading, error } = useQuery(DELIVERY_ACCOUNT_USER_DETAIL_QUERY);

  if (loading) {
    return 0;
  }

  if (error) {
    console.log({ error });
    // return <>{error.message}</>;
  }

  const {
    DeliveryAccountUserDetail: { id, isDelivery, deliveryBoxProductCount },
  } = data;

  return isDelivery ? deliveryBoxProductCount : 0;
};

function Nav() {
  return (
    <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 99 }}>
      <Navbar>
        <ul>
          {Menu.map((item, index) => {
            return (
              <li key={index}>
                <Item item={item} />
              </li>
            );
          })}
        </ul>
      </Navbar>
    </StickyBox>
  );
}

export default Nav;
