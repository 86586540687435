import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_USER_DETAIL_QUERY = gql`
  query DeliveryAccountUserDetail {
    DeliveryAccountUserDetail {
      id
      createdAt
      updatedAt

      avatar
      userId
      name
      email
      phone
      birthDate
      zipcode
      roadAddress
      jibunAddress
      addressDetail
      latitude
      longitude

      role
      trustlines {
        id
        commonStatus
        currencyType
        currency
        amount
        createdAt
        updatedAt
      }
      wallet {
        id
      }
      store {
        id
        name
        storeCEO
        storeNumber
        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail
        storeCategory {
          id
          name
        }
      }
      visitedAt

      isCms
      cmsValue
      cmsDay

      addCurrency

      isDelivery

      deliveryBoxProductCount
    }
  }
`;
