import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../../../Components/Layout/Layout";
import {
  Container,
  FooterButton,
  TabsNav,
  Ready,
  Span,
  Row,
} from "../../../../Components/Style/Styles";
import RowInfo from "../../../../Components/Common/RowInfo";
import Links from "../../../../Components/Common/Links";
import Button from "../../../../Components/Common/Buttons";
import PageTitle from "../../../../Components/Layout/PageTitle";
import { dayOfWeekName } from "../../../../Config/Codes/Place";
import { SwalCustom } from "../../../../Components/Style/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWarning } from "@fortawesome/pro-solid-svg-icons";

import { useMutation } from "@apollo/client";
import {
  DELIVERY_ACCOUNT_BOX_WRITE_MUTATION,
  DELIVERY_ACCOUNT_BOX_EDIT_MUTATION,
} from "../../../../Config/Queries/Box";
import { DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY } from "../../../../Config/Queries/Address";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../../../../Components/Style/Theme";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

const MySwal = withReactContent(Swal);

export const PickupList = styled.div`
  li {
    margin-bottom: ${theme.margins.base};
    width: 100%;
    background-color: ${theme.colors.lightgray};
    border: solid 1px ${theme.colors.lightgray};
    display: block;
    padding: ${theme.paddings.lg};
    border-radius: 12px;
    .title {
      display: block;
      text-align: left;
      margin-bottom: ${theme.margins.sm};
      color: ${theme.colors.black01};
      font-size: ${theme.fonts.size.base};
    }
    &.active {
      background-color: white;
      border: solid 1px ${theme.colors.primary};
    }
  }
`;

function Delivery({ page, take, loading, data, baseUrl }) {
  console.log("Delivery", data);

  const navigate = useNavigate();

  const [DeliveryAccountBoxWriteMutation, { DeliveryAccountBoxWriteLoading }] =
    useMutation(DELIVERY_ACCOUNT_BOX_WRITE_MUTATION, {
      refetchQueries: [
        {
          query: DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
          variables: { skip: 0, take: 100 },
        },
      ],
    });
  const [DeliveryAccountBoxEditMutation, { DeliveryAccountBoxEditLoading }] =
    useMutation(DELIVERY_ACCOUNT_BOX_EDIT_MUTATION, {
      refetchQueries: [
        {
          query: DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY,
          variables: { skip: 0, take: 100 },
        },
      ],
    });

  const onSelect = async (item) => {
    try {
      if (!data.DeliveryAccountBoxDetail) {
        if (DeliveryAccountBoxWriteLoading) {
          return;
        }

        const {
          data: { DeliveryAccountBoxWrite },
        } = await DeliveryAccountBoxWriteMutation({
          variables: { type: "Delivery", deliveryAddress_id: item.id },
        });

        if (DeliveryAccountBoxWrite) {
          MySwal.fire({
            html: (
              <SwalCustom className="ss">
                <div className="icon primary">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="alert_title">
                  <b>배달정보</b>가
                  <br />
                  등록되었습니다.
                </div>
              </SwalCustom>
            ),
            customClass: {
              popup: "swal_popup",
              actions: "swal",
              confirmButton: "swal_btn lightprimary",
            },
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/main");
            }
          });
        }
      } else {
        if (DeliveryAccountBoxEditLoading) {
          return;
        }

        const {
          data: { DeliveryAccountBoxEdit },
        } = await DeliveryAccountBoxEditMutation({
          variables: { type: "Delivery", deliveryAddress_id: item.id },
        });

        if (DeliveryAccountBoxEdit) {
          MySwal.fire({
            html: (
              <SwalCustom>
                <div className="icon primary">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="alert_title">
                  <b>배달정보</b>가
                  <br />
                  설정되었습니다.
                </div>
              </SwalCustom>
            ),
            customClass: {
              popup: "swal_popup",
              actions: "swal",
              confirmButton: "swal_btn lightprimary",
            },
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/account/addresses");
            }
          });
        }
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  return (
    <Layout
      isNav={false}
      isFooterButton={true}
      isFooter={false}
      isSearch={false}
    >
      <PageTitle title={`배달`} />
      <TabsNav>
        <ul>
          <li className="">
            <Link to="/account/places" replace={true}>
              <span>픽업</span>
            </Link>
          </li>
          <li className="active">
            <Link to="/account/addresses" replace={true}>
              <span>배달</span>
            </Link>
          </li>
        </ul>
      </TabsNav>
      <Container>
        {!loading && data.DeliveryAccountAddressList.count > 0 ? (
          <PickupList>
            <ul>
              {data.DeliveryAccountAddressList.deliveryAddresses.map(
                (item, index) => {
                  const isActive =
                    data.DeliveryAccountBoxDetail?.type === "Delivery" &&
                    data.DeliveryAccountBoxDetail?.deliveryAddress.id ===
                      item.id;

                  return (
                    <li key={index} className={isActive ? "active" : ""}>
                      <strong className="title">{item.name}</strong>
                      <RowInfo
                        lefttext={"주소"}
                        righttext={`${item.roadAddress}<br />${item.addressDetail}`}
                      />
                      <RowInfo
                        lefttext={"픽업요일"}
                        righttext={dayOfWeekName(item.dayOfWeek)}
                      />
                      <RowInfo
                        lefttext={"배송매장"}
                        righttext={<>{item.deliveryStore.store.name}</>}
                      />
                      <Row>
                        <Button
                          onClick={() => onSelect(item)}
                          color={isActive ? "primary" : "white"}
                          width={"full"}
                          size={"sm"}
                        >
                          선택
                        </Button>
                        <Links
                          to={`/account/addresses/${item.id}/edit`}
                          color={"white"}
                          width={"full"}
                          size={"sm"}
                        >
                          수정
                        </Links>
                      </Row>
                    </li>
                  );
                }
              )}
            </ul>
          </PickupList>
        ) : (
          <Ready>등록된 배달장소가 없습니다.</Ready>
        )}
      </Container>
      <FooterButton>
        <div className="inner">
          {!loading && data.DeliveryAccountBoxDetail ? (
            <Links
              to={`/account/addresses/write`}
              color="primary"
              width="full"
              border="radius"
              disabled
            >
              주소추가
            </Links>
          ) : (
            <Links
              to={`/account/addresses/register`}
              color="primary"
              width="full"
              border="radius"
              disabled
            >
              주소등록
            </Links>
          )}
        </div>
      </FooterButton>
    </Layout>
  );
}

export default Delivery;
