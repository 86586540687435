import React from "react"
import styled from "styled-components"
import theme from "../Style/Theme"
import { BottomModal } from "../Layout/Modal"
import { ModalContainer, SwalCustom } from "../Style/Styles"
import { LocationSlash } from "iconsax-react"
import axios from "axios"
import DaumPostcode from "react-daum-postcode"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
const MySwal = withReactContent(Swal)

function SearchModel({ isOpen, toggleModal, deliveryStoreDongs, setValue }) {
  const onSelect = async (data) => {
    const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&page=1&size=15`
    const API_KEY = "7da7d093496c67033d3e14aa3102e838"

    const res = await axios({
      url,
      headers: { Authorization: `KakaoAK ${API_KEY}` },
    })
    const json = res.data

    console.log({ deliveryStoreDongs })

    const deliveryStoreDong = deliveryStoreDongs.find(
      (item) =>
        item.upmyundong === json.documents[0].road_address.region_3depth_name
    )

    if (!deliveryStoreDong) {
      MySwal.fire({
        confirmButtonText: "확인",
        html: (
          <>
            <SwalCustom>
              <div className="icon">
                <LocationSlash variant="Bulk" size={32} />
              </div>

              <div className="alert_title">
                <b>해당주소</b>는<br />
                <b>배송지역</b>이 아닙니다.
              </div>
            </SwalCustom>
          </>
        ),
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn primary",
          cancelButton: "swal_btn danger",
        },
        allowOutsideClick: false,
      })
      toggleModal()
      return
    }

    // console.log({ data });
    // console.log({ json });

    setValue("zipcode", data.zonecode)
    setValue("roadAddress", data.roadAddress)
    setValue("jibunAddress", data.jibunAddress)

    setValue("longitude", json.documents[0].x)
    setValue("latitude", json.documents[0].y)

    setValue("sido", json.documents[0].road_address.region_1depth_name)
    setValue("sigungu", json.documents[0].road_address.region_2depth_name)
    setValue("upmyundong", json.documents[0].road_address.region_3depth_name)

    toggleModal()
  }

  return (
    <BottomModal
      isOpen={isOpen}
      toggleModal={() => {
        toggleModal()
      }}
      title={"주소검색"}
    >
      <ModalContainer>
        <DaumPostcode height={700} onComplete={onSelect} />
      </ModalContainer>
    </BottomModal>
  )
}

export default SearchModel
