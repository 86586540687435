import React from "react";
import { useParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_MAIN_QUERY } from "../../Config/Queries/Main";

const Container = () => {
  const { loading, error, data } = useQuery(DELIVERY_MAIN_QUERY, {
    fetchPolicy: "network-only",
  });

  //   if (loading) {
  //     return <></>;
  //   }
  if (error) {
    return <>{error.message}</>;
  }

  return <Presenter loading={loading} data={data} />;
};

export default Container;
