import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_STORE_DONG_LIST_QUERY = gql`
  query DeliveryAccountStoreDongList($skip: Int!, $take: Int) {
    DeliveryAccountStoreDongList(skip: $skip, take: $take) {
      deliveryStoreDongs {
        id
        createdAt
        updatedAt

        commonStatus
        sido
        sigungu
        upmyundong
        ri

        deliveryStore {
          id
          createdAt
          updatedAt

          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      count
    }
  }
`;
