import styled from "styled-components";
import RowInfo from "../Common/RowInfo";
import theme from "../Style/Theme";
import ReactMoment from "react-moment";
import { stateName, typeName, dayOfWeekName } from "../../Config/Codes/Order";

const ProductItem = styled.div`
  .label {
    color: ${theme.colors.black04};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    &.primary {
      color: ${theme.colors.primary};
    }
    &.danger {
      color: ${theme.colors.primary};
    }
  }
`;

function Item({ item }) {
  const deliverAtDayOfWeek = new Date(item.deliverAt).getDay().toString();
  return (
    <ProductItem>
      <RowInfo
        border={"bottom"}
        lefttext={
          <span className="label primary">{stateName(item.state)}</span>
        }
      />
      <RowInfo
        border={"bottom"}
        lefttext={"배송일"}
        righttext={
          <>
            <ReactMoment format="YYYY.MM.DD">{item.deliverAt}</ReactMoment>(
            {dayOfWeekName(deliverAtDayOfWeek)})
          </>
        }
      />
      <RowInfo
        border={"bottom"}
        lefttext={"타입"}
        righttext={typeName(item.type)}
      />
      <RowInfo
        border={"bottom"}
        lefttext={"주소"}
        righttext={`${item.roadAddress}<br />${item.addressDetail}`}
      />
      <RowInfo
        border={"bottom"}
        lefttext={"배송매장"}
        righttext={`${item.deliveryStore.store.name}`}
      />
      <RowInfo
        border={"bottom"}
        lefttext={"연락처"}
        righttext={item.deliveryStore.user.phone}
      />
    </ProductItem>
  );
}

export default Item;
