export const Policy = {
  Provision: {
    title: "이용약관",
    content: (
      <div>
        제 1장 총칙
        <br />
        <br />
        <b>제1조(목적)</b> <br />
        <br />
        약관은 지역화폐협동조합(이하 "회사"라 한다)이 제공하는 드림상점 서비스
        이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한
        사항을 규정함을 목적으로 합니다.
        <br />
        <br />
        <br />
        <b>제2조(정의)</b>
        <br />
        <br />
        ①“드림상점”(이하 "서비스"라 한다)라 함은 회사가 판매채널을 통해서
        제공하는 전자상거래 서비스를 말합니다.
        <br />
        ②“판매채널”이라 함은 회사가 상품 또는 용역(이하 "상품 등"이라 한다)을
        회원에게 제공하기 위하여 마련한 회사의 모바일 어플리케이션 또는 모바일
        웹 및 인터넷 사이트 등을 말합니다.
        <br />
        ③“회원”이라 함은 이 약관에 따라 회사와 이용계약을 체결하고 회사가
        제공하는 서비스를 이용하는 자를 말합니다.
        <br />
        ④“판매자”라 함은 회사가 제공하는 중개판매 서비스를 통하여 상품 등을
        판매할 목적으로 판매자 약관을 승인 하거나 회사와 서비스 이용계약을
        체결한 자를 말합니다.
        <br />
        ⑤“모바일 교환권”이라 함은 상품 등이 화체된 Mobile용 쿠폰으로서, 모바일
        교환권 내에 명시된 교환 조건에 따라 해당 상품 등으로 교환할 수 있는
        쿠폰을 말합니다.
        <br />
        가)“물품교환형”은 특정 상품 등과 교환할 수 있는 권리가 기록된 모바일
        교환권을 말합니다.
        <br />
        나)“티켓예매형”은 공연, 체험, 영화표를 예매하고 교환할 수 있는 모바일
        예매권을 말합니다. <br />
        다)“금액형”은 비정액 또는 정액형 선불전자지급수단으로 유효기간 내에 잔액
        범위 내에서 사 용 횟수에 제한 없이 자유롭게 상품 등으로 교환할 수 있는
        권리가 기록된 모바일 교환권을 말합니다.
        <br />
        ⑥“수신자”라 함은 모바일 교환권을 상품 등으로 교환할 수 있는 권리를
        가집니다.
        <br />
        ⑦“드림”이라 함은 회원이 서비스를 이용하는 과정에서 현금을 드림으로
        교환하여 상품 구매 또는 각종 이벤트 등에 참여하거나 다른 회원에서
        송금하거나 송금받는 등을 할 수 있는 사이버머니의 일종으로 드림상점에서
        사용하는 결제수단이라 합니다.
        <br />
        ⑧“환불드림”라 함은 모바일 교환권의 유효기간 만료 이후, 회원이 구매를
        취소하였으나 당해 결제 수단에서 제공하는 결제 취소 기간이 경과하여
        회원이나 수신자가 받은 모바일 교환권의 10% 수수료를 공제한 드림을 자동
        적립하는 것을 말합니다.
        <br />
        ⑨ 이 약관에 사용되는 용어 중 본 조에서 정하지 않은 부분은 관계 법령 및
        일반 관례에 따릅니다.
        <br />
        <br />
        <br />
        <b>제3조 (약관 등의 명시와 설명 및 개정)</b>
        <br />
        <br />
        ① 회사는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소,
        전화번호, 사업자등록번호, 통신판매업 신고번호(대상자에 한하여), 고객센터
        등을 회원이 쉽게 알 수 있도록 판매채널 내 서비스 화면에 게시합니다.
        다만, 이 약관의 구체적 내용은 이용자가 연결화면을 통하여 볼 수 있습니다.
        <br />
        ② 드림상점 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에
        관한 법률, 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률,
        소비자보호법 등 관계법령을 위배하지 않는 범위에서 이 약관을 개정할 수
        있습니다.
        <br />
        ③ 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께
        초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만,
        이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전
        유예기간을 두고 공지합니다. 이 경우 개정 전 내용과 개정 후 내용을
        명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
        <br />
        ④ 이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는
        계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의
        약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 회원이 개정약관
        조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에
        드림상점에 송신하여 개정약관 조항을 적용할 수 있습니다.
        <br />
        ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래
        등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
        공정거래위원회가 정하는 전자상거래 등에서 소비자 보호지침 및 관계법령
        또는 상관례에 따를 수 있습니다.
        <br />
        <br />
        <br />
        <b>제4조(서비스의 제공 및 변경) </b>
        <br />
        <br />
        ① 드림상점 은 다음과 같은 업무를 수행합니다.
        <br />
        가) 모바일 교환권 서비스
        <br />
        나) 티켓 예매서비스
        <br />
        다) 기타 회사가 정하는 서비스 또는 업무 등<br />
        <br />
        ② 회사는 상품 등의 품절 또는 기술적 사양의 변경 등이 있는 경우에는 상품
        등을 변경 할 수 있습니다.
        <br />
        ③ 회사는 필요한 경우 상품 등의 변경 사실 및 그 사유를 회원 또는
        수신자에게 전자우편, 전자 쪽지, SMS등으로 통지 할 수 있습니다.
        <br />
        ④ 전항의 경우 회사는 이로 인하여 회원 또는 수신자가 입은 인과관계가
        입증된 실제 손해를 배상합니다. 다만, 회사가 고의 또는 과실이 없음을
        입증하는 경우에는 그러하지 아니합니다.
        <br />
        <br />
        <br />
        <b>제5조(서비스의 중단)</b>
        <br />
        <br />
        ① 드림상점은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절
        등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수
        있습니다.
        <br />
        ② 제1항의 사유로 서비스의 제공이 일시적으로 중단됨에 따른 회원 또는
        제3자가 입은 손해에 대하여 배상합니다. 단, 드림상점이 고의 또는 과실이
        없음을 입증하는 경우에는 그러하지 아니합니다.
        <br />
        ③ 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를
        제공할 수 없게 되는 경우 회사는 제9조에 정한 방법으로 회원에게 통지하고
        회사에서 제시한 조건에 따라 회원에게 보상 할 수 있습니다.
        <br />
        <br />
        <br />
        <b>제6조(회원가입)</b>
        <br />
        <br />
        ① 회원은 한밭페이에 가입 되어 있는 경우 별도의 가입신청 없이 드림상점의
        가입자가 됩니다.
        <br />
        ② 회원으로 가입된 회원 중 다음 각 호에 해당하지 않는 한 드림상점의
        이용자로 자동 등록합니다.
        <br />
        가) 회원이 이전에 회원자격을 상실한 적이 있는 경우, 다만 회원자격 상실
        후 3년이 경 과한 자로서 드림상점의 회원재가입 승낙을 얻은 경우에는
        예외로 한다.
        <br />
        나) 등록 내용에 허위, 기재누락, 오기가 있는 경우
        <br />
        다) 기타 회원으로 등록하는 것이 드림상점의 기술상 현저히 지장이 있다고
        판단되는 경우
        <br />
        ③ 회원은 드림상점 가입 시 제공한 정보에 변경이 있는 경우, 앱을 통한
        개인정보 변동 수정 또는 한밭페이를 통하거나 드림상점에 상당한 기간
        이내에 그 변경 사항을 알려야 합니다.
        <br />
        <br />
        <br />
        <b>제7조(회원 탈퇴 및 자격 상실 등)</b>
        <br />
        <br />
        ① 회원은 드림상점에 언제든지 탈퇴를 요청할 수 있으며 드림상점은 즉시
        회원탈퇴를 처리합니다.
        <br />
        ② 회원이 다음 각 호의 사유에 해당하는 경우, 회원자격을 제한 및 정지시킬
        수 있습니다.
        <br />
        가) 가입 신청 시에 허위 내용을 등록한 경우
        <br />
        나) 드림상점을 이용하여 구입한 재화 등의 대금, 기타 이용에 관련하여
        회원이 부담하는 채 무를 기일에 지급하지 않는 경우
        <br />
        다) 다른 사람의 드림상점 이용을 방해하거나 그 정보를 도용하는 등
        전자상거래 질서를 위 협하는 경우
        <br />
        라) 드림상점을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는
        행위를 하는 경우
        <br />
        ③ 드림상점이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상
        반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회원자격을
        상실시킬 수 있습니다.
        <br />
        ④ 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게
        이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여
        소명할 기회를 부여합니다.
        <br />
        <br />
        <br />
        <b>제8조(회원에 대한 통지)</b>
        <br />
        <br />
        ① 드림상점 회원에 대한 통지를 하는 경우, 회원이 미리 약정하여 지정한
        전자우편, 전자쪽지, SMS등으로 통지 할 수 있습니다.
        <br />
        ② 불특정다수 회원에 대한 통지의 경우 1주일이상 드림상점 게시판에
        게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와
        관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
        <br />
        <br />
        <br />
        <b>제9조 (회사의 의무)</b>
        <br />
        <br />
        ① 드림상점은 법령과 이 약관이 금지하거나 반하는 행위를 하지 않으며 이
        약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데
        최선을 다하여야 합니다.
        <br />
        ② 회원이 안전하게 인터넷 서비스를 이용할 수 있도록 회원의
        개인정보(신용정보 포함)보호를 위한 노력을 해야합니다.
        <br />
        ③ 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의
        부당한 표시․광고행위를 함으로써 회원이 손해를 입은 때에는 이를 배상할
        책임을 집니다.
        <br />
        <br />
        ④ 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
        <br />
        ⑤ 사업의 폐업 등의 상황에 따라 소비자의 구매상품의 잔여기한이 남아있는
        경우 100% 드림으로 환불 합니다. (단, 명시된 별도의 특별조항이나 안내 등
        달리 정함이 있는 경우 그 정한 바를 먼저 따릅니다.)
        <br />
        <br />
        <br />
        <b>제10조(회원의 의무)</b>
        <br />
        <br />
        ① 신청 또는 변경 시 허위 내용의 등록을 해서는 안됩니다.
        <br />
        ② 타인의 정보 도용을 해서는 안됩니다.
        <br />
        ③ 드림상점에 게시된 정보의 변경을 해서는 안됩니다.
        <br />
        ④ 드림상점이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는
        게시 등을 해서는 안됩니다.
        <br />
        ⑤ 기타 제3자의 저작권 등 지적재산권에 대한 침해해서는 안됩니다.
        <br />
        ⑥ 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위를 해서는
        안됩니다.
        <br />
        ⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
        공개 또는 게시하는 행위 등 불법행위를 해서는 안됩니다.
        <br />
        <br />
        <br />
        <b>제11조(저작권의 귀속 및 이용제한)</b>
        <br />
        <br />
        ① 드림상점 작성한 저작물에 대한 저작권 기타 지적재산권은 드림상점에
        귀속합니다.
        <br />
        ② 회원은 드림상점을 이용함으로써 얻은 정보 중 드림상점에게 지적재산권이
        귀속된 정보를 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에
        의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        <br />
        ③ 드림상점은 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우 당해
        회원에게 통보하여야 합니다.
        <br />
        <br />
        <br />
        <b>제12조 (계약의 성립)</b>
        <br />
        <br />
        ① 드림상점은 구매신청에 대하여 결제완료 후 바로 계약이 이루어집니다. 단
        다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 또한 미성년자와 계약을
        체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
        법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
        <br />
        가) 신청 내용에 허위, 기재누락, 오기가 있는 경우
        <br />
        나) 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을
        구매하는 경우
        <br />
        ② 드림상점의 승낙이 제12조제1항의 수신확인통지형태로 회원에게 도달한
        시점에 계약이 성립한 것으로 봅니다.
        <br />
        ③ 드림상점의 승낙의 의사표시에는 회원의 구매 신청에 대한 확인 및
        판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야
        합니다.
        <br />
        <br />
        <br />
        <b>제13조 (구매신청 및 개인정보 제공 동의 등)</b>
        <br />
        <br />
        ① 드림상점 회원은 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며,
        회원이 구매신청을 함에 있어 다음 각 내용을 알기 쉽게 제공하여야 합니다.
        <br />
        가) 재화 등의 검색 및 선택
        <br />
        나) 받는 사람의 성명, 전화번호(이동전화번호) 등의 입력
        <br />
        다) 약관내용, 청약철회권이 제한되는 서비스 내용에 대한 확인
        <br />
        라) 재화 등의 구매신청 및 이에 관한 확인에 대한 동의
        <br />
        ② 드림상점은 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우
        개인정보를 제공받는 자의 개인정보 이용목적, 제공하는 개인정보의 항목,
        개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 회원에게 알리고
        동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
        <br />
        ③ 제3자에게 이용자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우
        개인정보 취급위탁을 하는 업무의 내용을 회원에게 알리고 동의를 받아야
        합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만,
        서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된
        경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고
        있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를
        거치지 않아도 됩니다.
        <br />
        <br />
        <br />
        <b>제14조 (개인정보보호)</b>
        <br />
        ① 드림상점은 회원의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서
        최소한의 개인정보를 수집하고 회원의 개인정보를 수집·이용하는 때에는 당해
        회원에게 그 목적을 고지 동의를 받습니다.
        <br />
        ② 드림상점은 회원가입 시 구매계약이행에 필요한 정보를 미리 수집하지
        않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이
        필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지
        아니합니다.
        <br />
        ③ 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 제3자에 대한
        정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등
        「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한
        사항을 미리 명시하거나 고지해야 하며 회원은 언제든지 이 동의를 철회할 수
        있습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
        <br />
        ④ 회원은 언제든지 드림상점에 가지고 있는 자신의 개인정보에 대해 열람 및
        오류정정을 요구할 수 있으며 드림상점은 이에 대해 지체 없이 필요한 조치를
        취할 의무를 집니다. 회원이 오류의 정정을 요구한 경우에는 그 오류를
        정정할 때까지 당해 개인정보를 이용하지 않습니다.
        <br />
        ⑤ 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로
        제한하여야 하며 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자
        제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
        <br />
        ⑥ 드림상점 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적
        또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
        <br />
        ⑦ 1년 이상 사용이 없을시 개인정보의 수집·이용·제공에 관해 개인정보를
        파기한다.
        <br />
        <br />
        <br />
        <b>제15조(이용자의 ID 및 비밀번호에 대한 의무)</b>
        <br />
        <br />
        ① 제17조의 경우를 공제한 ID와 비밀번호에 관한 관리책임은 회원에게
        있습니다.
        <br />
        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
        <br />
        ③ 회원은 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을
        인지한 경우에는 바로 드림상점에 통보하고 안내가 있는 경우에는 그에
        따라야 합니다.
        <br />
        <br />
        <br />
        <b>제16조(지불방법)</b>
        <br />
        <br />
        드림상점에서 구매한 재화 또는 용역에 대한 지불방법은 다음 각 호의 방법
        중 가용한 방법으로 할 수 있습니다.
        <br />
        가) 드림 결제
        <br />
        나) 드림상점과 계약을 맺었거나, 인정한 상품권에 의한 결제
        <br />
        다) 기타 전자적 지급 방법에 의한 드림상점이 인정하는 지불방법 등
        <br />
        <br />
        <br />
        <b>제17조(수신확인통지․구매신청 변경 및 취소)</b>
        <br />
        <br />
        ① 드림상점은 회원의 구매신청이 있는 경우 회원에게 수신확인통지를 합니다.
        <br />
        ② 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는
        수신확인통지를 받은 후 사용 전, 즉시 구매신청 변경 및 취소를 요청할 수
        있고 회원의 요청에 지체 없이 처리하여야 합니다. 다만 이미 지불금이 있는
        경우에는 제20조의 청약철회 등에 관한 규정에 따릅니다.
        <br />
        <br />
        <br />
        <b>제18조(재화 등의 공급)</b>
        <br />
        <br />
        드림상점은 회원의 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상,
        회원이 청약을 한 날로부터 즉시 또는 최대 1일 이내에 재화 등 기타의
        필요한 조치를 취합니다.
        <br />
        <br />
        <br />
        <b>제19조(환급) </b>
        <br />
        <br />
        드림상점은 회원이 구매 신청한 재화 등이 품절 등의 사유로 인도 또는
        제공을 할 수 없을 때에는 지체 없이 그 사유를 회원에게 통지하고 사전에
        재화 등의 지불을 받은 경우에는 지불금을 받은 날부터 3영업일 이내에 100%
        환급하거나 환급에 필요한 조치를 취합니다. (단 명시된 별도의 특별조항이나
        안내 등 달리 정함이 있는 경우 그 정한 바를 먼저 따릅니다.)
        <br />
        <br />
        <br />
        <b>제20조(청약철회 및 취소 수수료 등)</b>
        <br />
        <br /> ① 드림상점 재화 등의 구매에 관한 계약을 체결한 회원은「전자상거래
        등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한
        서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진
        경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터
        7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여
        「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는
        경우에는 동 법 규정에 따릅니다.
        <br />
        ② 최초유효기간(90일)내의 청약철회 요청 시 구매자에게 100% 드림으로
        환불됩니다.
        <br />
        ③ 회원은 재화 등을 제공 받은 경우 다음 각 호의 1에 해당하는 경우에는
        취소 및 교환을 할 수 없습니다.
        <br />
        가) 회원은 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한
        경우
        <br />
        나) 재화에 대한 사용후 청약철회를 요청하는 경우, 고의적인 청약철회를
        요청하는 경우
        <br />
        다) 시간의 경과에 의하여 재판매가 불가능한 경우
        <br />
        <br />
        <br />
        <b>제21조(청약철회 등의 효과)</b>
        <br />
        <br />
        ① 드림상점은 회원으로부터 재화 등을 반환받은 경우 3영업일 이내에 이미
        지급받은 재화 등의 대금을 환급합니다. 이 경우 특별한 사유없이 이용자에게
        재화 등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의
        소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여
        산정한 지연이자를 드림으로 지급합니다.
        <br />
        ② 위 대금을 환급함에 있어 회원이 재화를 반환 신청한 후 구매했던 재화를
        다시 재사용할 수 없습니다.
        <br />
        <br />
        <br />
        <b>제22조(소비자 피해보상 및 분쟁해결)</b>
        <br />
        <br />
        ① 드림상점은 회원이 제기하는 정당한 의견이나 불만을 반영하고 우선적으로
        처리하며, 신속한 처리가 곤란한 경우 그 사유와 처리일정을 통보한다.
        피해가 있을시 피해 금액을 확인하고 그에 맞는 보상과 합의로 해결을 위해
        노력해야 합니다.
        <br />
        ② 회원 간에 발생한 전자상거래 분쟁과 관련하여 회원의 피해구제신청이 있는
        경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에
        따를 수 있습니다.
        <br />
        <br />
        제2장 모바일 교환권 및 티켓 예매권 <br />
        드림상점에서 발행되는 모바일 교환권이나 티켓 예매권에 대한 총칭을 모바일
        상품권 이라 칭한다.
        <br />
        <br />
        <br />
        <b>제23조(모바일 상품권 등의 사용)</b>
        <br />
        <br />
        ① 회원이 소지 그 자체만으로 서비스를 이용할 수 있는 전자적 형태의
        온라인·모바일 상품권 등을 제휴업체로부터 발행 받아 회사의 판매채널에서
        판매하며, 모바일 상품권을 구매한 회원으로부터 이전받은 자(이하 총칭하여
        모바일 상품권을 구매한 회원이라 함)는 모바일 상품권에서 지정하는 바에
        따라 제휴업체 또는 제휴업체가 지정한 다른 업체에서 이를 사용할 수
        있습니다.
        <br />
        ② 모바일 상품권을 구매한 회원은 회사 홈페이지에 명시된 내용 및 모바일
        상품권에 기재된 주의사항을 확인하여야 할 책임이 있으며, 명시된
        이용조건에 따라서 모바일 상품권을 사용할 수 있습니다.
        <br />
        ③ 모바일 상품권에 대해서는 본 약관보다 다음 각 호의 내용을 우선 합니다.
        <br />
        가) 모바일 상품권을 구매한 회원은 모바일 상품권을 사용할 수 있는
        유효기간이 공정거래위 원회의 ' 신유형 상품권 표준약관(표준약관
        제10073호) 제5조' 및 개정될 경우 그 개정약관 에 따르며, 모바일 상품권을
        구매한 회원은 제휴업체에서 정한 유효기간에 따라 연2회(90 일) 이상 연장을
        요청할 수 있습니다.
        <br />
        나) 모바일 상품권을 구매한 회원은 공정거래위원회의 '신유형 상품권
        표준약관(표준약관 제 10073호) 제7조' 및 개정될 경우 그 개정약관에 따라
        모바일 상품권을 환불 받거나 일부 잔액을 반환받을 수 있습니다.
        <br />
        다) 모바일 상품권의 표시사항, 지급보증 또는 피해보상보험계약 등 그 밖의
        내용은 공정거래위 원회의 '신유형 상품권 표준약관(표준약관 제10073호)' 및
        개정될 경우 그 개정약관의 각 해 당 내용에 따르며, 회원은 위 표준약관의
        내용을 본 약관의 일부로 주장할 수 있습니다.
        <br />
        <br />
        <br />
        <b>제24조(모바일상품권 전송)</b>
        <br />
        <br />
        ① 드림상점은 회원이 구매한 모바일상품권의 상세 내용을 앱 PUSH로
        발송합니다.
        <br />
        ② 모바일상품권 전송작업은 공휴일과 드림상점 쉬는 날 및 처리 과정의
        상황에 따라 변경될 수 있으며, 이는 판매채널 내 해당 콘텐츠에 명시된
        내용을 따릅니다.
        <br />
        <br />
        <br />
        <b>제25조(모바일상품권의 사용제한)</b>
        <br />
        <br />
        회사는 다음 각 호에 해당하는 경우 모바일상품권의 전부 또는 일부에 대하여
        교환을 제한하거나 사용을 중지할 수 있습니다.
        <br /> 가) 회사가 제공하는 방식이 아닌 불법 또는 부정한 방법으로 모바일
        상품권을 구매하거나 사용 하고자 경우
        <br />
        나) 타인의 명의를 도용한 결제 수단을 통하여 송신된 모바일 상품권을
        교환하고자 하는 경우
        <br />
        다) 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
        <br />
        <br />
        <br />
        <b>제26조(모바일상품권의 구매 취소 및 환불 수수료)</b>
        <br />
        <br />① 모바일상품권의 수신자가 해당 모바일상품권을 상품 등으로 교환하기
        전이고, 최초 유효기간 내인 경우, 구매한 회원은 해당 구매 주문을 취소할
        수 있습니다. 또한 결제 취소 기간이 경과한 경우, 수신자에게 환불되는 경우
        모두 드림으로 환불됩니다. (상품권의 종류에 따라 수수료의 차이가 발생될
        수 있습니다.)
        <br />
        가) 회원이 구매 후 최초유효기간(90일) 내에 환불을 신청하는 경우 100%
        드림으로 구매자에게 환불됩니다.(수신자는 최초유효기간 내에 환불을 신청할
        권한이 없습니다.)
        <br />
        나) 모바일교환권의 최초유효기간 이후 수신자가 환불을 신청하는 경우
        수수료 10% 공제 후 드 림으로 수신자에게 환불합니다. (구매자는
        최초유효기간 이후에 환불을 신청할 권한이 없 습니다.)
        <br />
        다) 수신자의 미사용으로 인한 유효기간 이후의 환불은 수수료 10% 공제 후
        드림으로 수신자에게 환불 합니다. (단 수신자가 한밭페이 가입자가 아닐
        경우 한밭페이 가입을 안내 후 드림으로 환불하며, 이에 경과되는 기간의
        경우는 약관의 기간정함의 예외로 합니다.)
        <br />
        라) 티켓 예매권에 대해서는 별도의 상황에 따라 환불수수료를 적용하여
        드림으로 환불 합니다.
        <br />
        ② 드림상점은 환불권자에 대한 본인확인 절차 후 드림으로 환불을 진행하는
        것을 원칙으로 합니다.
        <br />
        <br />
        <br />
        <b>제27조(모바일 티켓예매권 환불 특칙)</b>
        <br />
        <br />
        ① 공연, 체험, 전시 및 영화 예약 및 그에 따른 서비스는 준비 등의 특수한
        사정이 있는 관계로 별도의 특별약관이 우선하여 적용되며 개별판매 조건에
        명시된 유효기간 및 조건에 한하여 서비스가 제공, 별도의 취소/환불 규정을
        따릅니다. 회원은 판매채널에 명시된 내용 및 개별 주의사항(사용정보 등)을
        확인하여야 할 책임이 있습니다.
        <br />
        ② 드림상점은 판매채널에서 상시 판매하는 티켓 예매권을 구매하는 경우
        회원이 예약과 동시에 결제가 이루어집니다. 만일, 티켓예약 상품의 매진이
        된 경우 결제가 취소되므로 회원 에게 별도의 요금이 청구되지 않습니다.
        다만, 진행 업체의 사정으로 인하여 티켓예매권의 예약 상품의 결제를
        취소하는 경우에는 판매채널에 명시된 별도의 특별약관에서 정한 바에
        따릅니다.
        <br />
        ③ 회원은 공연, 전시, 체험 입장권을 타인에게 양도하거나 담보로 제공할 수
        없으며(단, 드림상점이 승인한 경우 예외로 함), 필요 시 본인확인절차를
        통해 구매 회원과 사용자의 일치 여부 등을 확인 할 수 있습니다. 또한, 구매
        회원이 이를 위반하여 드림상점 제휴업체 또는 제휴업체가 지정한 장소에서
        서비스를 제공받지 못하는 등의 손해에 대하여 회원이 이의를 제기할 수
        없습니다.
        <br />
        ④ 회원은 공연 및 전시, 체험 입장권의 구매처리가 완료되었음을 안내하는 등
        특정 서비스 이용권의 매매를 증명하기 위하여 발송된 이메일, 문자메시지
        등을 소지하는 것만으로 본인 확인절차를 갈음할 수 없습니다.
        <br />
        ⑤ 드림상점은 판매 및 판매된 티켓의 취소가능기한을 개별적인 상품 또는
        용역에 따라 달리 정할 수 있습니다. 즉, 판매 및 판매된 티켓의
        취소가능기한은 제휴업체 및 전시장, 공연장 사정에 의해 변경될 수 있으며,
        회원은 개별판매 조건에 명시된 티켓의 취소가능기한까지 취소 절차를 취하지
        않은 경우에는 드림상점에서 취소나 환불을 할 수 없습니다.
        <br />
        <br />
        <br />
        <b>제28조(티켓의 구매취소 및 수수료, 청약철회 등)</b>
        <br />
        <br />
        ① 드림상점은 구매취소 및 청약철회 규정은 전자상거래 등에서의
        소비자보호에 관한 법률 등 관련 법령을 준수합니다.
        <br />
        ② 티켓을 구매한 회원이 수신확인 통지를 받은 날(전자우편 또는 SMS 통지
        등을 받은 날) 또는 상품 등(PIN 포함)을 공급받은 날로부터 취소에 따른
        수수료를 아래와 같이 드림으로 환불합니다. (개별 티켓 취소에 대한 조건이
        명시되어 있지 않은 경우 아래 조건을 따른다.)
        <br />
        가) 티켓예매 통지를 받은 날로부터 7일 이내에 티켓구매를 취소할 수 있고
        취소수수료 없이 100% 드림으로 환불 합니다.
        <br />
        나) 티켓예매의 사용일(공연일) 6일전부터 3일전까지 취소시 수수료 20% 공제
        후 환불합니다.
        <br />
        다) 티켓예매의 사용일(공연일) 2일전부터 취소마감일 까지 취소시 수수료는
        30% 또는 환불 신청을 할 수 없을 수 있습니다. (업체의 상황에 따라 취소
        수수료를 고지하고 이에 따릅 니다.)
        <br /> 라) 타켓예매 후 사용일 당일 취소 가능한 상품의 경우 당일 취소시에
        수수료 90%가 부과 되 거나 환불금액이 없을 수 있습니다.
        <br />③ 드림상점은 제28조 2항의 가) 조건보다 선행되는 규정으로 티켓예매
        통지를 받은 날보다 티켓의 사용일(공연일)을 기준으로 취소수수료 나), 다),
        라)의 규정을 먼저 따릅니다.
        <br />④ 회원의 단순 변심에 의한 7일 이후 취소 및 환불일 경우 이의 처리에
        발생하는 수수료 3%공제 후 드림으로 환불합니다.
        <br />
        ⑤ 회원은 상품 등의 내용이 표시∙광고 내용과 다르거나 계약 내용과 다르게
        이행된 때에는 그 상품 등을 공급받은 날부터 3일이내, 그 사실을 안 날 또는
        알 수 있었던 날부터 30일 이내에 구매를 취소 할 수 있습니다.
        <br />
        ⑥ 개별 상품 등의 성격에 따라 드림상점은 별도 계약 및 이용조건에 따른
        구매취소 관련 규정을 정할 수 있으며 이 경우 별도 계약 및 이용조건상의
        구매취소 규정이 우선 적용됩니다.
        <br />
        ⑦ 미성년자 또는 미성년자의 법정대리인은 미성년자의 구매를 취소할 수
        있으며 구체적인 내용은 민법 상 미성년자의 법률행위 취소 규정에 따릅니다.
        <br />⑧ 회원은 타인의 휴대전화번호이나 드림상점 로그인 정보를 도용하는
        등 본 약관에서 금지하는 부정한 방법으로 부당한 이익을 편취하였다고
        의심되는 경우 티켓 구매를 취소처리 하거나 티켓의 사용을 제한할 수
        있으며, 회원은 충분한 소명 자료를 제출할 때까지 환불을 보류할 수
        있습니다. 기타 이 약관 및 판매채널의 이용안내에 규정되지 않은 취소 및
        환불에 대한 사항에 대해서는 소비자 피해보상 규정에서 정한 바에 따릅니다.
      </div>
    ),
  },
  Privacy: {
    title: "개인정보보호정책",
    content: (
      <div>
        회사는 회원가입, 원활한 고객상담, 각종 서비스 등 기본적인 서비스 제공을
        위한 필수정보와 고객 맞춤 서비스 제공을 위한 선택정보로 구분하여 아래와
        같은 개인정보를 수집하고 있습니다.
        <br />
        <br />
        1) 개인 회원
        <br />
        필수항목 : 아이디, 비밀번호, 이름, 닉네임, 이메일
        <br />
        <br />
        2) 법인 회원
        <br />
        필수항목 : 아이디, 비밀번호, 법인명, 사업자등록번호, 대표자명, 업종,
        이메일, 담당자 이름, 담당자 생년월일, 담당자 성별, 담당자 본인확인값(CI,
        DI)
        <br />
        선택사항 : 설립일, 담당자 휴대폰 번호
        <br />
        <br />
        3) 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수
        있습니다.
        <br />
        IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기정보
        <br />
        선택정보를 입력하지 않은 경우에도 서비스 이용 제한은 없으며 이용자의
        기본적 인권 침해의 우려가 있는 민감한 개인 정보(인종, 사상 및 신조,
        정치적 성향 이나 범죄기록, 의료정보 등)는 기본적으로 수집하지 않습니다.
        <br />
        다만 불가피하게 수집이 필요한 경우 반드시 사전에 동의 절차를 거치도록
        하겠습니다.
        <br />
        <br />
        <br />
        개인정보의 수집 • 이용목적
        <br />
        <br />
        지역화폐협동조합은 이용자의 소중한 개인정보를 다음과 같은 목적으로만
        이용하며, 목적이 변경될 경우에는 사전에 이용자의 동의를 구하도록
        하겠습니다.
        <br />
        <br />
        회원으로 가입한 이용자를 식별하고 가입의사 및 나이 확인, 불량회원의
        부정한 이용을 방지하기 위하여 사용합니다. 다만 만14세 미만 아동의
        개인정보는 법령에 따라 법정대리인의 동의 여부도 확인하기 위해도
        사용됩니다.
        <br />
        이용자에게 지역화폐협동조합의 다양한 서비스를 제공하고 서비스 이용
        과정에서 이용자의 문의사항이나 불만을 처리하고 공지사항 등을 전달하기
        위해 사용합니다.
        <br />
        이용자와 약속한 서비스를 제공하고 유료 서비스 구매 및 이용이 이루어지는
        경우 이에 따른 요금 정산을 위해 사용됩니다.
        <br />
        신규 서비스가 개발되거나 이벤트 행사 시 참여기회를 알리기 위한 정보 전달
        및 마케팅 및 광고 등에도 사용됩니다.
        <br />
        이용자의 서비스 이용 기록과 접속 빈도 분석 및 서비스 이용에 대한 통계,
        이를 통한 맞춤형 서비스 제공과 서비스 개선에도 사용됩니다.
        <br />
        <br />
        <br />
        개인정보의 보유 • 이용기간
        <br />
        <br />
        한밭페이은 이용자의 개인정보를 회원가입을 하는 시점부터 서비스를
        제공하는 기간 동안에만 제한적으로 이용하고 있습니다. 이용자가 회원탈퇴를
        요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우,
        또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당
        이용자의 개인정보는 지체 없이 파기 됩니다.
        <br />
        그리고 관계법령의 규정에 따라 아래와 같이 관계 법령에서 정한 일정한 기간
        동안 회원정보를 보관합니다. 서비스 이용 관련 개인정보 (로그인기록)보존
        근거 : 통신비밀보호법
        <br />
        보존 기간 : 3개월
        <br />
        표시/광고에 관한 기록 보존 근거 : 전자상거래 등에서의 소비자보호에 관한
        법률
        <br />
        보존 기간 : 6개월
        <br />
        계약 또는 청약철회 등에 관한 기록 보존 근거 : 전자상거래 등에서의
        소비자보호에 관한 법률
        <br />
        보존 기간 : 5년
        <br />
        대금결제 및 재화 등의 공급에 관한 기록 보존 근거 : 전자상거래 등에서의
        소비자보호에 관한 법률
        <br />
        보존 기간 : 5년
        <br />
        소비 자의 불만 또는 분쟁처리에 관한 기록 보존 근거 : 전자상거래 등에서의
        소비자보호에 관한 법률 보존 기간 : 3년 개인위치정보에 관한 기록
        <br />
        보존 근거 : 위치정보의 보호 및 이용 등에 관한 법률
        <br />
        보존 기간 : 6개월
        <br />
        전자금융 거래에 관한 기록 보존 근거 : 전자금융거래법
        <br />
        보존 기간 : 5년
        <br />
        <br />
        <br />
        수집한 개인정보의 공유 및 제공
        <br />
        <br />
        회원의 개인정보는 약관과 개인정보를 수집하는 목적에서 고지한 범위 내에서
        사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나
        원칙적으로 정보주체의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의
        경우에는 예외로 합니다. 정보주체들이 사전에 제공에 동의한 경우 법령의
        규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
        수사기관의 요구가 있는 경우 홈페이지에 게시한 서비스 이용약관이나 기타
        회원서비스 등 이용약관 또는 정책을 위반한 경우 이벤트 행사시 응모하여
        당첨된 경우 경품 등을 발송하기 위해 배송업체에 당첨자 이름, 주소,
        연락처를 제공하는 경우
        <br />
        서비스 제공에 따른 협력사와의 업무 진행(요금정산 등)을 위하여 필요한
        경우
        <br />
        <br />
        수집한 개인정보의 위탁 및 제공
        <br />
        <br />
        회사는 정보주체들의 개인정보를 "개인정보의 수집 이용목적"에서 고지한
        범위 내에서 사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여
        이용하거나 원칙적으로 정보주체의 개인정보를 외부에 공개하지 않습니다.
        <br />
        다만, 아래의 경우에는 예외로 합니다. 정보주체들이 사전에 제공에 동의한
        경우 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우 홈페이지에 게시한 서비스 이용약관이나
        기타 회원서비스 등 이용약관 또는 정책을 위반한 경우 이벤트 행사시
        응모하여 당첨된 경우 경품 등을 발송하기 위해 배송업체에 당첨자 이름,
        주소, 연락처를 제공하는 경우 서비스 제공에 따른 협력사와의 업무
        진행(요금정산 등)을 위하여 필요한 경우 회사가 특정 업체에 특정 서비스의
        제공을 위탁하는 경우 서비스 제공에 필요한 회원의 개인정보를 위탁 받는
        업체에 제공할 수 있으며 서비스 위탁사실을 개인정보처리방침에 명시합니다.
        <br />
        회사의 업무를 위탁받는 업체는 제공받은 회원의 개인정보의 수집, 취급,
        관리에 있어 위탁 받은 목적 외의 용도로 이를 이용하거나 제3자에게
        제공하지 않습니다. 정보주체 및 법정 대리인의 권리와 그 행사방법 정보주체
        및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만
        아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도
        있습니다. 혹은 고객만족센터로 서면, 전화 또는 이메일로 연락하시면 내용을
        확인 후 지체 없이 조치하겠습니다. 정보주체가 개인정보의 오류에 대한
        정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용
        또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한
        경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정되도록
        하겠습니다. 정보주체 혹은 만 14세 미만 아동의 개인정보 조회, 수정,
        탈퇴는 지역화폐협동조합 홈페이지 내의 마이페이지 "회원정보수정"을,
        클릭하신 후 홈페이지의 안내사항에 따라 항목 선택 및 질문사항을
        입력하시면, 입력하신 사항을 기초로 정보주체 본인여부를 확인한 후
        처리하며, 탈퇴 처리된 아이디의 재사용은 불가합니다. 또한 탈퇴일로부터
        7일 동안은 재가입이 불가하며, 구매 후 주문취소로 인한 환불관련 분쟁의
        방지를 위해 특정 가맹점에서 한 달 이내에 구매한 이력이 있는 고객에 대해
        가탈퇴 처리하며, 30일 후 자동 탈퇴 처리합니다.
        <br />
        회사는 정보주체 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 "3. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고
        그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. <br />
        <br />
        <br />
        <br />
        개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
        <br />
        <br />
        회사는 정보주체의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
        운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 정보주체의
        브라우저에 보내는 아주 작은 텍스트 파일로서 정보주체의 컴퓨터
        하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를
        사용합니다.
        <br />* 쿠키 등 사용 목적 회원과 비회원의 접속 빈도나 방문 시간 등을
        분석, 정보주체의 취향과 관심분야를 파악 및 자취 추적,각종 이벤트 참여
        정도 및 방문 회수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공
        정보주체는 쿠키 설치에 대한 선택권을 가지고 있습니다.
        <br />
        따라서, 정보주체는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를
        허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
        저장을 거부할 수도 있습니다.
        <br />* 쿠키 설정 거부 방법 예(익스플로러의 경우) 웹 브라우저 상단의
        도구 &gt; 인터넷 옵션 &gt; 개인정보 단, 정보주체가 쿠키 설치를
        거부하였을 경우 회사의 서비스 제공에 어려움이 있을 수 있습니다.
        <br />
        <br />
        <br />
        개인정보의 기술적/관리적 보호 대책
        <br />
        <br />
        회사는 정보주체들의 개인정보를 취급함에 있어 개인정보가 분실, 도난,
        누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
        기술적/관리적 대책을 강구하고 있습니다.
        <br />
        비밀번호 암호화 지역화폐협동조합 회원 아이디(ID)의 비밀번호는 암호화되어
        저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도
        비밀번호를 알고 있는 본인에 의해서만 가능합니다.
        <br />
        <br />
        <br />
        해킹 등에 대비한 대책
        <br />
        <br />
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 정보주체의 개인정보가
        유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
        <br />
        개인정보의 훼손에 대비해서 자료를 정기적으로 백업하고 있고, 최신
        백신프로그램을 이용하여 정보주체들의 개인정보나 자료가 누출되거나
        손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서
        개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
        <br />
        그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
        있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적
        장치를 갖추려 노력하고 있습니다.
        <br />
        <br />
        <br />
        취급 직원의 최소화 및 교육
        <br />
        <br />
        회사의 개인정보관련 취급 직원은 담당자로 한정하고 있으며, 담당자에 대한
        교육 및 정기적인 비밀번호 변경 등 회사의 개인정보 처리방침의 준수를 항상
        강조하고 있습니다.
        <br />
        <br />
        <br />
        개인정보보호전담기구의 운영
        <br />
        <br />
        회사의 개인정보보호 전담기구(개인정보보호위원회) 등을 통하여 회사의
        개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가
        발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단,
        정보주체의 부주의나 인터넷 이용상의 문제로 지역화폐협동조합 ID, 비밀번호
        등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
        않습니다.
        <br />
        <br />
        미성년자 및 어린이의 정보보호 정책만 14세 미만의 아동들의 경우는
        지역화폐협동조합 가입 시, 꼭 부모(법정 대리인)의 동의를 받아야 합니다.
        <br />
        회사는 부모(법정 대리인)가 만 14세 미만 아동의 개인정보를 열람, 정정
        또는 탈퇴/해지를 요구하는 경우 내용을 확인 후 지체 없이 필요한 조치를
        취합니다.
        <br />
        지역화폐협동조합은 만 14세 미만 회원의 정보에 대해서는 사전에 동의하지
        않는 경우 제3자에게 제공하거나 판매, 대여하지 않으며, 유료 컨텐츠 서비스
        중 성인정보 서비스는 미성년자의 무분별한 구매활동을 보호하고자
        관계법령에 따라 서비스를 제한합니다.
        <br />
        <br />
        개인정보에 관한 민원서비스
        <br />
        회사는 정보주체의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 개인정보 보호책임자 및 담당부서를 지정하고 있습니다.
        또한 개인정보 열람/정정·삭제/처리정지 요구 및 위임 관련 양식을 제공하고
        있습니다.
        <br />
        <br />
        개인정보 보호책임자: 이원표
        <br />
        전화번호 : 042-331-9114
        <br />
        이메일 : hyup_365@hanmail.net
        <br />
        <br />
        고객서비스 담당 부서: 고객만족센터
        <br />
        전화번호 : 042-331-9114
        <br />
        이메일 : hyup_365@hanmail.net
        <br />
        <br />
        기타 개인정보침해에 대한 신고 및 상담 기관 안내
        <br />- 개인정보 침해센터 : (국번없이) 118 (http://privacy.kisa.or.kr)
        <br />- 개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)
        <br />- 대검찰청 사이버수사과 : (국번없이) 1301, cid@spo.go.kr
        (http://spo.go.kr)
        <br />- 경찰청 사이버안전국 : (국번없이) 182
        (http://cyberbureau.police.go.kr)
      </div>
    ),
  },
  ThirdParty: {
    title: "제3자 정보제공",
    content: (
      <div>
        서비스 제공을 위해 개인정보는 드림상점과 모바일상품권 판매처에만
        제공되며. 제공된 개인정보는 모바일상품권 발송 및 사용에 대한 목적으로만
        사용됩니다. 또한 정보의 제공 범위는 성명, 휴대폰번호, 주소, ID 이며
        서비스 제공 완료 3개월 후 삭제됩니다. 고객님께서는 정보제공에 동의하지
        않을 수 있으며, 동의하지 않는 경우 서비스 이용이 제한될 수 있습니다.
        <br />
        <br />
        1.  개인정보를 제공받는 자<br />- (주)한밭페이
        <br />
        <br />
        2.  개인정보를 제공받는 자의 개인정보 이용 목적
        <br />- 개인화된 콘텐츠 추천 서비스 부정이용방지
        <br />- 이벤트
        <br />
        <br />
        3.  제공하는 개인정보의 항목
        <br />- 한밭페이 이용내역
        <br />- 한밭페이 서비스 이용 결제내역
        <br />- 한밭페이 전자고지 및 인증내역
        <br />- 한밭페이 드림 및 드림p(기부포인트) 사용내역
        <br />- 한밭페이 서비스앱 이용 기록
        <br />
        <br />
        4.  개인정보를 제공받는 자의 개인정보 보유 및 이용 기간
        <br />- 한밭페이 탈퇴시 까지 제 3자 제공에 동의를 거부하실수있으며,
        거부시에는 개인 맞춤서비스 제공이제한될수 있습니다.
      </div>
    ),
  },
}

export const OrderPolicy = {
  Refund: {
    title: "교환/환불",
    content: (
      <div className="policy">
        <div className="cont">
          <p>- 구독상품 결품 시 100%전액 환불됩니다.</p>
          <p>
            - 단순변심이나 상품의 질 저하로 교환 및 환불을 원하시는 경우
            배송받으신 매장으로 문의 혹은 방문해주시기 바랍니다.
          </p>
        </div>
      </div>
    ),
  },
  Payment: {
    title: "배송/결제",
    content: (
      <div className="policy">
        <div className="cont">
          <p>- 상품은 3만드림 이상 구매시 결제 및 배송됩니다.</p>
          <p>
            - 배송 전날 오후 2시에 자동으로 결제되며, 결제되지 않을 시 오후
            5시에 2차결제가 진행됩니다.
          </p>
          <p>
            - 결제 미완료 시 배송은 진행되지 않으며, 배송날짜는 한 주뒤로
            연기됩니다.
          </p>
          <p>- 배송은 총 2가지로, 개인배송과 거점배송으로 나뉘어져 있습니다.</p>
          <ul>
            <li className="first">
              ① <b>거점배송 :</b> 주 1회 3만드림이상 최소 5명이 모이면 지정한
              곳으로 일괄배송
            </li>
            <li>
              - <b>사무국 문의 :</b> 042-331-9114
            </li>
            <li className="first">
              ② <b>개인배송 :</b> 각 매장별 개인배송가능지역인지 확인
            </li>
            <li>
              - <b>관저점 :</b> 관저동, 원내동, 교촌동, 대정동{" "}
            </li>
            <li>
              - <b>도안점 :</b> 상대동, 가수원동, 원신흥동, 도안동{" "}
            </li>
            <li>
              - <b>지족점 :</b> 노은동, 지족동, 하기동, 반석동, 죽동
            </li>
          </ul>
        </div>
      </div>
    ),
  },
}
