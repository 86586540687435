import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_ADDRESS_LIST_QUERY = gql`
  query DeliveryAccountAddressList($skip: Int!, $take: Int) {
    DeliveryAccountAddressList(skip: $skip, take: $take) {
      deliveryAddresses {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        sido
        sigungu
        upmyundong
        li
        deliveryStore {
          id
          createdAt
          updatedAt

          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      count
    }
    DeliveryAccountBoxDetail {
      id
      createdAt
      updatedAt

      state
      type
      deliveryPlace {
        id
        createdAt
        updatedAt

        commonStatus
        name
        managerName
        managerPhone
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        deliveryStore {
          id
          createdAt
          updatedAt
          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
        }
      }
      deliveryAddress {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        sido
        sigungu
        upmyundong
        li

        deliveryStore {
          id
          createdAt
          updatedAt
          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
        }
      }

      condition
      totalPrice
      deliveryDate
    }
  }
`;
export const DELIVERY_ACCOUNT_ADDRESS_REGISTER_MUTATION = gql`
  mutation DeliveryAccountAddressRegister(
    $name: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String!
    $latitude: String!
    $dayOfWeek: String!
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $li: String!
  ) {
    DeliveryAccountAddressRegister(
      name: $name
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      li: $li
    )
  }
`;

export const DELIVERY_ACCOUNT_ADDRESS_WRITE_MUTATION = gql`
  mutation DeliveryAccountAddressWrite(
    $name: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String!
    $latitude: String!
    $dayOfWeek: String!
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $li: String!
  ) {
    DeliveryAccountAddressWrite(
      name: $name
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      li: $li
    )
  }
`;

export const DELIVERY_ACCOUNT_ADDRESS_DETAIL_QUERY = gql`
  query DeliveryAccountAddressDetail($id: String!) {
    DeliveryAccountAddressDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      name
      zipcode
      jibunAddress
      roadAddress
      addressDetail
      longitude
      latitude
      dayOfWeek
      sido
      sigungu
      upmyundong
      li
      deliveryStore {
        id
        createdAt
        updatedAt
        commonStatus
        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
          phone
        }
      }
    }
    DeliveryAccountStoreDongList(skip: 0, take: 100) {
      deliveryStoreDongs {
        id
        createdAt
        updatedAt

        commonStatus
        sido
        sigungu
        upmyundong
        ri

        deliveryStore {
          id
          createdAt
          updatedAt

          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      count
    }
    DeliveryAccountBoxDetail {
      id
      createdAt
      updatedAt

      state
      type
      deliveryAddress {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        sido
        sigungu
        upmyundong
        li
      }
    }
  }
`;

export const DELIVERY_ACCOUNT_ADDRESS_EDIT_MUTATION = gql`
  mutation DeliveryAccountAddressEdit(
    $id: String!
    $name: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String!
    $latitude: String!
    $dayOfWeek: String!
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $li: String!
  ) {
    DeliveryAccountAddressEdit(
      id: $id
      name: $name
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      li: $li
    )
  }
`;

export const DELIVERY_ACCOUNT_ADDRESS_DELETE_MUTATION = gql`
  mutation DeliveryAccountAddressDelete($id: String!) {
    DeliveryAccountAddressDelete(id: $id)
  }
`;
