import styled from "styled-components"
import theme from "../Style/Theme"

const SFormError = styled.div`
  color: ${theme.colors.danger};
  font-weight: ${theme.fonts.weight.medium};
  font-size: 13px;
  margin: 5px 0;
`

function FormError({ message }) {
  return message ? (
    <SFormError>
      <span>* {message}</span>
    </SFormError>
  ) : null
}

export default FormError
