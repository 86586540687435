import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_BOX_WRITE_MUTATION = gql`
  mutation DeliveryAccountBoxWrite(
    $type: String!
    $deliveryPlace_id: String
    $deliveryAddress_id: String
  ) {
    DeliveryAccountBoxWrite(
      type: $type
      deliveryPlace_id: $deliveryPlace_id
      deliveryAddress_id: $deliveryAddress_id
    )
  }
`;

export const DELIVERY_ACCOUNT_BOX_DETAIL_QUERY = gql`
  query DeliveryAccountBoxDetail {
    DeliveryAccountBoxDetail {
      id
      createdAt
      updatedAt

      state
      type
      deliveryPlace {
        id
        createdAt
        updatedAt

        commonStatus
        name
        managerName
        managerPhone
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        deliveryStore {
          id
          createdAt
          updatedAt
          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
        }
      }
      deliveryAddress {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        sido
        sigungu
        upmyundong
        li

        deliveryStore {
          id
          createdAt
          updatedAt
          commonStatus
          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
        }
      }
      deliveryBoxProducts {
        id
        createdAt
        updatedAt

        state
        count
        deliveryProduct {
          id
          createdAt
          updatedAt

          commonStatus
          coverImage
          timestamp
          price
          name
          description
        }
        deliveryProductOptionDetail1 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail2 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail3 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }

        price
        totalPrice
      }
      # deliveryOrders {
      #   id
      #   createdAt
      #   updatedAt
      # }
      user {
        id
        createdAt
        updatedAt
      }
      condition
      totalPrice
      deliveryDate
    }
    DeliveryAccountUserDetail {
      id
      createdAt
      updatedAt

      avatar
      userId
      name
      email
      phone
      birthDate
      zipcode
      roadAddress
      jibunAddress
      addressDetail
      latitude
      longitude

      role
      trustlines {
        id
        commonStatus
        currencyType
        currency
        amount
        createdAt
        updatedAt
      }
      wallet {
        id
      }
      store {
        id
        name
        storeCEO
        storeNumber
        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail
        storeCategory {
          id
          name
        }
      }
      visitedAt

      isCms
      cmsValue
      cmsDay

      addCurrency

      isDelivery

      deliveryBoxProductCount
    }
  }
`;

export const DELIVERY_ACCOUNT_BOX_EDIT_MUTATION = gql`
  mutation DeliveryAccountBoxEdit(
    $type: String!
    $deliveryPlace_id: String
    $deliveryAddress_id: String
  ) {
    DeliveryAccountBoxEdit(
      type: $type
      deliveryPlace_id: $deliveryPlace_id
      deliveryAddress_id: $deliveryAddress_id
    )
  }
`;

export const DELIVERY_ACCOUNT_BOX_EDIT_STATE_TOGGLE_MUTATION = gql`
  mutation DeliveryAccountBoxEditStateToggle {
    DeliveryAccountBoxEditStateToggle
  }
`;
