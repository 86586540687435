import React from "react";
import { useSearchParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_SET_LIST_QUERY } from "../../../Config/Queries/Set";

const Container = () => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get("page") ? +searchParams.get("page") : 1;
  const take = 20;
  const baseUrl = "/sets";

  const { loading, error, data } = useQuery(DELIVERY_SET_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take,
      skip: (page - 1) * take,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
};

export default Container;
