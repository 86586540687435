import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../Components/Layout/Layout";
import {
  ProductList,
  ProductTitle,
  Ready,
} from "../../../Components/Style/Styles";
import SetItem from "../../../Components/Product/SetItem";
import PageTitle from "../../../Components/Layout/PageTitle";
import { NumericFormat } from "react-number-format";
import Pagination from "../../../Components/Common/Pagination";

function Presenter({ page, take, loading, data, baseUrl }) {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultUrl = baseUrl + "?";

  return (
    <Layout isNav={true} isFooter={true} isSearch={true}>
      <PageTitle title={`묶음`} />
      <ProductTitle>
        <h3>
          묶음상품{" "}
          <b>
            <NumericFormat
              displayType={"text"}
              value={!loading ? data.DeliverySetList.count : 0}
              thousandSeparator={true}
            />
          </b>
          건
        </h3>
      </ProductTitle>
      {!loading ? (
        <Fragment>
          {data.DeliverySetList.count > 0 ? (
            <>
              <ProductList>
                <ul>
                  {data.DeliverySetList.deliverySets.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`${baseUrl}/${item.id}${location.search}`}>
                          <SetItem item={item} />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </ProductList>
              <Pagination
                totalRecord={data.DeliverySetList.count}
                blockSize={5}
                pageSize={take}
                currentPage={page}
                baseUrl={`${defaultUrl}`}
              />
            </>
          ) : (
            <Ready>등록된 묶음상품이 없습니다.</Ready>
          )}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Presenter;
