import React from "react";
import { useParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_ACCOUNT_ADDRESS_DETAIL_QUERY } from "../../../../Config/Queries/Address";

const Container = () => {
  const params = useParams();
  const id = params.id;

  const { data, loading, error } = useQuery(
    DELIVERY_ACCOUNT_ADDRESS_DETAIL_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { id },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return <Presenter loading={loading} data={data} />;
};

export default Container;
