import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { uri } from "../../Config/Apollo"

import { useQuery } from "@apollo/client"
import { DELIVERY_PRODUCT_CATEGORY_LIST_QUERY } from "../../Config/Queries/Product"
import { Ready } from "../Style/Styles"

function Category() {
  const { data, error, loading } = useQuery(
    DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { skip: 0, take: 100 },
    }
  )

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>
  }
  return (
    <Fragment>
      {!loading ? (
        <ul>
          {data.DeliveryProductCategoryList.deliveryProductCategories.map(
            (item, index) => {
              const url =
                "/products?" +
                (item?.id ? `deliveryProductCategory_id=${item?.id}&` : ``) +
                `page=1`

              const iconImage =
                item.coverImage === "" || item.iconImage === null
                  ? "/image/default.png"
                  : `${uri}${item.iconImage}`

              return (
                <li key={index}>
                  <Link to={url}>
                    <div className="img">
                      <img src={iconImage} alt={item.name} />
                    </div>
                    <p>
                      {item.name} <b>{item.deliveryProductsCount}</b>
                    </p>
                  </Link>
                </li>
              )
            }
          )}
        </ul>
      ) : (
        <Ready>상품 분류가 없습니다.</Ready>
      )}
    </Fragment>
  )
}

export default Category
