import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Style/Theme"
import { InputGroup, Row, Updown } from "../Style/Styles"
import CheckBtn from "../Common/CheckBtn"
import RadioBtn from "../Common/RadioBtn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { NumericFormat } from "react-number-format"
import { uri } from "../../Config/Apollo"

const ItemList = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${theme.paddings.base} ${theme.paddings.lg};
  .product {
    flex: 1;
    border-bottom: solid 1px ${theme.colors.gray};
    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.margins.base};
    }
    .left {
      flex: 1;
      h3 {
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black01};
        font-weight: ${theme.fonts.weight.bold};
      }
      .option {
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.black03};
      }
    }
    .right {
      flex: 1;
      margin-left: ${theme.margins.base};
      .price {
        margin-bottom: ${theme.margins.base};
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black01};
      }
    }
    .delete_btn {
      display: flex;
      background-color: transparent;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.black04};
      &:hover {
        color: ${theme.colors.black01};
      }
    }
    .img {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      img {
        max-width: 100%;
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &.missing {
      .img {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          top: 0;
          left: 0;
          right: 0;
          border-radius: 4px;
        }
        span {
          position: absolute;
          left: 5px;
          right: 5px;
          top: 50%;
          background-color: rgba(0, 0, 0, 0.5);
          padding: ${theme.paddings.sm} ${theme.paddings.base};
          font-size: ${theme.fonts.size.sm};
          color: white;
          text-align: center;
          transform: translateY(-50%);
          border-radius: 6px;
        }
      }
    }
  }
`
function Item({
  item,
  onDelete,
  onEditCount,
  onEditState,
  doChecker,
  checkItems,
}) {
  const {
    id,
    // createdAt,
    // updatedAt,

    state,
    count,
    deliveryProduct,
    deliveryProductOptionDetail1,
    deliveryProductOptionDetail2,
    deliveryProductOptionDetail3,

    // price,
    totalPrice,
  } = item

  const onMinus = () => {
    if (count <= 1) return
    onEditCount(item, count - 1)
  }

  const onPlus = () => {
    onEditCount(item, count + 1)
  }

  const cover =
    deliveryProduct.coverImage === "" || deliveryProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${uri}${deliveryProduct.coverImage}`

  return (
    <ItemList>
      <CheckBtn
        onChange={(event) => doChecker(event, item)}
        checked={checkItems.includes(item) ? true : false}
      />
      {/*결품시 className 에 missing추가 */}
      <div className="product ">
        <Row className="row">
          <div className="left">
            <h3>{deliveryProduct.name}</h3>
            {(deliveryProductOptionDetail1 ||
              deliveryProductOptionDetail2 ||
              deliveryProductOptionDetail3) && (
              <span className="option">
                [옵션]
                {deliveryProductOptionDetail1 &&
                  ` ${deliveryProductOptionDetail1.name}`}
                {deliveryProductOptionDetail2 &&
                  ` / ${deliveryProductOptionDetail2.name}`}
                {deliveryProductOptionDetail3 &&
                  ` / ${deliveryProductOptionDetail3.name}`}
              </span>
            )}
          </div>
          <button className="delete_btn" onClick={() => onDelete(item)}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </button>
        </Row>
        <Row className="row">
          <Link to={`/products/${item.deliveryProduct.id}`}>
            <div className="img">
              <img src={cover} alt={deliveryProduct.name} />
              {/* <span>결품</span> */}
            </div>
          </Link>
          <div className="right">
            <div className="price">
              <b>
                <NumericFormat
                  displayType={"text"}
                  value={totalPrice}
                  thousandSeparator={true}
                />
              </b>
              드림
            </div>
            <Updown>
              <button onClick={onMinus}>
                <FontAwesomeIcon icon={faMinus} size="lg" />
              </button>
              <div>
                <input value={count} readOnly={true} />
              </div>
              <button onClick={onPlus}>
                <FontAwesomeIcon icon={faPlus} size="lg" />
              </button>
            </Updown>
          </div>
        </Row>
        <InputGroup>
          <div className="form_group">
            <label className="label_txt">구독옵션</label>
            <div className="row" style={{ margin: 0 }}>
              <RadioBtn
                id={`Onetime-${id}`}
                text={"1회 구독"}
                value={"Onetime"}
                onChange={() => onEditState(item, "Onetime")}
                checked={"Onetime" === state}
              />
              <RadioBtn
                id={`Subscription-${id}`}
                text={"정기구독"}
                value={"Subscription"}
                onChange={() => onEditState(item, "Subscription")}
                checked={"Subscription" === state}
              />
            </div>
          </div>
        </InputGroup>
      </div>
    </ItemList>
  )
}
export default Item
