import styled from "styled-components";
import Link from "../../Components/Common/Links";
import Layout from "../../Components/Layout/Layout";
import PageTitle from "../../Components/Layout/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../Components/Style/Theme";
import { faFaceFrownOpen } from "@fortawesome/pro-duotone-svg-icons";

const Notfound = styled.div`
  display: flex;
  padding: 0 ${theme.paddings.xl};
  color: ${theme.colors.black04};
  font-size: ${theme.fonts.size.sm};
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .img {
  }
  .cont {
    margin: 32px 0;
    h3 {
      font-size: 24px;
      line-height: 32px;
      font-size: ${theme.fonts.size.xl};
      font-weight: ${theme.fonts.weight.base};
      color: ${theme.colors.black02};
    }
    b {
      color: ${theme.colors.primary};
    }
  }
  .btn {
    width: 50%;
  }
`;

function Error() {
  return (
    <Layout isNav={true} isSearch={true}>
      <PageTitle title="상품" />
      <Notfound>
        <div className="icon">
          <FontAwesomeIcon
            icon={faFaceFrownOpen}
            color={theme.colors.primary}
            size="6x"
          />
        </div>
        <div className="cont">
          <h3>죄송합니다.</h3>
          <h3>현재 페이지를 찾을 수 없습니다.</h3>
        </div>

        <div className="btn">
          <Link color="primary" to="/main" width="full" border="radius">
            홈으로
          </Link>
        </div>
      </Notfound>
    </Layout>
  );
}
export default Error;
