import styled from "styled-components";
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { uri } from "../../Config/Apollo";

const SwiperSlideImg = styled.div`
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  :before {
    content: "";
    display: block;
    padding-top: 85%;
  }
  :after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
  img {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;
    &.sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
`;

function Banner({ DeliveryBannerList }) {
  const { deliveryBanners } = DeliveryBannerList;

  return (
    <div className="swiper-banner">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          type: "fraction",
        }}
        navigation={false}
        modules={[Pagination]}
        className="mySwiper"
      >
        {deliveryBanners.map((item, index) => {
          const cover =
            item.coverImage === "" || item.coverImage === null
              ? "url(/logo512.png)"
              : `${uri}${item.coverImage}`;

          return (
            <SwiperSlide key={index}>
              {item.isLink ? (
                <Link to={item.link}>
                  <SwiperSlideImg
                    style={{
                      backgroundImage: cover,
                    }}
                  >
                    <img src={cover} alt={item.title} />
                  </SwiperSlideImg>
                </Link>
              ) : (
                <SwiperSlideImg
                  style={{
                    backgroundImage: cover,
                  }}
                >
                  <img src={cover} alt={item.title} />
                </SwiperSlideImg>
              )}
            </SwiperSlide>
          );
        })}

        <SwiperSlide>
          <SwiperSlideImg
            style={{
              backgroundImage: "url(/image/banner_01.png)",
            }}
          >
            <img src={"/image/banner_01.png"} alt="" />
          </SwiperSlideImg>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Banner;
