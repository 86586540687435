import React, { Fragment } from "react";
import Layout from "../../Components/Layout/Layout";
import Banner from "../../Components/Home/Banner";
import Category from "../../Components/Home/Category";
import SetList from "../../Components/Home/SetList";
import BestList from "../../Components/Home/BestList";

function Main({ loading, data }) {
  return (
    <Layout isNav={true} isFooter={true} isSearch={true}>
      {!loading ? (
        <Fragment>
          <Banner DeliveryBannerList={data.DeliveryBannerList} />
          <Category
            DeliveryProductCategoryList={data.DeliveryProductCategoryList}
          />
          <SetList DeliverySetList={data.DeliverySetList} />
          <BestList DeliveryProductList={data.DeliveryProductList} />
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Main;
