import { useReactiveVar } from "@apollo/client";
import { Navigate, useOutlet } from "react-router-dom";
import { isLoggedInVar } from "./Apollo";
import Login from "../Routes/Auth/Login/index";

export const PublicAuth = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const outlet = useOutlet();

  if (isLoggedIn) {
    return <Navigate to={"/main"} />;
  }

  return outlet;
};

export const ProtectedAuth = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const outlet = useOutlet();

  if (isLoggedIn) {
    return outlet;
  } else {
    return <Login />;
  }
};
