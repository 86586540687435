import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { uri } from "../../Config/Apollo"

import styled, { css } from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"

const SwiperCate = styled.div`
  ${({ theme }) => {
    return css`
      margin: 24px 0;
      .container {
        padding: 0 ${theme.paddings.xl};
      }
    `
  }}
`
const CateItems = styled.div`
  ${({ theme }) => {
    return css`
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: ${theme.fonts.family.base};
        .img {
          background-color: ${theme.colors.lightwarning};
          display: flex;
          width: 58px;
          height: 58px;
          align-items: center;
          justify-content: center;
          border-radius: 32px;
          overflow: hidden;
          img {
            max-width: 100%;
          }
        }
        p {
          margin-top: 5px;
          height: 38px;
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.medium};
          font-size: 14px;
          text-align: center;
          overflow: hidden;
          b {
            color: ${theme.colors.primary};
            font-weight: ${theme.fonts.weight.bold};
            margin-left: 2px;
          }
        }
      }
    `
  }}
`

function Category({ DeliveryProductCategoryList }) {
  const { deliveryProductCategories, count } = DeliveryProductCategoryList

  return (
    <SwiperCate>
      <Swiper className="container" slidesPerView={4.5} spaceBetween={10}>
        {deliveryProductCategories.map((item, index) => {
          const iconImage =
            item.iconImage === "" || item.iconImage === null
              ? "/image/default.png"
              : `${uri}${item.iconImage}`

          return (
            <SwiperSlide key={index}>
              <CateItems>
                <Link
                  to={`/products?deliveryProductCategory_id=${item?.id}&page=1`}
                >
                  <div className="img">
                    <img src={iconImage} alt={item.name} />
                  </div>
                  <p>
                    {item.name}
                    <b>{item.deliveryProductsCount}</b>
                  </p>
                </Link>
              </CateItems>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </SwiperCate>
  )
}

export default Category
