import React from "react";
import { useParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_SET_DETAIL_QUERY } from "../../../Config/Queries/Set";

export default () => {
  const params = useParams();
  const id = params.id;
  const baseUrl = "/sets";

  const { data, loading, error } = useQuery(DELIVERY_SET_DETAIL_QUERY, {
    variables: { id },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return <Presenter data={data} baseUrl={baseUrl} />;
};
