import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import theme from "../Style/Theme";

const Paginations = styled.div`
  width: 100%;
  display: flex;
  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${theme.paddings.sm};
    li {
      margin: 0 ${theme.margins.xs};
      justify-content: center;
      .page-link {
        background-color: #f5f5f5;
        display: flex;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        font-size: ${theme.fonts.size.base};
        font-weight: ${theme.fonts.weight.medium};
        color: ${theme.colors.black04};
        border-radius: 8px;
        :hover {
          background-color: ${theme.colors.primary};
        }
      }
    }
    li.active {
      .page-link {
        background-color: ${theme.colors.primary};
        font-weight: ${theme.fonts.weight.bold};
        color: white;
      }
    }
  }
`;

const Item = ({ currentPage, baseUrl, startPage, endPage, totalPage }) => {
  let pages = [];
  let page = parseInt(currentPage);

  for (var i = startPage; i <= endPage && i <= totalPage; i++) {
    if (i === page) {
      pages.push(
        <li
          className={"page-item " + (i === page ? "active" : "")}
          key={"pagination" + i}
        >
          <div className="page-link" title="현재 페이지" to="#">
            {currentPage}
          </div>
        </li>
      );
    } else {
      pages.push(
        <li className="page-item" key={"pagination" + i}>
          <Link
            className="page-link"
            title={i + " 페이지 이동"}
            to={baseUrl + "page=" + i}
          >
            {i}
          </Link>
        </li>
      );
    }
  }
  return pages;
};

const Pagination = ({
  totalRecord,
  blockSize,
  pageSize,
  currentPage,
  baseUrl,
}) => {
  const currentBlock = Math.ceil(currentPage / blockSize);
  const totalPage = Math.ceil(totalRecord / pageSize);
  const totalBlock = Math.ceil(totalPage / blockSize);

  const startPage = (currentBlock - 1) * blockSize + 1;
  const endPage = currentBlock * blockSize;

  const prevBlockPage = startPage - 1;
  const nextBlockPage = endPage + 1;

  if (totalRecord === 0) {
    return <></>;
  }

  return (
    <Paginations>
      <ul className="pagination mb-0 justify-content-center">
        {currentBlock > 1 && (
          <li className={`page-item`} key={"paginationPrev"}>
            <Link
              className="page-link"
              title={"이전 " + blockSize + "페이지 보기"}
              to={baseUrl + "page=" + prevBlockPage}
            >
              <FontAwesomeIcon icon={faAngleLeft} color="#999" size="lg" />
            </Link>
          </li>
        )}
        <Item
          currentPage={currentPage}
          baseUrl={baseUrl}
          startPage={startPage}
          endPage={endPage}
          totalPage={totalPage}
        />
        {currentBlock < totalBlock && (
          <li className={`page-item`} key={"paginationNext"}>
            <Link
              className="page-link"
              title={"다음 " + nextBlockPage + "페이지 보기"}
              to={baseUrl + "page=" + nextBlockPage}
            >
              <FontAwesomeIcon icon={faAngleRight} color="#999" size="lg" />
            </Link>
          </li>
        )}
      </ul>
    </Paginations>
  );
};

export default Pagination;
