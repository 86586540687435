import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import theme from "../Style/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.lightgray};
  border: solid 1px #eee;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.25s linear;
  .accordion {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 0 ${theme.paddings.base};
    p {
      flex: 1;
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.sm};
      color: ${theme.colors.black02};
    }
    span {
      font-size: ${theme.fonts.size.base};
    }
  }
  .accordion_content {
    background-color: white;
    opacity: 0;
    transition: all 0.25s linear;
    &.active {
      opacity: 1;
    }
    .accordion_text {
      padding: ${theme.paddings.sm} ${theme.paddings.base};
    }
  }
`;
function Accordion(props) {
  const [active, setActive] = useState(true);
  const content = useRef(null);
  const [height, setHeight] = useState();

  useEffect(() => {}, [height]);
  const accordionToggle = () => {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  };
  useEffect(() => {});
  return (
    <AccordionContainer>
      <div
        className={`accordion ${active ? "active" : ""}`}
        onClick={accordionToggle}
      >
        <p>{props.title}</p>
        <span>
          {active ? (
            <FontAwesomeIcon icon={faAngleUp} color={theme.colors.black03} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} color={theme.colors.black03} />
          )}
        </span>
      </div>
      <div
        ref={content}
        className={`accordion_content ${active ? "active" : ""}`}
        style={{ height: `${height}` }}
      >
        <div className="accordion_text">{props.content}</div>
      </div>
    </AccordionContainer>
  );
}
export default Accordion;
