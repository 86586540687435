import styled, { css } from "styled-components"
import { NumericFormat } from "react-number-format"
import { uri } from "../../Config/Apollo"
import { Item } from "./ProductItem"

export const SetItem = ({ item }) => {
  const CoverImage =
    item.coverImage === "" || item.coverImage === null
      ? "/image/default.png"
      : `${uri}${item.coverImage}`
  return (
    <Item>
      <div className="img">
        <img src={CoverImage} alt={item.name} />
      </div>
      <div className="cont">
        {item.deliveryProductCategory?.name && (
          <span className="category">{item.deliveryProductCategory.name}</span>
        )}
        <h3>{item.name}</h3>
        <span className="price">
          <b>
            <NumericFormat
              displayType={"text"}
              value={item.totalPrice}
              thousandSeparator={true}
            />
          </b>{" "}
          드림
        </span>
      </div>
    </Item>
  )
}

export default SetItem
