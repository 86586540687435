import React from "react";
import { useSearchParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_PRODUCT_LIST_QUERY } from "../../../Config/Queries/Product";

const Container = () => {
  const [searchParams] = useSearchParams();

  const deliveryProductCategory_id = searchParams.get(
    "deliveryProductCategory_id"
  )
    ? searchParams.get("deliveryProductCategory_id")
    : "";
  const keyword = searchParams.get("keyword")
    ? searchParams.get("keyword")
    : "";
  const orderBy = searchParams.get("orderBy")
    ? searchParams.get("orderBy")
    : "";
  const page = searchParams.get("page") ? +searchParams.get("page") : 1;
  const take = 20;
  const baseUrl = "/products";

  const { loading, error, data } = useQuery(DELIVERY_PRODUCT_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      deliveryProductCategory_id,
      keyword,
      orderBy: orderBy ? orderBy : "id_DESC",
      take,
      skip: (page - 1) * take,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      deliveryProductCategory_id={deliveryProductCategory_id}
      keyword={keyword}
      orderBy={orderBy}
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
};

export default Container;
