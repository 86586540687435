import React from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { MainTitle, Ready } from "../Style/Styles"
import { ArrowRight2 } from "iconsax-react"
import Theme from "../Style/Theme"
import { uri } from "../../Config/Apollo"
import { NumericFormat } from "react-number-format"

const BestList = styled.div`
  ${({ theme }) => {
    return css`
      width: auto;
      padding: 0 ${theme.paddings.xl};
    `
  }}
`
const BestItem = styled.div`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.lg};
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        .img {
          position: relative;
          overflow: hidden;
          border-radius: 8px;
          height: 120px;
          width: 120px;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 8px;
          }
          img {
            position: absolute;
            top: 0;
            max-width: 100%;
            min-width: 100%;
            display: block;
            min-height: 100%;
            max-height: 100%;
          }
        }
        .cont {
          flex: 1;
          padding: 10px 15px;
          .cate {
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black04};
            font-weight: ${theme.fonts.weight.normal};
          }
          strong {
            display: block;
            font-size: ${theme.fonts.size.lg};
            font-weight: ${theme.fonts.weight.medium};
            color: ${theme.colors.black01};
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .price {
            margin-top: ${theme.margins.base};
            font-size: ${theme.fonts.size.base};
            color: ${theme.colors.black01};
            font-weight: ${theme.fonts.weight.normal};
            b {
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
      }
    `
  }}
`

function Best({ DeliveryProductList }) {
  const { deliveryProducts } = DeliveryProductList
  return (
    <>
      <MainTitle>
        <h3>
          인기상품<b>10</b>
        </h3>
        <Link to="/products" className="more">
          더보기{" "}
          <ArrowRight2
            size={14}
            color={Theme.colors.black04}
            variant="Linear"
          />
        </Link>
      </MainTitle>
      {deliveryProducts.length > 0 ? (
        <BestList>
          {deliveryProducts.map((item, index) => {
            const coverImage =
              item.coverImage === "" || item.coverImage === null
                ? "/image/default.png"
                : `${uri}${item.coverImage}`
            return (
              <BestItem key={index}>
                <Link to={`/products/${item.id}`}>
                  <div className="img">
                    <img src={coverImage} alt={item.name} />
                  </div>
                  <div className="cont">
                    <span className="cate">
                      {item.deliveryProductCategory.name}
                    </span>
                    <strong>{item.name}</strong>
                    <div className="price">
                      <b>
                        <NumericFormat
                          displayType={"text"}
                          value={item.price}
                          thousandSeparator={true}
                        />
                      </b>{" "}
                      드림
                    </div>
                  </div>
                </Link>
              </BestItem>
            )
          })}
        </BestList>
      ) : (
        <BestList>
          <Ready>등록된 상품이 없습니다.</Ready>
        </BestList>
      )}
    </>
  )
}

export default Best
