import { gql } from "@apollo/client";

// 분류 목록
export const DELIVERY_PRODUCT_CATEGORY_LIST_QUERY = gql`
  query DeliveryProductCategoryList($skip: Int!, $take: Int) {
    DeliveryProductCategoryList(skip: $skip, take: $take) {
      deliveryProductCategories {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order

        deliveryProductsCount
      }
      count
    }
  }
`;

export const DELIVERY_PRODUCT_LIST_QUERY = gql`
  query DeliveryProductList(
    $deliveryProductCategory_id: String
    $keyword: String
    $orderBy: String
    $skip: Int!
    $take: Int
  ) {
    DeliveryProductList(
      deliveryProductCategory_id: $deliveryProductCategory_id
      keyword: $keyword
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      deliveryProducts {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        price
        name
        description

        deliveryProductCategory {
          id
          createdAt
          updatedAt

          commonStatus
          iconImage
          name
          order
        }
        deliveryProductOptions {
          id
          createdAt
          updatedAt

          commonStatus
          name
          deliveryProductOptionDetails {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
  }
`;
export const DELIVERY_PRODUCT_DETAIL_QUERY = gql`
  query DeliveryProductDetail($id: String!) {
    DeliveryProductDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      coverImage
      timestamp
      price
      name
      description

      deliveryProductCategory {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order
      }
      deliveryProductOptions {
        id
        createdAt
        updatedAt

        commonStatus
        name
        deliveryProductOptionDetails {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }
    }
  }
`;
