import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../Components/Layout/Layout";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../Components/Common/Buttons";
import Links from "../../../Components/Common/Links";
import {
  Bundle,
  DetailContainer,
  DetailInfo,
  Dot,
  FooterButton,
  InfoBox,
} from "../../../Components/Style/Styles";
import Accordion from "../../../Components/Common/Accordion";
import { bundleProduct } from "../../../Components/Datas";
import PageTitle from "../../../Components/Layout/PageTitle";
import { uri } from "../../../Config/Apollo";
import { NumericFormat } from "react-number-format";
import RowInfo from "../../../Components/Common/RowInfo";

function Detail({ data, baseUrl }) {
  const {
    DeliverySetDetail: {
      id,
      createdAt,
      updatedAt,

      commonStatus,
      coverImage,
      timestamp,
      price,
      name,
      description,

      deliverySetProducts,

      totalPrice,
    },
  } = data;

  const cover =
    coverImage === "" || coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${uri}${coverImage}`;

  const [isFull, setIsFull] = useState(false);
  const isFullToggle = () => {
    setIsFull(!isFull);
  };

  return (
    <Fragment>
      <DetailContainer>
        <div className="detail_img">
          <img src={cover} alt={name} />
        </div>
        <DetailInfo>
          <h3>{name}</h3>
          <span className="price">
            <b>
              <NumericFormat
                displayType={"text"}
                value={totalPrice}
                thousandSeparator={true}
              />
            </b>{" "}
            드림
          </span>
          <div className="accordion_box">
            <Accordion
              title={`묶음상품(${deliverySetProducts.length}개)`}
              content={
                <Bundle>
                  <ul>
                    {deliverySetProducts.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="cont">
                            <strong>
                              <Link to={`/products/${item.deliveryProduct.id}`}>
                                {item.deliveryProduct.name}
                              </Link>
                              <span>({item.count}개)</span>
                            </strong>
                            <div className="price">
                              <b>
                                <NumericFormat
                                  displayType={"text"}
                                  value={item.totalPrice}
                                  thousandSeparator={true}
                                />
                              </b>
                              드림
                            </div>
                          </div>
                          {(item.deliveryProductOptionDetail1 ||
                            item.deliveryProductOptionDetail2 ||
                            item.deliveryProductOptionDetail3) && (
                            <span className="option">
                              [옵션]
                              {item.deliveryProductOptionDetail1 &&
                                item.deliveryProductOptionDetail1
                                  .commonStatus === "C" &&
                                ` ${item.deliveryProductOptionDetail1.name}`}
                              {item.deliveryProductOptionDetail2 &&
                                item.deliveryProductOptionDetail2
                                  .commonStatus === "C" &&
                                ` / ${item.deliveryProductOptionDetail2.name}`}
                              {item.deliveryProductOptionDetail3 &&
                                item.deliveryProductOptionDetail3
                                  .commonStatus === "C" &&
                                ` / ${item.deliveryProductOptionDetail3.name}`}
                            </span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="total">
                    <RowInfo
                      total={"true"}
                      lefttext={"총 합계"}
                      righttext={
                        <>
                          <b>
                            <NumericFormat
                              displayType={"text"}
                              value={totalPrice}
                              thousandSeparator={true}
                            />
                          </b>{" "}
                          드림
                        </>
                      }
                    />
                  </div>
                </Bundle>
              }
            />
          </div>
        </DetailInfo>
        <div className={"detail_content"}>
          <div
            className={isFull ? " open" : "hide"}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <Button color="white" width="full" onClick={isFullToggle}>
            {isFull ? (
              <>
                상품정보 접기 &nbsp;
                <FontAwesomeIcon icon={faAngleUp} />
              </>
            ) : (
              <>
                상품정보 펼치기 &nbsp;
                <FontAwesomeIcon icon={faAngleDown} />
              </>
            )}
          </Button>
          <InfoBox style={{ marginTop: 15 }}>
            묶음상품은 <b>교환/환불/배송/결제</b>는 각각 상품별로 문의하시기
            바랍니다.
          </InfoBox>
        </div>
      </DetailContainer>
      <FooterButton>
        <div className="shadow">
          <Links
            to={`${baseUrl}/${id}/order`}
            replace={true}
            color="primary"
            width="full"
            border="radius"
          >
            구독 담기
          </Links>
        </div>
      </FooterButton>
    </Fragment>
  );
}

function Presenter({ loading, data, baseUrl }) {
  return (
    <Layout isNav={false} isFooter={true} isFooterButton={true} isSearch={true}>
      <PageTitle title={`묶음`} />

      {!loading ? (
        <Detail data={data} baseUrl={baseUrl} />
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Presenter;
