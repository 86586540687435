import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import theme from "../../../Components/Style/Theme";
import { BottomModal } from "../../../Components/Layout/Modal";
import Button from "../../../Components/Common/Buttons";
import RadioBtn from "../../../Components/Common/RadioBtn";
import {
  InputGroup,
  ModalContainer,
  Select,
  SwalCustom,
  Updown,
} from "../../../Components/Style/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMinus,
  faPlus,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import RowInfo from "../../../Components/Common/RowInfo";
import { useForm, Controller } from "react-hook-form";
import FormError from "../../../Components/Common/FormError";
import { stateOptions } from "../../../Config/Codes/BoxProduct";
import { NumericFormat } from "react-number-format";

import { useMutation } from "@apollo/client";
import { DELIVERY_ACCOUNT_BOX_PRODUCT_WRITE_MUTATION } from "../../../Config/Queries/BoxProduct";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function Presenter({ data, baseUrl }) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    DeliveryProductDetail: {
      id,
      // createdAt,
      // updatedAt,

      // commonStatus,
      // coverImage,
      // timestamp,
      price,
      name,
      // description,

      // deliveryProductCategory,
      deliveryProductOptions,
    },
  } = data;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      state: null,
      count: 1,
      deliveryProductOptionDetail1_id: null,
      deliveryProductOptionDetail2_id: null,
      deliveryProductOptionDetail3_id: null,
    },
  });

  const [
    DeliveryAccountBoxProductWriteMutation,
    { loading: DeliveryAccountBoxProductWriteLoading },
  ] = useMutation(DELIVERY_ACCOUNT_BOX_PRODUCT_WRITE_MUTATION);

  const onSubmit = async (data) => {
    try {
      if (DeliveryAccountBoxProductWriteLoading) {
        return;
      }

      const {
        data: { DeliveryAccountBoxProductWrite },
      } = await DeliveryAccountBoxProductWriteMutation({
        variables: {
          state: data.state,
          count: `${data.count}`,
          deliveryProduct_id: id,
          deliveryProductOptionDetail1_id: data.deliveryProductOptionDetail1_id,
          deliveryProductOptionDetail2_id: data.deliveryProductOptionDetail2_id,
          deliveryProductOptionDetail3_id: data.deliveryProductOptionDetail3_id,
        },
      });

      if (DeliveryAccountBoxProductWrite) {
        MySwal.fire({
          html: (
            <SwalCustom>
              <div className="icon primary">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <div className="alert_title">
                <b>구독박스에 상품</b>이
                <br />
                담겼습니다.
              </div>
            </SwalCustom>
          ),
          customClass: {
            popup: "swal_popup",
            actions: "swal",
            confirmButton: "swal_btn lightprimary",
            cancelButton: "swal_btn primary",
          },
          confirmButtonText: "구독박스 보기",
          cancelButtonText: "계속담기",
          showCancelButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/box${location.search}`);
          } else if (result.isDismissed) {
            onClose(false);
          }
        });
      }
    } catch (e) {
      const error = e.toString().replace("ApolloError:", "");

      MySwal.fire({
        html: (
          <SwalCustom>
            <div className="icon danger">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="alert_title">{error}</div>
          </SwalCustom>
        ),
        confirmButtonText: `확인`,
        confirmButtonColor: `#44a16a`,
        customClass: {
          popup: "swal_popup",
          actions: "swal",
          confirmButton: "swal_btn lightprimary",
          cancelButton: "swal_btn primary",
        },
        allowOutsideClick: false,
      });
      return;
    }
  };

  const onClose = () => {
    document.body.style.overflow = "unset";
    navigate(`${baseUrl}/${id}${location.search}`, { replace: true });
  };

  const onMinus = (onChange, value) => {
    if (value <= 1) return;
    onChange(value - 1);
  };
  const onPlus = (onChange, value) => {
    onChange(value + 1);
  };

  const [totalPrice, setTotalPrice] = useState(price);
  useEffect(() => {
    const countTotalPrice = () => {
      let totalPrice = price;
      deliveryProductOptions.forEach((item, index) => {
        if (watch(`deliveryProductOptionDetail${index + 1}_id`)) {
          const option = item.deliveryProductOptionDetails.find(
            (option) =>
              option.id === watch(`deliveryProductOptionDetail${index + 1}_id`)
          );
          totalPrice += option.price;
        }
      });
      setTotalPrice(totalPrice * watch(`count`));
    };
    countTotalPrice();
  }, [
    watch(`deliveryProductOptionDetail1_id`),
    watch(`deliveryProductOptionDetail2_id`),
    watch(`deliveryProductOptionDetail3_id`),
    watch(`count`),
  ]);

  const selects = deliveryProductOptions.filter(
    (item) => item.commonStatus === "C"
  );
  return (
    <BottomModal isOpen={true} toggleModal={onClose} title={"구독 담기"}>
      <ModalContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            {selects.length > 0 && (
              <div className="form_group">
                <label className="label_txt">옵션선택</label>
                {selects.map((select, index) => (
                  <Controller
                    key={index}
                    control={control}
                    rules={{
                      required: `${select.name}을 선택해주세요.`,
                    }}
                    name={`deliveryProductOptionDetail${index + 1}_id`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <Select className="selects">
                          <select
                            onChange={onChange}
                            onBlur={onBlur}
                            defaultValue={value}
                          >
                            <option value="">{select.name} 선택</option>
                            {select.deliveryProductOptionDetails
                              .filter((option) => option.commonStatus === "C")
                              .map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.name}
                                  {option.price !== 0 &&
                                    `(${
                                      option.price > 0 ? "+" : ""
                                    }${option.price.toLocaleString("ko-KR", {
                                      maximumFractionDigits: 4,
                                    })}드림)`}
                                </option>
                              ))}
                          </select>
                        </Select>
                        {errors[
                          `deliveryProductOptionDetail${index + 1}_id`
                        ] && (
                          <FormError
                            message={
                              errors[
                                `deliveryProductOptionDetail${index + 1}_id`
                              ].message
                            }
                          />
                        )}
                      </>
                    )}
                  />
                ))}
              </div>
            )}
            <Controller
              control={control}
              rules={{
                required: "수량을 선택해주세요.",
              }}
              name="count"
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <Updown>
                    <button
                      type="button"
                      onClick={() => onMinus(onChange, value)}
                    >
                      <FontAwesomeIcon icon={faMinus} size="lg" />
                    </button>
                    <div>
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        readOnly={true}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => onPlus(onChange, value)}
                    >
                      <FontAwesomeIcon icon={faPlus} size="lg" />
                    </button>
                  </Updown>
                  {errors.count && <FormError message={errors.count.message} />}
                </div>
              )}
            />
            <Controller
              control={control}
              rules={{
                required: "구독옵션을 선택해주세요.",
              }}
              name="state"
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="form_group">
                  <label className="label_txt">구독옵션</label>
                  <div className="row">
                    {stateOptions.map((item, index) => (
                      <>
                        <RadioBtn
                          key={index}
                          id={index}
                          text={item.label}
                          value={item.value}
                          onChange={onChange}
                          checked={item.value === value}
                        />
                      </>
                    ))}
                  </div>
                  <div className="order_box">
                    {value === "Onetime" ? (
                      <div className="order_text">
                        <strong>1회 구독</strong>
                        <p>- 선택 시 한번 배송 후 구독박스에서 사라집니다.</p>
                      </div>
                    ) : (
                      <div className="order_text">
                        <strong>정기구독</strong>
                        <p>- 선택 시 구독박스에 유지됩니다.</p>
                      </div>
                    )}
                  </div>
                  {errors.state && <FormError message={errors.state.message} />}
                </div>
              )}
            />
          </InputGroup>
          <div className="price_content">
            <div className="price_info">
              <h3>{name}</h3>
              <RowInfo
                lefttext={"금액"}
                righttext={`${price.toLocaleString("ko-KR", {
                  maximumFractionDigits: 4,
                })}드림`}
              />
              <RowInfo lefttext={"수량"} righttext={`${watch(`count`)}개`} />
            </div>
            <div className="price_total">
              <RowInfo
                total="true"
                lefttext={"총 금액"}
                righttext={
                  <>
                    <b>
                      <NumericFormat
                        displayType={"text"}
                        value={totalPrice}
                        thousandSeparator={true}
                      />
                    </b>
                    드림
                  </>
                }
              />
            </div>
          </div>
          <Button
            type="submit"
            color="primary"
            size="lg"
            width="full"
            border="radius"
          >
            구독 담기
          </Button>
        </form>
      </ModalContainer>
    </BottomModal>
  );
}

export default Presenter;
