import styled, { css } from "styled-components"

export const StyledRow = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 0;
      ${(props) =>
        props.border === "bottom" &&
        css`
          border-bottom: solid 1px #eee;
        `}
      div {
        display: flex;
        font-weight: ${theme.fonts.weight.normal};
        &.left {
          width: 80px;
          color: ${theme.colors.black04};
          font-size: 15px;
          ${(props) =>
            props.total === true &&
            css`
              font-size: ${theme.fonts.size.base};
              color: ${theme.colors.black01};
              font-weight: ${theme.fonts.weight.bold};
            `}
        }
        &.right {
          flex: 1;
          align-items: center;
          color: ${theme.colors.black01};
          font-size: 15px;
          justify-content: flex-end;
          text-align: right;
          ${(props) =>
            props.total === true &&
            css`
              font-size: ${theme.fonts.size.base};
            `}
        }
      }
    `
  }}
`
function RowInfo({ lefttext, righttext, border, total }) {
  return (
    <StyledRow border={border} total={total}>
      <div className="left">{lefttext}</div>
      {typeof righttext === "string" ? (
        <div
          className="right"
          dangerouslySetInnerHTML={{ __html: righttext }}
        />
      ) : (
        <div className="right">{righttext}</div>
      )}
    </StyledRow>
  )
}

export default RowInfo
