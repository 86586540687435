import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { styled } from "styled-components";
import Layout from "../../../../Components/Layout/Layout";
import theme from "../../../../Components/Style/Theme";
import {
  ProductList,
  Ready,
  SearchGroup,
  TabsNav,
} from "../../../../Components/Style/Styles";
import SetItem from "../../../../Components/Product/SetItem";
import PageTitle from "../../../../Components/Layout/PageTitle";
import FormError from "../../../../Components/Common/FormError";
import Pagination from "../../../../Components/Common/Pagination";
import { SearchNormal1 } from "iconsax-react";

const TabsNavs = styled(TabsNav)`
  margin-bottom: ${theme.margins.lg};
  span {
    margin-left: 3px;
    color: ${theme.colors.black04};
  }
  .active {
    a {
      span {
        color: ${theme.colors.primary};
      }
    }
  }
`;
function Presenter({ keyword, page, take, loading, data, baseUrl }) {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { keyword },
  });

  const setItem = async (text) => {
    const date = new Date();
    const newKeyword = { keyword: text, date: date.toString() };

    let keywords = await localStorage.getItem("keywords");

    if (keywords) {
      keywords = JSON.parse(keywords);

      let tempKeywords = keywords.filter((item) => text !== item.keyword);

      tempKeywords = tempKeywords
        ? [newKeyword, ...tempKeywords]
        : [newKeyword];
      await localStorage.removeItem("keywords");
      localStorage.setItem("keywords", JSON.stringify(tempKeywords));
    } else {
      const tempKeywords = [newKeyword];
      localStorage.setItem("keywords", JSON.stringify(tempKeywords));
    }
  };

  const onSubmit = (data) => {
    setItem(data.keyword);
    navigate(`${baseUrl}?keyword=${encodeURIComponent(data.keyword)}`);
  };

  const defaultUrl = `${baseUrl}?` + (keyword ? `keyword=${keyword}&` : ``);

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title={`검색결과`} />
      <SearchGroup>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form_group">
            <div className="row">
              <div>
                <Controller
                  name="keyword"
                  control={control}
                  rules={{
                    required: "키워드를 입력해주세요.",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <input
                        className="form_control"
                        placeholder="검색어를 입력해주세요."
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    </>
                  )}
                />
              </div>
              <button type="submit" className="btn">
                <SearchNormal1 variant="Linear" size={24} />
              </button>
            </div>
            {errors.keyword && <FormError message={errors.keyword.message} />}
          </div>
        </form>
      </SearchGroup>
      <TabsNavs>
        <ul>
          <li>
            <Link
              to={`/search/result/products?keyword=${encodeURIComponent(
                keyword
              )}`}
            >
              상품 <span>{!loading && data.DeliveryProductList.count}</span>
            </Link>
          </li>
          <li className="active">
            <Link
              to={`/search/result/sets?keyword=${encodeURIComponent(keyword)}`}
            >
              묶음 <span>{!loading && data.DeliverySetList.count}</span>
            </Link>
          </li>
        </ul>
      </TabsNavs>
      {!loading ? (
        <Fragment>
          {data.DeliverySetList.count > 0 ? (
            <>
              <ProductList>
                <ul>
                  {data.DeliverySetList.deliverySets.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/sets/${item.id}`}>
                          <SetItem item={item} />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </ProductList>
              <Pagination
                totalRecord={data.DeliverySetList.count}
                blockSize={5}
                pageSize={take}
                currentPage={page}
                baseUrl={`${defaultUrl}`}
              />
            </>
          ) : (
            <Ready>검색된 묶음상품이 없습니다.</Ready>
          )}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Presenter;
