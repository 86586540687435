import { gql } from "@apollo/client";

export const DELIVERY_ACCOUNT_ORDER_LIST_QUERY = gql`
  query DeliveryAccountOrderList($keyword: String, $skip: Int!, $take: Int) {
    DeliveryAccountOrderList(keyword: $keyword, skip: $skip, take: $take) {
      deliveryOrders {
        id
        createdAt
        updatedAt

        state
        deliverAt
        deliveryOrderProducts {
          id
          createdAt
          updatedAt

          state
          price
          count
          totalPrice

          # deliveryOrder
          deliveryProduct {
            id

            price
            name
          }
          deliveryProductOptionDetail1 {
            id

            price
            name
          }
          deliveryProductOptionDetail2 {
            id

            price
            name
          }
          deliveryProductOptionDetail3 {
            id

            price
            name
          }
          # user
        }
        price
        refundPrice
        totalPrice

        type
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        dayOfWeek

        completedAddress
        completedLatitude
        completedLongitude
        completedAt

        # storeOrder
        # deliveryBox
        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
            phone
          }
          # deliveryStoreDongs {
          #   id
          #   createdAt
          #   updatedAt

          #   sido
          #   sigungu
          #   upmyundong
          #   ri
          # }
          # deliveryPlaces {
          #   id
          #   createdAt
          #   updatedAt

          #   name
          # }

          # deliveryStoreDongsCount
          # deliveryPlacesCount
        }
        # deliveryOrderSteps
        # deliveryDeliver
        # user
      }
      count
    }
  }
`;
export const DELIVERY_ACCOUNT_ORDER_DETAIL_QUERY = gql`
  query DeliveryAccountOrderDetail($id: String!) {
    DeliveryAccountOrderDetail(id: $id) {
      id
      createdAt
      updatedAt

      state
      deliverAt
      deliveryOrderProducts {
        id
        createdAt
        updatedAt

        state
        price
        count
        totalPrice

        # deliveryOrder
        deliveryProduct {
          id
          createdAt
          updatedAt

          commonStatus
          coverImage
          timestamp
          price
          name
          description
        }
        deliveryProductOptionDetail1 {
          id

          price
          name
        }
        deliveryProductOptionDetail2 {
          id

          price
          name
        }
        deliveryProductOptionDetail3 {
          id

          price
          name
        }
        # user
      }
      price
      refundPrice
      totalPrice

      type
      zipcode
      jibunAddress
      roadAddress
      addressDetail
      dayOfWeek

      completedAddress
      completedLatitude
      completedLongitude
      completedAt

      # storeOrder
      # deliveryBox
      deliveryStore {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
          phone
        }
        # deliveryStoreDongs {
        #   id
        #   createdAt
        #   updatedAt

        #   sido
        #   sigungu
        #   upmyundong
        #   ri
        # }
        # deliveryPlaces {
        #   id
        #   createdAt
        #   updatedAt

        #   name
        # }

        # deliveryStoreDongsCount
        # deliveryPlacesCount
      }
      deliveryOrderSteps {
        id
        createdAt
        updatedAt

        state
        address
        latitude
        longitude
      }
      # deliveryDeliver
      # user
    }
  }
`;
