import React from "react";
import { useSearchParams } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "@apollo/client";
import { DELIVERY_ACCOUNT_PLACE_LIST_QUERY } from "../../../../Config/Queries/Place";

export default () => {
  const [searchParams] = useSearchParams();

  const dayOfWeek = searchParams.get("dayOfWeek")
    ? searchParams.get("dayOfWeek")
    : "";
  const page = searchParams.get("page") ? +searchParams.get("page") : 1;
  const take = 100;
  const baseUrl = "/account/places";

  const { data, loading, error } = useQuery(DELIVERY_ACCOUNT_PLACE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      dayOfWeek,
      take,
      skip: (page - 1) * take,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      dayOfWeek={dayOfWeek}
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
};
