import { gql } from "@apollo/client";

export const DELIVERY_SET_LIST_QUERY = gql`
  query DeliverySetList($skip: Int!, $take: Int) {
    DeliverySetList(skip: $skip, take: $take) {
      deliverySets {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        name
        description

        totalPrice
      }
      count
    }
  }
`;
export const DELIVERY_SET_DETAIL_QUERY = gql`
  query DeliverySetDetail($id: String!) {
    DeliverySetDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      coverImage
      timestamp
      name
      description

      deliverySetProducts {
        id
        createdAt
        updatedAt

        count
        deliveryProduct {
          id

          price
          name
        }
        deliveryProductOptionDetail1 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail2 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail3 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        price
        totalPrice
      }

      totalPrice
    }
  }
`;
