import { faClose } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

const Modals = styled.div`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: -0;
        z-index: 99;
        &.center {
          top: 0;
          padding: ${theme.paddings.lg};
          section {
            border-radius: 20px;
          }
        }
        &:before {
          content: "";
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 11;
        }
        section {
          position: relative;
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 750px;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          background-color: #fff;
          /* 팝업이 열릴때 스르륵 열리는 효과 */
          animation: modal-show 0.3s;
          z-index: 15;
          main {
            display: flex;
            flex-direction: column;
            ${theme.device.mobile} {
              height: 100%;
              overflow-y: scroll;
              ::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
        header {
          position: relative;
          margin-top: 12px;
          height: 46px;
          font-weight: 700;
          justify-content: space-between;
          align-items: center;
          display: flex;
          flex-direction: row;
          .right {
            width: 46px;
            height: 46px;
          }
          .title {
            font-size: ${theme.fonts.size.base};
            font-weight: ${theme.fonts.weight.bold};
            align-items: center;
          }
          button {
            width: 46px;
            height: 46px;
            font-size: 21px;
            font-weight: 700;
            text-align: center;
            border: 0;
            color: #999;
            background-color: transparent;
          }
        }
        footer {
          height: 60px;
          flex: 1;
          display: flex;
          border-top: solid 1px #eee;
          a,
          button {
            margin-left: 0 !important;
            height: 60px;
            line-height: 60px;
          }
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: modal-bg-show 0.3s;
      }
      @keyframes modal-show {
        from {
          transform: translateY(50%);
        }
        to {
          transform: translateY(0);
        }
      }
    `
  }}
`
export const CenterModal = ({ isOpen, toggleModal, title, children }) => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.overflow = "unset"
  //   }
  // }, [isOpen])
  return (
    <Modals>
      <div className={`modal center ${isOpen ? "openModal" : ""}`}>
        {isOpen ? (
          <section>
            <main>{children}</main>
          </section>
        ) : null}
      </div>
    </Modals>
  )
}
export const BottomModal = ({ isOpen, toggleModal, title, children }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, [isOpen])
  return (
    <Modals>
      <div className={isOpen ? "openModal modal" : "modal"}>
        {isOpen ? (
          <section>
            <header>
              <div className="right" />
              <h3 className="title">{title}</h3>
              <button onClick={toggleModal}>
                <FontAwesomeIcon icon={faClose} />
              </button>
            </header>
            <main style={{ maxHeight: windowSize.height / 1.25 }}>
              {children}
            </main>
          </section>
        ) : null}
      </div>
    </Modals>
  )
}
