import { createGlobalStyle } from "styled-components"

import PretendardLightWoff2 from "pretendard/dist/web/static/woff2/Pretendard-Light.woff2"
import PretendardLightWoff from "pretendard/dist/web/static/woff/Pretendard-Light.woff"
import PretendardLightOtf from "pretendard/dist/public/static/Pretendard-Light.otf"

import PretendardRegularWoff2 from "pretendard/dist/web/static/woff2/Pretendard-Regular.woff2"
import PretendardRegularWoff from "pretendard/dist/web/static/woff/Pretendard-Light.woff"
import PretendardRegularOtf from "pretendard/dist/public/static/Pretendard-Light.otf"

import PretendardMediumWoff2 from "pretendard/dist/web/static/woff2/Pretendard-Medium.woff2"
import PretendardMediumWoff from "pretendard/dist/web/static/woff/Pretendard-Medium.woff"
import PretendardMediumOtf from "pretendard/dist/public/static/Pretendard-Medium.otf"

import PretendardBoldWoff2 from "pretendard/dist/web/static/woff2/Pretendard-Bold.woff2"
import PretendardBoldWoff from "pretendard/dist/web/static/woff/Pretendard-Bold.woff"
import PretendardBoldOtf from "pretendard/dist/public/static/Pretendard-Bold.otf"

export const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'Pretendard';
        font-weight: 300;
        src: url(${PretendardLightWoff2}) format('woff2'),
            url(${PretendardLightWoff}) format('woff'),
            url(${PretendardLightOtf}) format('opentype');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 400;
        src: url(${PretendardRegularWoff2}) format('woff2'),
            url(${PretendardRegularWoff}) format('woff'),
            url(${PretendardRegularOtf}) format('opentype');
    }
    @font-face {
        font-family: 'Pretendard';
        font-weight: 500;
        src: url(${PretendardMediumWoff2}) format('woff2'),
            url(${PretendardMediumWoff}) format('woff'),
            url(${PretendardMediumOtf}) format('opentype');
    }
    @font-face {
        font-family: 'Pretendard';
        font-weight: 700;
        src: url(${PretendardBoldWoff2}) format('woff2'),
            url(${PretendardBoldWoff}) format('woff'),
            url(${PretendardBoldOtf}) format('opentype');
    }
`
