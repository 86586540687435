const margins = {
  xs: "3px",
  sm: "5px",
  base: "10px",
  lg: "18px",
  xl: "24px",
}

const paddings = {
  xs: "3px",
  sm: "5px",
  base: "10px",
  lg: "18px",
  xl: "24px",
}

const fonts = {
  family: {
    base: `'Pretendard', sans-serif`,
  },
  size: {
    xs: "12px",
    sm: "14px",
    base: "16px",
    lg: "18px",
    xl: "24px",
    title: "32px",
  },
  weight: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
  },
}

const colors = {
  black01: "#222",
  black02: "#444",
  black03: "#777",
  black04: "#999",
  black05: "#ccc",
  white: "#fff",
  gray: "#eee",
  lightgray: "#F9FAFB",
  primary: "#8EC96D",
  lightprimary: "#F4FAF0",
  danger: "#FF5473",
  lightdanger: "#FFE5EA",
  warning: "#FF8F50",
  lightwarning: "#FFEEE5",
  success: "#84D85D",
}

const size = {
  mobile: "420px",
  tablet: "991px",
  desktop: "1279px",
}

// 미디어 쿼리의 중복 코드를 줄이기위해 정의된 변수입니다
const device = {
  mobile: `@media (max-width: ${size.mobile})`,
  tablet: `@media (max-width: ${size.tablet})`,
  desktop: `@media (max-width: ${size.desktop})`,
}

const Theme = {
  margins,
  paddings,
  fonts,
  device,
  colors,
}

// 공통으로 사용되는 변수들입니다
export default Theme
