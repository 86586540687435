import React, { Fragment } from "react";
import styled from "styled-components";
import Layout from "../../../Components/Layout/Layout";
import theme from "../../../Components/Style/Theme";
import Item from "../../../Components/Delivery/Item";
import ProductList from "../../../Components/Delivery/ProductList";
import { Ready } from "../../../Components/Style/Styles";
import PageTitle from "../../../Components/Layout/PageTitle";
import Pagination from "../../../Components/Common/Pagination";

const DeliveryList = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: ${theme.paddings.lg};
    li {
      flex: 1;
      flex-shrink: 0;
      padding: 0 ${theme.paddings.xl};
      margin-bottom: ${theme.margins.lg};
      .content {
        padding: 15px;
        border-radius: 8px;
        border: solid 1px #eee;
      }
    }
  }
`;
function Presenter({ keyword, page, take, loading, data, baseUrl }) {
  const defaultUrl = `${baseUrl}?` + (keyword ? `keyword=${keyword}&` : ``);

  return (
    <Layout isNav={true} isFooter={true} isSearch={true}>
      <PageTitle title={`배송관리`} />

      {!loading ? (
        <Fragment>
          {data.DeliveryAccountOrderList.count > 0 ? (
            <Fragment>
              <DeliveryList>
                <ul>
                  {data.DeliveryAccountOrderList.deliveryOrders.map(
                    (item, index) => {
                      return (
                        <li key={index}>
                          <div className="content">
                            <Item item={item} />
                            <ProductList item={item} />
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </DeliveryList>
              <Pagination
                totalRecord={data.DeliveryAccountOrderList.count}
                blockSize={5}
                pageSize={take}
                currentPage={page}
                baseUrl={`${defaultUrl}`}
              />
            </Fragment>
          ) : (
            <Ready>배송내역이 없습니다.</Ready>
          )}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Layout>
  );
}

export default Presenter;
