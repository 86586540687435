import styled from "styled-components";
import theme from "../../Components/Style/Theme";
import { Row } from "../Style/Styles";
import { Box } from "iconsax-react";
import Links from "../Common/Links";
import { NumericFormat } from "react-number-format";

const ProductLists = styled.div`
  .product_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.paddings.lg} 0 ${theme.paddings.sm};
    font-size: ${theme.fonts.size.base};
    color: ${theme.colors.black03};
    span {
      color: ${theme.colors.black01};
      font-size: ${theme.fonts.size.base};
      font-weight: ${theme.fonts.weight.bold};
    }
  }
  .product_list {
    transition: all 0.3s linear;
    overflow: hidden;
    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 ${theme.margins.base};
        &:last-child {
          margin-bottom: 0;
        }
        .icon {
          background-color: ${theme.colors.lightprimary};
          display: flex;
          border-radius: 12px;
          width: 70px;
          height: 70px;
          justify-content: center;
          align-items: center;
        }
        .cont {
          padding-left: ${theme.paddings.base};
          strong {
            width: 180px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: ${theme.fonts.size.base};
            font-weight: ${theme.fonts.weight.medium};
            color: ${theme.colors.black01};
          }
          .option {
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black04};
          }
          .row {
            margin-top: ${theme.margins.sm};
            display: flex;
            align-items: center;
            font-size: ${theme.fonts.size.base};
            color: ${theme.colors.black02};
          }
        }
      }
    }
  }
`;

function ProductList({ item }) {
  return (
    <ProductLists>
      <div className="product_top">
        <span>상품정보</span>
      </div>
      <div className={`product_list`}>
        <ul>
          <li>
            <div className="icon">
              <Box variant="Bulk" size={36} color={theme.colors.primary} />
            </div>
            <div className="cont">
              <strong>
                {item.deliveryOrderProducts.length === 1 ? (
                  <>{item.deliveryOrderProducts[0].deliveryProduct.name}</>
                ) : (
                  <>
                    {item.deliveryOrderProducts[0].deliveryProduct.name} 외에{" "}
                    {item.deliveryOrderProducts.length - 1}건
                  </>
                )}
              </strong>
              <Row className="row">
                <b>
                  <NumericFormat
                    displayType={"text"}
                    value={item.totalPrice}
                    thousandSeparator={true}
                  />
                </b>{" "}
                드림
              </Row>
            </div>
          </li>
        </ul>
      </div>
      <Links
        to={`/orders/${item.id}`}
        color="lightprimary"
        width="full"
        border="radius"
      >
        상세정보
      </Links>
    </ProductLists>
  );
}

export default ProductList;
