import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import theme from "../Style/Theme"
import { CenterModal } from "../Layout/Modal"
import { Bag, Bag2, Truck } from "iconsax-react"

import { useQuery } from "@apollo/client"
import { DELIVERY_ACCOUNT_USER_DETAIL_QUERY } from "../../Config/Queries/Account"
import { ModalContainer } from "../Style/Styles"

const ModalTitle = styled.div`
  ${({ theme }) => {
    return css`
      margin-top: ${theme.margins.lg};
      text-align: center;
      .logo {
        margin-bottom: 12px;
        img {
          width: 72px;
          height: auto;
          margin-right: 8px;
        }
      }
      h3 {
        font-size: 21px;
        line-height: 1.4;
        color: ${theme.colors.black01};
      }
    `
  }}
`
const LinkBtn = styled.div`
  ${({ theme }) => {
    return css`
      margin-top: ${theme.margins.lg};
      ul {
        display: flex;
        flex-direction: row;
        li {
          flex: 1;
          background-color: ${theme.colors.lightgray};
          border-radius: 12px;
          padding: ${theme.paddings.lg};
          &:nth-child(2) {
            margin-left: ${theme.margins.base};
          }
          .icon {
            display: flex;
            width: 44px;
            height: 44px;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            background-color: ${theme.colors.lightprimary};
          }
          strong {
            display: block;
            margin-top: ${theme.margins.base};
            font-size: ${theme.fonts.size.lg};
            color: ${theme.colors.black01};
          }
          p {
            margin-top: ${theme.margins.sm};
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black04};
          }
        }
      }
    `
  }}
`
function FirstModal() {
  const [firstModal, setFirstModal] = useState(false)
  const firstModalToggle = () => {
    setFirstModal(!firstModal)
  }

  const { data, loading, error } = useQuery(
    DELIVERY_ACCOUNT_USER_DETAIL_QUERY,
    {
      fetchPolicy: "network-only",
    }
  )

  useEffect(() => {
    if (!loading) {
      if (!data.DeliveryAccountUserDetail.isDelivery) {
        setFirstModal(true)
      }
    }
  }, [data, loading])

  if (loading) {
    return <></>
  }

  // if (error) {
  //   return <>{error.message}</>;
  // }

  return (
    <CenterModal isOpen={firstModal} toggleModal={firstModalToggle}>
      <ModalContainer>
        <ModalTitle>
          <h3>정기구독을 이용 하시려면</h3>
          <h3>
            <b>배송정보 등록</b> 해주셔야 합니다.
          </h3>
        </ModalTitle>
        <LinkBtn>
          <ul className="LinkBtn">
            <li>
              <Link to={`/account/places`}>
                <div className="icon">
                  <Bag variant="Bulk" color={theme.colors.primary} size={24} />
                </div>
                <strong>픽업</strong>
                <p>한밭페이에서 지정한 장소에서 픽업 합니다.</p>
              </Link>
            </li>
            <li>
              <Link to={`/account/addresses`}>
                <div className="icon">
                  <Truck
                    variant="Bulk"
                    color={theme.colors.primary}
                    size={24}
                  />
                </div>
                <strong>배달</strong>
                <p>매장에서 배송이 가능한 주소지에 배송합니다.</p>
              </Link>
            </li>
          </ul>
        </LinkBtn>
      </ModalContainer>
    </CenterModal>
  )
}

export default FirstModal
